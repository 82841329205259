import React from "react";
import { BoldText } from "../../Text/Text";
import classes from "./Button.module.scss";

interface IButton extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  color?: string;
  leftArrow?: boolean;
  height?: number;
  width?: number;
  leftIcon?: string;
  textstyles?: React.CSSProperties;
}

const Button: React.FC<IButton> = ({
  color,
  children,
  leftArrow,
  height = 91,
  width = 314,
  leftIcon,
  ...rest
}) => {
  const svgWidth = (24 / 34) * height;

  return (
    <div className={classes["container"]}>
      {leftArrow ? (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width={svgWidth}
          height={height}
          viewBox="0 0 24 34"
          style={{ display: "flex", justifyContent: "flex-start" }}
        >
          <path
            id="Polygon_1"
            data-name="Polygon 1"
            d="M17,0,34,24H0Z"
            transform="translate(0 34) rotate(-90)"
            fill={`${color}`}
          />
        </svg>
      ) : null}
      <button
        {...rest}
        className={`${classes["button"]} ${rest.className}`}
        style={{
          display: "flex",
          justifyContent: "flex-start",
          paddingLeft: "35px",
          alignItems: "center",
          backgroundColor: color,
          height: height,
          width: `${width - svgWidth}px`,
          ...rest.style,
        }}
      >
        {leftIcon ? (
          <img
            src={leftIcon}
            alt="icon"
            style={{
              width: "20px",
              height: "20px",
              objectFit: "contain",
              marginRight: "10px",
            }}
          />
        ) : null}
        <BoldText
          style={{
            fontFamily: "Bebas Neue",
            marginTop: "3px",
            ...rest.textstyles,
          }}
        >
          {children}
        </BoldText>
      </button>

      {!leftArrow ? (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width={svgWidth}
          height={height}
          viewBox="0 0 24 34"
          style={{ display: "flex", justifyContent: "flex-start" }}
        >
          <path
            id="Polygon_1"
            data-name="Polygon 1"
            d="M17,0,34,24H0Z"
            transform="translate(24) rotate(90)"
            fill={`${color}`}
          />
        </svg>
      ) : null}
    </div>
  );
};

Button.defaultProps = {
  color: "#0db48b",
  leftArrow: true,
  height: 34,
  width: 154,
};

export default Button;
