export const removeItem = (id: string, array: any[]) => {
  return array.filter((item) => {
    return item.id !== id;
  });
};

export const updateItem = (itemToPut: any, array: any[]) => {
  const index = array.findIndex((item) => {
    return item.id === itemToPut.id;
  });

  if (index >= 0) {
    const allData = [...array];
    allData.splice(index, 1, itemToPut);
    return [...allData];
  }
  return array;
};

export const isTriviaMakerRoute = () => {
  return window.location.pathname.includes("triviamaker");
};
