export const YELLOW = "#F9CE29";
export const GREEN = "#29C0B0";
export const ORANGE = "#FFAB2D";
export const DARK_GREEN = "#29F937";
export const BALTIC_SEA = "#222027";
export const BURNT_SIENNA = "#EB7062";
export const BLACK = "#222027";
export const RED = "#FC5C65";
export const GREEN_BUTTON = "#28EA55";
export const PINK_BUTTON = "#D4ABFC";
export const WHITE = "#FFFFFF";
export const GREY_BUTTON = "#B5B3C1";
export const GREEN_POPUP = "#28EA55";
export const CONIFER = "#83DB49";
export const BLUE_BELL = "#9493c4";
export const GEEBUNG = "#CB8419";
export const FLAME_PEA = "#D16138";
export const PURPLE = "#A55EEA";
export const GREEN_ICON = "#2BCBBA";
export const FLUORESCENT_BLUE = "#29EBF9";
export const GREY = "#979797";
export const MEDIUM_PURPLE = "#9C59DE";
