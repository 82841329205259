/* tslint:disable */
/* eslint-disable */
//  This file was automatically generated and should not be edited.

export type TriviaDetails = {
  __typename: "TriviaDetails",
  id?: string,
  TotalCategory?: number,
  TotalCollection?: number,
  TotalQuestion?: number,
  TotalCorrectAnswer?: number,
  TotalAnswer?: number,
  createdAt?: string,
  updatedAt?: string,
};

export type Category = {
  __typename: "Category",
  id?: string,
  Image?: string,
  Type?: string | null,
  Name?: string,
  Color?: string,
  TotalCollection?: number,
  Collection?: ModelCollectionConnection,
  isActive?: boolean | null,
  createdAt?: string,
  updatedAt?: string,
};

export type ModelCollectionConnection = {
  __typename: "ModelCollectionConnection",
  items?:  Array<Collection | null > | null,
  nextToken?: string | null,
};

export type Collection = {
  __typename: "Collection",
  id?: string,
  Category_Id?: string,
  Category?: Category,
  Image?: string,
  Name?: string,
  Description?: string,
  GameRules?: string,
  CollectionType?: string,
  Time?: number,
  TotalQuestion?: number,
  TimesPlayed?: number,
  TotalTime?: number,
  Difficulty?: number,
  GameCode?: string,
  owner?: string | null,
  isPublished?: boolean,
  TriviaRound?: ModelTriviaRoundConnection,
  isActive?: boolean | null,
  createdAt?: string,
  updatedAt?: string,
};

export type ModelTriviaRoundConnection = {
  __typename: "ModelTriviaRoundConnection",
  items?:  Array<TriviaRound | null > | null,
  nextToken?: string | null,
};

export type TriviaRound = {
  __typename: "TriviaRound",
  id?: string,
  Trivia_Id?: string,
  Trivia?: Trivia,
  DateStarted?: string | null,
  DateCompleted?: string | null,
  Collection_Id?: string | null,
  Collection?: Collection,
  CurrentQuestionId?: number | null,
  createdAt?: string,
  updatedAt?: string,
};

export type Trivia = {
  __typename: "Trivia",
  id?: string,
  GameCode?: string,
  DateStarted?: string | null,
  DateCompleted?: string | null,
  HasPowerUps?: boolean | null,
  CurrentRoundId?: number | null,
  CurrentQuestionId?: number | null,
  TriviaRound?: ModelTriviaRoundConnection,
  TriviaPlayers?: ModelTriviaPlayerConnection,
  createdAt?: string,
  updatedAt?: string,
};

export type ModelTriviaPlayerConnection = {
  __typename: "ModelTriviaPlayerConnection",
  items?:  Array<TriviaPlayer | null > | null,
  nextToken?: string | null,
};

export type TriviaPlayer = {
  __typename: "TriviaPlayer",
  id?: string,
  Trivia_Id?: string | null,
  GameCode?: string,
  Trivia?: Trivia,
  PlayerName?: string,
  PusherId?: string | null,
  isReady?: boolean,
  isAdmin?: boolean,
  isSuperAdmin?: boolean | null,
  isAdminActive?: boolean | null,
  PowerUps?:  Array<PlayerPowerUps | null > | null,
  Avatar?: string | null,
  createdAt?: string,
  updatedAt?: string,
};

export type PlayerPowerUps = {
  __typename: "PlayerPowerUps",
  id?: string,
  PowerUp?: PowerUp,
  isUsed?: boolean,
};

export enum PowerUp {
  None = "None",
  FiftyFifty = "FiftyFifty",
  DoubleTime = "DoubleTime",
  DoublePoint = "DoublePoint",
  Sabotage = "Sabotage",
}


export type CreateGameCodeInput = {
  id?: string | null,
  GameCode: string,
  AdminGameCode: string,
  MaxPlayers: number,
  GameType: string,
  Reseller: string,
  DaysValid: number,
  DateActivated?: string | null,
  DateExpired?: number | null,
  CollectionPlayed?: Array< string | null > | null,
};

export type ModelGameCodeConditionInput = {
  AdminGameCode?: ModelStringInput | null,
  MaxPlayers?: ModelIntInput | null,
  GameType?: ModelStringInput | null,
  Reseller?: ModelStringInput | null,
  DaysValid?: ModelIntInput | null,
  DateActivated?: ModelStringInput | null,
  DateExpired?: ModelIntInput | null,
  CollectionPlayed?: ModelStringInput | null,
  and?: Array< ModelGameCodeConditionInput | null > | null,
  or?: Array< ModelGameCodeConditionInput | null > | null,
  not?: ModelGameCodeConditionInput | null,
};

export type ModelStringInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
  size?: ModelSizeInput | null,
};

export enum ModelAttributeTypes {
  binary = "binary",
  binarySet = "binarySet",
  bool = "bool",
  list = "list",
  map = "map",
  number = "number",
  numberSet = "numberSet",
  string = "string",
  stringSet = "stringSet",
  _null = "_null",
}


export type ModelSizeInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
};

export type ModelIntInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
};

export type GameCode = {
  __typename: "GameCode",
  id?: string,
  GameCode?: string,
  AdminGameCode?: string,
  MaxPlayers?: number,
  GameType?: string,
  Reseller?: string,
  DaysValid?: number,
  DateActivated?: string | null,
  DateExpired?: number | null,
  CollectionPlayed?: Array< string | null > | null,
  createdAt?: string,
  updatedAt?: string,
};

export type UpdateGameCodeInput = {
  id?: string | null,
  GameCode: string,
  AdminGameCode?: string | null,
  MaxPlayers?: number | null,
  GameType?: string | null,
  Reseller?: string | null,
  DaysValid?: number | null,
  DateActivated?: string | null,
  DateExpired?: number | null,
  CollectionPlayed?: Array< string | null > | null,
};

export type DeleteGameCodeInput = {
  GameCode: string,
};

export type CreateTriviaInput = {
  id?: string | null,
  GameCode: string,
  DateStarted?: string | null,
  DateCompleted?: string | null,
  HasPowerUps?: boolean | null,
  CurrentRoundId?: number | null,
  CurrentQuestionId?: number | null,
};

export type ModelTriviaConditionInput = {
  GameCode?: ModelIDInput | null,
  DateStarted?: ModelStringInput | null,
  DateCompleted?: ModelStringInput | null,
  HasPowerUps?: ModelBooleanInput | null,
  CurrentRoundId?: ModelIntInput | null,
  CurrentQuestionId?: ModelIntInput | null,
  and?: Array< ModelTriviaConditionInput | null > | null,
  or?: Array< ModelTriviaConditionInput | null > | null,
  not?: ModelTriviaConditionInput | null,
};

export type ModelIDInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
  size?: ModelSizeInput | null,
};

export type ModelBooleanInput = {
  ne?: boolean | null,
  eq?: boolean | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
};

export type UpdateTriviaInput = {
  id: string,
  GameCode?: string | null,
  DateStarted?: string | null,
  DateCompleted?: string | null,
  HasPowerUps?: boolean | null,
  CurrentRoundId?: number | null,
  CurrentQuestionId?: number | null,
};

export type DeleteTriviaInput = {
  id: string,
};

export type CreateTriviaRoundInput = {
  id?: string | null,
  Trivia_Id: string,
  DateStarted?: string | null,
  DateCompleted?: string | null,
  Collection_Id?: string | null,
  CurrentQuestionId?: number | null,
};

export type ModelTriviaRoundConditionInput = {
  Trivia_Id?: ModelIDInput | null,
  DateStarted?: ModelStringInput | null,
  DateCompleted?: ModelStringInput | null,
  Collection_Id?: ModelIDInput | null,
  CurrentQuestionId?: ModelIntInput | null,
  and?: Array< ModelTriviaRoundConditionInput | null > | null,
  or?: Array< ModelTriviaRoundConditionInput | null > | null,
  not?: ModelTriviaRoundConditionInput | null,
};

export type UpdateTriviaRoundInput = {
  id: string,
  Trivia_Id?: string | null,
  DateStarted?: string | null,
  DateCompleted?: string | null,
  Collection_Id?: string | null,
  CurrentQuestionId?: number | null,
};

export type DeleteTriviaRoundInput = {
  id: string,
};

export type CreateTriviaPlayerInput = {
  id?: string | null,
  Trivia_Id?: string | null,
  GameCode: string,
  PlayerName: string,
  PusherId?: string | null,
  isReady: boolean,
  isAdmin: boolean,
  isSuperAdmin?: boolean | null,
  isAdminActive?: boolean | null,
  PowerUps?: Array< PlayerPowerUpsInput | null > | null,
  Avatar?: string | null,
};

export type PlayerPowerUpsInput = {
  id: string,
  PowerUp: PowerUp,
  isUsed: boolean,
};

export type ModelTriviaPlayerConditionInput = {
  Trivia_Id?: ModelIDInput | null,
  GameCode?: ModelIDInput | null,
  PlayerName?: ModelStringInput | null,
  PusherId?: ModelStringInput | null,
  isReady?: ModelBooleanInput | null,
  isAdmin?: ModelBooleanInput | null,
  isSuperAdmin?: ModelBooleanInput | null,
  isAdminActive?: ModelBooleanInput | null,
  Avatar?: ModelStringInput | null,
  and?: Array< ModelTriviaPlayerConditionInput | null > | null,
  or?: Array< ModelTriviaPlayerConditionInput | null > | null,
  not?: ModelTriviaPlayerConditionInput | null,
};

export type UpdateTriviaPlayerInput = {
  id: string,
  Trivia_Id?: string | null,
  GameCode?: string | null,
  PlayerName?: string | null,
  PusherId?: string | null,
  isReady?: boolean | null,
  isAdmin?: boolean | null,
  isSuperAdmin?: boolean | null,
  isAdminActive?: boolean | null,
  PowerUps?: Array< PlayerPowerUpsInput | null > | null,
  Avatar?: string | null,
};

export type DeleteTriviaPlayerInput = {
  id: string,
};

export type CreateTriviaPlayerAnswerInput = {
  id?: string | null,
  Trivia_Id: string,
  QuestionId: string,
  CollectionId: string,
  Answers?: Array< string | null > | null,
  PlayerId: string,
  PlayerName?: string | null,
  IsAnswerCorrect?: boolean | null,
  Points?: number | null,
  PowerUp?: Array< PowerUp | null > | null,
  ReceivedTime?: string | null,
  ReactionTime?: string | null,
};

export type ModelTriviaPlayerAnswerConditionInput = {
  Trivia_Id?: ModelIDInput | null,
  QuestionId?: ModelIDInput | null,
  CollectionId?: ModelIDInput | null,
  Answers?: ModelStringInput | null,
  PlayerId?: ModelIDInput | null,
  PlayerName?: ModelStringInput | null,
  IsAnswerCorrect?: ModelBooleanInput | null,
  Points?: ModelIntInput | null,
  PowerUp?: ModelPowerUpListInput | null,
  ReceivedTime?: ModelStringInput | null,
  ReactionTime?: ModelStringInput | null,
  and?: Array< ModelTriviaPlayerAnswerConditionInput | null > | null,
  or?: Array< ModelTriviaPlayerAnswerConditionInput | null > | null,
  not?: ModelTriviaPlayerAnswerConditionInput | null,
};

export type ModelPowerUpListInput = {
  eq?: Array< PowerUp | null > | null,
  ne?: Array< PowerUp | null > | null,
  contains?: PowerUp | null,
  notContains?: PowerUp | null,
};

export type TriviaPlayerAnswer = {
  __typename: "TriviaPlayerAnswer",
  id?: string,
  Trivia_Id?: string,
  QuestionId?: string,
  CollectionId?: string,
  Answers?: Array< string | null > | null,
  PlayerId?: string,
  PlayerName?: string | null,
  IsAnswerCorrect?: boolean | null,
  Points?: number | null,
  PowerUp?: Array< PowerUp | null > | null,
  ReceivedTime?: string | null,
  ReactionTime?: string | null,
  createdAt?: string,
  updatedAt?: string,
};

export type UpdateTriviaPlayerAnswerInput = {
  id: string,
  Trivia_Id?: string | null,
  QuestionId?: string | null,
  CollectionId?: string | null,
  Answers?: Array< string | null > | null,
  PlayerId?: string | null,
  PlayerName?: string | null,
  IsAnswerCorrect?: boolean | null,
  Points?: number | null,
  PowerUp?: Array< PowerUp | null > | null,
  ReceivedTime?: string | null,
  ReactionTime?: string | null,
};

export type DeleteTriviaPlayerAnswerInput = {
  id: string,
};

export type CreateTriviaPlayerRankingInput = {
  id?: string | null,
  Trivia_Id: string,
  GameCode: string,
  CorrectAnswersCount: string,
  IncorrectAnswersCount: string,
  Points?: number | null,
  PlayerId: string,
  CurrentRank?: number | null,
  PrevRank?: number | null,
};

export type ModelTriviaPlayerRankingConditionInput = {
  Trivia_Id?: ModelIDInput | null,
  GameCode?: ModelStringInput | null,
  CorrectAnswersCount?: ModelIDInput | null,
  IncorrectAnswersCount?: ModelIDInput | null,
  Points?: ModelIntInput | null,
  PlayerId?: ModelIDInput | null,
  CurrentRank?: ModelIntInput | null,
  PrevRank?: ModelIntInput | null,
  and?: Array< ModelTriviaPlayerRankingConditionInput | null > | null,
  or?: Array< ModelTriviaPlayerRankingConditionInput | null > | null,
  not?: ModelTriviaPlayerRankingConditionInput | null,
};

export type TriviaPlayerRanking = {
  __typename: "TriviaPlayerRanking",
  id?: string,
  Trivia_Id?: string,
  GameCode?: string,
  CorrectAnswersCount?: string,
  IncorrectAnswersCount?: string,
  Points?: number | null,
  PlayerId?: string,
  CurrentRank?: number | null,
  PrevRank?: number | null,
  Player?: TriviaPlayer,
  createdAt?: string,
  updatedAt?: string,
};

export type UpdateTriviaPlayerRankingInput = {
  id: string,
  Trivia_Id?: string | null,
  GameCode?: string | null,
  CorrectAnswersCount?: string | null,
  IncorrectAnswersCount?: string | null,
  Points?: number | null,
  PlayerId?: string | null,
  CurrentRank?: number | null,
  PrevRank?: number | null,
};

export type DeleteTriviaPlayerRankingInput = {
  id: string,
};

export type CreateTriviaDetailsInput = {
  id?: string | null,
  TotalCategory: number,
  TotalCollection: number,
  TotalQuestion: number,
  TotalCorrectAnswer: number,
  TotalAnswer: number,
};

export type ModelTriviaDetailsConditionInput = {
  TotalCategory?: ModelIntInput | null,
  TotalCollection?: ModelIntInput | null,
  TotalQuestion?: ModelIntInput | null,
  TotalCorrectAnswer?: ModelIntInput | null,
  TotalAnswer?: ModelIntInput | null,
  and?: Array< ModelTriviaDetailsConditionInput | null > | null,
  or?: Array< ModelTriviaDetailsConditionInput | null > | null,
  not?: ModelTriviaDetailsConditionInput | null,
};

export type UpdateTriviaDetailsInput = {
  id: string,
  TotalCategory?: number | null,
  TotalCollection?: number | null,
  TotalQuestion?: number | null,
  TotalCorrectAnswer?: number | null,
  TotalAnswer?: number | null,
};

export type DeleteTriviaDetailsInput = {
  id: string,
};

export type CreateCategoryInput = {
  id?: string | null,
  Image: string,
  Type?: string | null,
  Name: string,
  Color: string,
  TotalCollection: number,
  isActive?: boolean | null,
};

export type ModelCategoryConditionInput = {
  Image?: ModelStringInput | null,
  Type?: ModelStringInput | null,
  Name?: ModelStringInput | null,
  Color?: ModelStringInput | null,
  TotalCollection?: ModelIntInput | null,
  isActive?: ModelBooleanInput | null,
  and?: Array< ModelCategoryConditionInput | null > | null,
  or?: Array< ModelCategoryConditionInput | null > | null,
  not?: ModelCategoryConditionInput | null,
};

export type UpdateCategoryInput = {
  id: string,
  Image?: string | null,
  Type?: string | null,
  Name?: string | null,
  Color?: string | null,
  TotalCollection?: number | null,
  isActive?: boolean | null,
};

export type DeleteCategoryInput = {
  id: string,
};

export type CreateCollectionInput = {
  id?: string | null,
  Category_Id: string,
  Image: string,
  Name: string,
  Description: string,
  GameRules: string,
  CollectionType: string,
  Time: number,
  TotalQuestion: number,
  TimesPlayed: number,
  TotalTime: number,
  Difficulty: number,
  GameCode: string,
  owner?: string | null,
  isPublished: boolean,
  isActive?: boolean | null,
};

export type ModelCollectionConditionInput = {
  Category_Id?: ModelIDInput | null,
  Image?: ModelStringInput | null,
  Name?: ModelStringInput | null,
  Description?: ModelStringInput | null,
  GameRules?: ModelStringInput | null,
  CollectionType?: ModelStringInput | null,
  Time?: ModelIntInput | null,
  TotalQuestion?: ModelIntInput | null,
  TimesPlayed?: ModelIntInput | null,
  TotalTime?: ModelIntInput | null,
  Difficulty?: ModelIntInput | null,
  GameCode?: ModelStringInput | null,
  owner?: ModelStringInput | null,
  isPublished?: ModelBooleanInput | null,
  isActive?: ModelBooleanInput | null,
  and?: Array< ModelCollectionConditionInput | null > | null,
  or?: Array< ModelCollectionConditionInput | null > | null,
  not?: ModelCollectionConditionInput | null,
};

export type UpdateCollectionInput = {
  id: string,
  Category_Id?: string | null,
  Image?: string | null,
  Name?: string | null,
  Description?: string | null,
  GameRules?: string | null,
  CollectionType?: string | null,
  Time?: number | null,
  TotalQuestion?: number | null,
  TimesPlayed?: number | null,
  TotalTime?: number | null,
  Difficulty?: number | null,
  GameCode?: string | null,
  owner?: string | null,
  isPublished?: boolean | null,
  isActive?: boolean | null,
};

export type DeleteCollectionInput = {
  id: string,
};

export type CreateQuestionInput = {
  id?: string | null,
  Collection_Id: string,
  Description: string,
  Rank?: number | null,
  Type?: string | null,
  Media: string,
  Hint?: Array< string | null > | null,
  Answers?: Array< AnswerOptionsInput | null > | null,
  isActive?: boolean | null,
};

export type AnswerOptionsInput = {
  id: string,
  Answer: Array< string | null >,
  Correct: boolean,
  CaseSensitive: boolean,
};

export type ModelQuestionConditionInput = {
  Collection_Id?: ModelIDInput | null,
  Description?: ModelStringInput | null,
  Rank?: ModelIntInput | null,
  Type?: ModelStringInput | null,
  Media?: ModelStringInput | null,
  Hint?: ModelStringInput | null,
  isActive?: ModelBooleanInput | null,
  and?: Array< ModelQuestionConditionInput | null > | null,
  or?: Array< ModelQuestionConditionInput | null > | null,
  not?: ModelQuestionConditionInput | null,
};

export type Question = {
  __typename: "Question",
  id?: string,
  Collection_Id?: string,
  Description?: string,
  Rank?: number | null,
  Type?: string | null,
  Media?: string,
  Hint?: Array< string | null > | null,
  Answers?:  Array<AnswerOptions | null > | null,
  isActive?: boolean | null,
  createdAt?: string,
  updatedAt?: string,
};

export type AnswerOptions = {
  __typename: "AnswerOptions",
  id?: string,
  Answer?: Array< string | null >,
  Correct?: boolean,
  CaseSensitive?: boolean,
};

export type UpdateQuestionInput = {
  id: string,
  Collection_Id?: string | null,
  Description?: string | null,
  Rank?: number | null,
  Type?: string | null,
  Media?: string | null,
  Hint?: Array< string | null > | null,
  Answers?: Array< AnswerOptionsInput | null > | null,
  isActive?: boolean | null,
};

export type DeleteQuestionInput = {
  id: string,
};

export type CreateTriviaSettingInput = {
  id?: string | null,
  CountDownTime: number,
  TriviaMakerPassword: string,
};

export type ModelTriviaSettingConditionInput = {
  CountDownTime?: ModelIntInput | null,
  TriviaMakerPassword?: ModelStringInput | null,
  and?: Array< ModelTriviaSettingConditionInput | null > | null,
  or?: Array< ModelTriviaSettingConditionInput | null > | null,
  not?: ModelTriviaSettingConditionInput | null,
};

export type TriviaSetting = {
  __typename: "TriviaSetting",
  id?: string,
  CountDownTime?: number,
  TriviaMakerPassword?: string,
  createdAt?: string,
  updatedAt?: string,
};

export type UpdateTriviaSettingInput = {
  id: string,
  CountDownTime?: number | null,
  TriviaMakerPassword?: string | null,
};

export type DeleteTriviaSettingInput = {
  id: string,
};

export type CreateTriviaErrorInput = {
  id?: string | null,
  Status: number,
  Message: string,
};

export type ModelTriviaErrorConditionInput = {
  Status?: ModelIntInput | null,
  Message?: ModelStringInput | null,
  and?: Array< ModelTriviaErrorConditionInput | null > | null,
  or?: Array< ModelTriviaErrorConditionInput | null > | null,
  not?: ModelTriviaErrorConditionInput | null,
};

export type TriviaError = {
  __typename: "TriviaError",
  id?: string,
  Status?: number,
  Message?: string,
  createdAt?: string,
  updatedAt?: string,
};

export type UpdateTriviaErrorInput = {
  id: string,
  Status?: number | null,
  Message?: string | null,
};

export type DeleteTriviaErrorInput = {
  id: string,
};

export type ModelGameCodeFilterInput = {
  id?: ModelIDInput | null,
  GameCode?: ModelStringInput | null,
  AdminGameCode?: ModelStringInput | null,
  MaxPlayers?: ModelIntInput | null,
  GameType?: ModelStringInput | null,
  Reseller?: ModelStringInput | null,
  DaysValid?: ModelIntInput | null,
  DateActivated?: ModelStringInput | null,
  DateExpired?: ModelIntInput | null,
  CollectionPlayed?: ModelStringInput | null,
  and?: Array< ModelGameCodeFilterInput | null > | null,
  or?: Array< ModelGameCodeFilterInput | null > | null,
  not?: ModelGameCodeFilterInput | null,
};

export enum ModelSortDirection {
  ASC = "ASC",
  DESC = "DESC",
}


export type ModelGameCodeConnection = {
  __typename: "ModelGameCodeConnection",
  items?:  Array<GameCode | null > | null,
  nextToken?: string | null,
};

export type ModelTriviaFilterInput = {
  id?: ModelIDInput | null,
  GameCode?: ModelIDInput | null,
  DateStarted?: ModelStringInput | null,
  DateCompleted?: ModelStringInput | null,
  HasPowerUps?: ModelBooleanInput | null,
  CurrentRoundId?: ModelIntInput | null,
  CurrentQuestionId?: ModelIntInput | null,
  and?: Array< ModelTriviaFilterInput | null > | null,
  or?: Array< ModelTriviaFilterInput | null > | null,
  not?: ModelTriviaFilterInput | null,
};

export type ModelTriviaConnection = {
  __typename: "ModelTriviaConnection",
  items?:  Array<Trivia | null > | null,
  nextToken?: string | null,
};

export type ModelTriviaRoundFilterInput = {
  id?: ModelIDInput | null,
  Trivia_Id?: ModelIDInput | null,
  DateStarted?: ModelStringInput | null,
  DateCompleted?: ModelStringInput | null,
  Collection_Id?: ModelIDInput | null,
  CurrentQuestionId?: ModelIntInput | null,
  and?: Array< ModelTriviaRoundFilterInput | null > | null,
  or?: Array< ModelTriviaRoundFilterInput | null > | null,
  not?: ModelTriviaRoundFilterInput | null,
};

export type ModelTriviaPlayerFilterInput = {
  id?: ModelIDInput | null,
  Trivia_Id?: ModelIDInput | null,
  GameCode?: ModelIDInput | null,
  PlayerName?: ModelStringInput | null,
  PusherId?: ModelStringInput | null,
  isReady?: ModelBooleanInput | null,
  isAdmin?: ModelBooleanInput | null,
  isSuperAdmin?: ModelBooleanInput | null,
  isAdminActive?: ModelBooleanInput | null,
  Avatar?: ModelStringInput | null,
  and?: Array< ModelTriviaPlayerFilterInput | null > | null,
  or?: Array< ModelTriviaPlayerFilterInput | null > | null,
  not?: ModelTriviaPlayerFilterInput | null,
};

export type ModelTriviaPlayerAnswerFilterInput = {
  id?: ModelIDInput | null,
  Trivia_Id?: ModelIDInput | null,
  QuestionId?: ModelIDInput | null,
  CollectionId?: ModelIDInput | null,
  Answers?: ModelStringInput | null,
  PlayerId?: ModelIDInput | null,
  PlayerName?: ModelStringInput | null,
  IsAnswerCorrect?: ModelBooleanInput | null,
  Points?: ModelIntInput | null,
  PowerUp?: ModelPowerUpListInput | null,
  ReceivedTime?: ModelStringInput | null,
  ReactionTime?: ModelStringInput | null,
  and?: Array< ModelTriviaPlayerAnswerFilterInput | null > | null,
  or?: Array< ModelTriviaPlayerAnswerFilterInput | null > | null,
  not?: ModelTriviaPlayerAnswerFilterInput | null,
};

export type ModelTriviaPlayerAnswerConnection = {
  __typename: "ModelTriviaPlayerAnswerConnection",
  items?:  Array<TriviaPlayerAnswer | null > | null,
  nextToken?: string | null,
};

export type ModelTriviaPlayerRankingFilterInput = {
  id?: ModelIDInput | null,
  Trivia_Id?: ModelIDInput | null,
  GameCode?: ModelStringInput | null,
  CorrectAnswersCount?: ModelIDInput | null,
  IncorrectAnswersCount?: ModelIDInput | null,
  Points?: ModelIntInput | null,
  PlayerId?: ModelIDInput | null,
  CurrentRank?: ModelIntInput | null,
  PrevRank?: ModelIntInput | null,
  and?: Array< ModelTriviaPlayerRankingFilterInput | null > | null,
  or?: Array< ModelTriviaPlayerRankingFilterInput | null > | null,
  not?: ModelTriviaPlayerRankingFilterInput | null,
};

export type ModelTriviaPlayerRankingConnection = {
  __typename: "ModelTriviaPlayerRankingConnection",
  items?:  Array<TriviaPlayerRanking | null > | null,
  nextToken?: string | null,
};

export type ModelTriviaDetailsFilterInput = {
  id?: ModelIDInput | null,
  TotalCategory?: ModelIntInput | null,
  TotalCollection?: ModelIntInput | null,
  TotalQuestion?: ModelIntInput | null,
  TotalCorrectAnswer?: ModelIntInput | null,
  TotalAnswer?: ModelIntInput | null,
  and?: Array< ModelTriviaDetailsFilterInput | null > | null,
  or?: Array< ModelTriviaDetailsFilterInput | null > | null,
  not?: ModelTriviaDetailsFilterInput | null,
};

export type ModelTriviaDetailsConnection = {
  __typename: "ModelTriviaDetailsConnection",
  items?:  Array<TriviaDetails | null > | null,
  nextToken?: string | null,
};

export type ModelCategoryFilterInput = {
  id?: ModelIDInput | null,
  Image?: ModelStringInput | null,
  Type?: ModelStringInput | null,
  Name?: ModelStringInput | null,
  Color?: ModelStringInput | null,
  TotalCollection?: ModelIntInput | null,
  isActive?: ModelBooleanInput | null,
  and?: Array< ModelCategoryFilterInput | null > | null,
  or?: Array< ModelCategoryFilterInput | null > | null,
  not?: ModelCategoryFilterInput | null,
};

export type ModelCategoryConnection = {
  __typename: "ModelCategoryConnection",
  items?:  Array<Category | null > | null,
  nextToken?: string | null,
};

export type ModelCollectionFilterInput = {
  id?: ModelIDInput | null,
  Category_Id?: ModelIDInput | null,
  Image?: ModelStringInput | null,
  Name?: ModelStringInput | null,
  Description?: ModelStringInput | null,
  GameRules?: ModelStringInput | null,
  CollectionType?: ModelStringInput | null,
  Time?: ModelIntInput | null,
  TotalQuestion?: ModelIntInput | null,
  TimesPlayed?: ModelIntInput | null,
  TotalTime?: ModelIntInput | null,
  Difficulty?: ModelIntInput | null,
  GameCode?: ModelStringInput | null,
  owner?: ModelStringInput | null,
  isPublished?: ModelBooleanInput | null,
  isActive?: ModelBooleanInput | null,
  and?: Array< ModelCollectionFilterInput | null > | null,
  or?: Array< ModelCollectionFilterInput | null > | null,
  not?: ModelCollectionFilterInput | null,
};

export type ModelQuestionFilterInput = {
  id?: ModelIDInput | null,
  Collection_Id?: ModelIDInput | null,
  Description?: ModelStringInput | null,
  Rank?: ModelIntInput | null,
  Type?: ModelStringInput | null,
  Media?: ModelStringInput | null,
  Hint?: ModelStringInput | null,
  isActive?: ModelBooleanInput | null,
  and?: Array< ModelQuestionFilterInput | null > | null,
  or?: Array< ModelQuestionFilterInput | null > | null,
  not?: ModelQuestionFilterInput | null,
};

export type ModelQuestionConnection = {
  __typename: "ModelQuestionConnection",
  items?:  Array<Question | null > | null,
  nextToken?: string | null,
};

export type ModelTriviaSettingFilterInput = {
  id?: ModelIDInput | null,
  CountDownTime?: ModelIntInput | null,
  TriviaMakerPassword?: ModelStringInput | null,
  and?: Array< ModelTriviaSettingFilterInput | null > | null,
  or?: Array< ModelTriviaSettingFilterInput | null > | null,
  not?: ModelTriviaSettingFilterInput | null,
};

export type ModelTriviaSettingConnection = {
  __typename: "ModelTriviaSettingConnection",
  items?:  Array<TriviaSetting | null > | null,
  nextToken?: string | null,
};

export type ModelTriviaErrorFilterInput = {
  id?: ModelIDInput | null,
  Status?: ModelIntInput | null,
  Message?: ModelStringInput | null,
  and?: Array< ModelTriviaErrorFilterInput | null > | null,
  or?: Array< ModelTriviaErrorFilterInput | null > | null,
  not?: ModelTriviaErrorFilterInput | null,
};

export type ModelTriviaErrorConnection = {
  __typename: "ModelTriviaErrorConnection",
  items?:  Array<TriviaError | null > | null,
  nextToken?: string | null,
};

export type ModelIDKeyConditionInput = {
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
};

export type IncrementTriviaDetailsMutationVariables = {
  id?: string,
  categoryValue?: number | null,
  collectionValue?: number | null,
  questionValue?: number | null,
  correctAnswerValue?: number | null,
  answerValue?: number | null,
};

export type IncrementTriviaDetailsMutation = {
  incrementTriviaDetails?:  {
    __typename: "TriviaDetails",
    id: string,
    TotalCategory: number,
    TotalCollection: number,
    TotalQuestion: number,
    TotalCorrectAnswer: number,
    TotalAnswer: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type IncrementCategoryCollectionsMutationVariables = {
  id?: string,
  collectionValue?: number | null,
};

export type IncrementCategoryCollectionsMutation = {
  incrementCategoryCollections?:  {
    __typename: "Category",
    id: string,
    Image: string,
    Type?: string | null,
    Name: string,
    Color: string,
    TotalCollection: number,
    Collection?:  {
      __typename: "ModelCollectionConnection",
      items?:  Array< {
        __typename: "Collection",
        id: string,
        Category_Id: string,
        Image: string,
        Name: string,
        Description: string,
        GameRules: string,
        CollectionType: string,
        Time: number,
        TotalQuestion: number,
        TimesPlayed: number,
        TotalTime: number,
        Difficulty: number,
        GameCode: string,
        owner?: string | null,
        isPublished: boolean,
        isActive?: boolean | null,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      nextToken?: string | null,
    } | null,
    isActive?: boolean | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type IncrementCollectionQuestionsMutationVariables = {
  id?: string,
  questionValue?: number | null,
};

export type IncrementCollectionQuestionsMutation = {
  incrementCollectionQuestions?:  {
    __typename: "Collection",
    id: string,
    Category_Id: string,
    Category?:  {
      __typename: "Category",
      id: string,
      Image: string,
      Type?: string | null,
      Name: string,
      Color: string,
      TotalCollection: number,
      Collection?:  {
        __typename: "ModelCollectionConnection",
        nextToken?: string | null,
      } | null,
      isActive?: boolean | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    Image: string,
    Name: string,
    Description: string,
    GameRules: string,
    CollectionType: string,
    Time: number,
    TotalQuestion: number,
    TimesPlayed: number,
    TotalTime: number,
    Difficulty: number,
    GameCode: string,
    owner?: string | null,
    isPublished: boolean,
    TriviaRound?:  {
      __typename: "ModelTriviaRoundConnection",
      items?:  Array< {
        __typename: "TriviaRound",
        id: string,
        Trivia_Id: string,
        DateStarted?: string | null,
        DateCompleted?: string | null,
        Collection_Id?: string | null,
        CurrentQuestionId?: number | null,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      nextToken?: string | null,
    } | null,
    isActive?: boolean | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type IncrementCollectionTimesPlayedMutationVariables = {
  id?: string,
  timesPlayedValue?: number | null,
};

export type IncrementCollectionTimesPlayedMutation = {
  incrementCollectionTimesPlayed?:  {
    __typename: "Collection",
    id: string,
    Category_Id: string,
    Category?:  {
      __typename: "Category",
      id: string,
      Image: string,
      Type?: string | null,
      Name: string,
      Color: string,
      TotalCollection: number,
      Collection?:  {
        __typename: "ModelCollectionConnection",
        nextToken?: string | null,
      } | null,
      isActive?: boolean | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    Image: string,
    Name: string,
    Description: string,
    GameRules: string,
    CollectionType: string,
    Time: number,
    TotalQuestion: number,
    TimesPlayed: number,
    TotalTime: number,
    Difficulty: number,
    GameCode: string,
    owner?: string | null,
    isPublished: boolean,
    TriviaRound?:  {
      __typename: "ModelTriviaRoundConnection",
      items?:  Array< {
        __typename: "TriviaRound",
        id: string,
        Trivia_Id: string,
        DateStarted?: string | null,
        DateCompleted?: string | null,
        Collection_Id?: string | null,
        CurrentQuestionId?: number | null,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      nextToken?: string | null,
    } | null,
    isActive?: boolean | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateGameCodeMutationVariables = {
  input?: CreateGameCodeInput,
  condition?: ModelGameCodeConditionInput | null,
};

export type CreateGameCodeMutation = {
  createGameCode?:  {
    __typename: "GameCode",
    id: string,
    GameCode: string,
    AdminGameCode: string,
    MaxPlayers: number,
    GameType: string,
    Reseller: string,
    DaysValid: number,
    DateActivated?: string | null,
    DateExpired?: number | null,
    CollectionPlayed?: Array< string | null > | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateGameCodeMutationVariables = {
  input?: UpdateGameCodeInput,
  condition?: ModelGameCodeConditionInput | null,
};

export type UpdateGameCodeMutation = {
  updateGameCode?:  {
    __typename: "GameCode",
    id: string,
    GameCode: string,
    AdminGameCode: string,
    MaxPlayers: number,
    GameType: string,
    Reseller: string,
    DaysValid: number,
    DateActivated?: string | null,
    DateExpired?: number | null,
    CollectionPlayed?: Array< string | null > | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteGameCodeMutationVariables = {
  input?: DeleteGameCodeInput,
  condition?: ModelGameCodeConditionInput | null,
};

export type DeleteGameCodeMutation = {
  deleteGameCode?:  {
    __typename: "GameCode",
    id: string,
    GameCode: string,
    AdminGameCode: string,
    MaxPlayers: number,
    GameType: string,
    Reseller: string,
    DaysValid: number,
    DateActivated?: string | null,
    DateExpired?: number | null,
    CollectionPlayed?: Array< string | null > | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateTriviaMutationVariables = {
  input?: CreateTriviaInput,
  condition?: ModelTriviaConditionInput | null,
};

export type CreateTriviaMutation = {
  createTrivia?:  {
    __typename: "Trivia",
    id: string,
    GameCode: string,
    DateStarted?: string | null,
    DateCompleted?: string | null,
    HasPowerUps?: boolean | null,
    CurrentRoundId?: number | null,
    CurrentQuestionId?: number | null,
    TriviaRound?:  {
      __typename: "ModelTriviaRoundConnection",
      items?:  Array< {
        __typename: "TriviaRound",
        id: string,
        Trivia_Id: string,
        DateStarted?: string | null,
        DateCompleted?: string | null,
        Collection_Id?: string | null,
        CurrentQuestionId?: number | null,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      nextToken?: string | null,
    } | null,
    TriviaPlayers?:  {
      __typename: "ModelTriviaPlayerConnection",
      items?:  Array< {
        __typename: "TriviaPlayer",
        id: string,
        Trivia_Id?: string | null,
        GameCode: string,
        PlayerName: string,
        PusherId?: string | null,
        isReady: boolean,
        isAdmin: boolean,
        isSuperAdmin?: boolean | null,
        isAdminActive?: boolean | null,
        Avatar?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateTriviaMutationVariables = {
  input?: UpdateTriviaInput,
  condition?: ModelTriviaConditionInput | null,
};

export type UpdateTriviaMutation = {
  updateTrivia?:  {
    __typename: "Trivia",
    id: string,
    GameCode: string,
    DateStarted?: string | null,
    DateCompleted?: string | null,
    HasPowerUps?: boolean | null,
    CurrentRoundId?: number | null,
    CurrentQuestionId?: number | null,
    TriviaRound?:  {
      __typename: "ModelTriviaRoundConnection",
      items?:  Array< {
        __typename: "TriviaRound",
        id: string,
        Trivia_Id: string,
        DateStarted?: string | null,
        DateCompleted?: string | null,
        Collection_Id?: string | null,
        CurrentQuestionId?: number | null,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      nextToken?: string | null,
    } | null,
    TriviaPlayers?:  {
      __typename: "ModelTriviaPlayerConnection",
      items?:  Array< {
        __typename: "TriviaPlayer",
        id: string,
        Trivia_Id?: string | null,
        GameCode: string,
        PlayerName: string,
        PusherId?: string | null,
        isReady: boolean,
        isAdmin: boolean,
        isSuperAdmin?: boolean | null,
        isAdminActive?: boolean | null,
        Avatar?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteTriviaMutationVariables = {
  input?: DeleteTriviaInput,
  condition?: ModelTriviaConditionInput | null,
};

export type DeleteTriviaMutation = {
  deleteTrivia?:  {
    __typename: "Trivia",
    id: string,
    GameCode: string,
    DateStarted?: string | null,
    DateCompleted?: string | null,
    HasPowerUps?: boolean | null,
    CurrentRoundId?: number | null,
    CurrentQuestionId?: number | null,
    TriviaRound?:  {
      __typename: "ModelTriviaRoundConnection",
      items?:  Array< {
        __typename: "TriviaRound",
        id: string,
        Trivia_Id: string,
        DateStarted?: string | null,
        DateCompleted?: string | null,
        Collection_Id?: string | null,
        CurrentQuestionId?: number | null,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      nextToken?: string | null,
    } | null,
    TriviaPlayers?:  {
      __typename: "ModelTriviaPlayerConnection",
      items?:  Array< {
        __typename: "TriviaPlayer",
        id: string,
        Trivia_Id?: string | null,
        GameCode: string,
        PlayerName: string,
        PusherId?: string | null,
        isReady: boolean,
        isAdmin: boolean,
        isSuperAdmin?: boolean | null,
        isAdminActive?: boolean | null,
        Avatar?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateTriviaRoundMutationVariables = {
  input?: CreateTriviaRoundInput,
  condition?: ModelTriviaRoundConditionInput | null,
};

export type CreateTriviaRoundMutation = {
  createTriviaRound?:  {
    __typename: "TriviaRound",
    id: string,
    Trivia_Id: string,
    Trivia?:  {
      __typename: "Trivia",
      id: string,
      GameCode: string,
      DateStarted?: string | null,
      DateCompleted?: string | null,
      HasPowerUps?: boolean | null,
      CurrentRoundId?: number | null,
      CurrentQuestionId?: number | null,
      TriviaRound?:  {
        __typename: "ModelTriviaRoundConnection",
        nextToken?: string | null,
      } | null,
      TriviaPlayers?:  {
        __typename: "ModelTriviaPlayerConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    DateStarted?: string | null,
    DateCompleted?: string | null,
    Collection_Id?: string | null,
    Collection?:  {
      __typename: "Collection",
      id: string,
      Category_Id: string,
      Category?:  {
        __typename: "Category",
        id: string,
        Image: string,
        Type?: string | null,
        Name: string,
        Color: string,
        TotalCollection: number,
        isActive?: boolean | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      Image: string,
      Name: string,
      Description: string,
      GameRules: string,
      CollectionType: string,
      Time: number,
      TotalQuestion: number,
      TimesPlayed: number,
      TotalTime: number,
      Difficulty: number,
      GameCode: string,
      owner?: string | null,
      isPublished: boolean,
      TriviaRound?:  {
        __typename: "ModelTriviaRoundConnection",
        nextToken?: string | null,
      } | null,
      isActive?: boolean | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    CurrentQuestionId?: number | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateTriviaRoundMutationVariables = {
  input?: UpdateTriviaRoundInput,
  condition?: ModelTriviaRoundConditionInput | null,
};

export type UpdateTriviaRoundMutation = {
  updateTriviaRound?:  {
    __typename: "TriviaRound",
    id: string,
    Trivia_Id: string,
    Trivia?:  {
      __typename: "Trivia",
      id: string,
      GameCode: string,
      DateStarted?: string | null,
      DateCompleted?: string | null,
      HasPowerUps?: boolean | null,
      CurrentRoundId?: number | null,
      CurrentQuestionId?: number | null,
      TriviaRound?:  {
        __typename: "ModelTriviaRoundConnection",
        nextToken?: string | null,
      } | null,
      TriviaPlayers?:  {
        __typename: "ModelTriviaPlayerConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    DateStarted?: string | null,
    DateCompleted?: string | null,
    Collection_Id?: string | null,
    Collection?:  {
      __typename: "Collection",
      id: string,
      Category_Id: string,
      Category?:  {
        __typename: "Category",
        id: string,
        Image: string,
        Type?: string | null,
        Name: string,
        Color: string,
        TotalCollection: number,
        isActive?: boolean | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      Image: string,
      Name: string,
      Description: string,
      GameRules: string,
      CollectionType: string,
      Time: number,
      TotalQuestion: number,
      TimesPlayed: number,
      TotalTime: number,
      Difficulty: number,
      GameCode: string,
      owner?: string | null,
      isPublished: boolean,
      TriviaRound?:  {
        __typename: "ModelTriviaRoundConnection",
        nextToken?: string | null,
      } | null,
      isActive?: boolean | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    CurrentQuestionId?: number | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteTriviaRoundMutationVariables = {
  input?: DeleteTriviaRoundInput,
  condition?: ModelTriviaRoundConditionInput | null,
};

export type DeleteTriviaRoundMutation = {
  deleteTriviaRound?:  {
    __typename: "TriviaRound",
    id: string,
    Trivia_Id: string,
    Trivia?:  {
      __typename: "Trivia",
      id: string,
      GameCode: string,
      DateStarted?: string | null,
      DateCompleted?: string | null,
      HasPowerUps?: boolean | null,
      CurrentRoundId?: number | null,
      CurrentQuestionId?: number | null,
      TriviaRound?:  {
        __typename: "ModelTriviaRoundConnection",
        nextToken?: string | null,
      } | null,
      TriviaPlayers?:  {
        __typename: "ModelTriviaPlayerConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    DateStarted?: string | null,
    DateCompleted?: string | null,
    Collection_Id?: string | null,
    Collection?:  {
      __typename: "Collection",
      id: string,
      Category_Id: string,
      Category?:  {
        __typename: "Category",
        id: string,
        Image: string,
        Type?: string | null,
        Name: string,
        Color: string,
        TotalCollection: number,
        isActive?: boolean | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      Image: string,
      Name: string,
      Description: string,
      GameRules: string,
      CollectionType: string,
      Time: number,
      TotalQuestion: number,
      TimesPlayed: number,
      TotalTime: number,
      Difficulty: number,
      GameCode: string,
      owner?: string | null,
      isPublished: boolean,
      TriviaRound?:  {
        __typename: "ModelTriviaRoundConnection",
        nextToken?: string | null,
      } | null,
      isActive?: boolean | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    CurrentQuestionId?: number | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateTriviaPlayerMutationVariables = {
  input?: CreateTriviaPlayerInput,
  condition?: ModelTriviaPlayerConditionInput | null,
};

export type CreateTriviaPlayerMutation = {
  createTriviaPlayer?:  {
    __typename: "TriviaPlayer",
    id: string,
    Trivia_Id?: string | null,
    GameCode: string,
    Trivia?:  {
      __typename: "Trivia",
      id: string,
      GameCode: string,
      DateStarted?: string | null,
      DateCompleted?: string | null,
      HasPowerUps?: boolean | null,
      CurrentRoundId?: number | null,
      CurrentQuestionId?: number | null,
      TriviaRound?:  {
        __typename: "ModelTriviaRoundConnection",
        nextToken?: string | null,
      } | null,
      TriviaPlayers?:  {
        __typename: "ModelTriviaPlayerConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    PlayerName: string,
    PusherId?: string | null,
    isReady: boolean,
    isAdmin: boolean,
    isSuperAdmin?: boolean | null,
    isAdminActive?: boolean | null,
    PowerUps?:  Array< {
      __typename: "PlayerPowerUps",
      id: string,
      PowerUp: PowerUp,
      isUsed: boolean,
    } | null > | null,
    Avatar?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateTriviaPlayerMutationVariables = {
  input?: UpdateTriviaPlayerInput,
  condition?: ModelTriviaPlayerConditionInput | null,
};

export type UpdateTriviaPlayerMutation = {
  updateTriviaPlayer?:  {
    __typename: "TriviaPlayer",
    id: string,
    Trivia_Id?: string | null,
    GameCode: string,
    Trivia?:  {
      __typename: "Trivia",
      id: string,
      GameCode: string,
      DateStarted?: string | null,
      DateCompleted?: string | null,
      HasPowerUps?: boolean | null,
      CurrentRoundId?: number | null,
      CurrentQuestionId?: number | null,
      TriviaRound?:  {
        __typename: "ModelTriviaRoundConnection",
        nextToken?: string | null,
      } | null,
      TriviaPlayers?:  {
        __typename: "ModelTriviaPlayerConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    PlayerName: string,
    PusherId?: string | null,
    isReady: boolean,
    isAdmin: boolean,
    isSuperAdmin?: boolean | null,
    isAdminActive?: boolean | null,
    PowerUps?:  Array< {
      __typename: "PlayerPowerUps",
      id: string,
      PowerUp: PowerUp,
      isUsed: boolean,
    } | null > | null,
    Avatar?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteTriviaPlayerMutationVariables = {
  input?: DeleteTriviaPlayerInput,
  condition?: ModelTriviaPlayerConditionInput | null,
};

export type DeleteTriviaPlayerMutation = {
  deleteTriviaPlayer?:  {
    __typename: "TriviaPlayer",
    id: string,
    Trivia_Id?: string | null,
    GameCode: string,
    Trivia?:  {
      __typename: "Trivia",
      id: string,
      GameCode: string,
      DateStarted?: string | null,
      DateCompleted?: string | null,
      HasPowerUps?: boolean | null,
      CurrentRoundId?: number | null,
      CurrentQuestionId?: number | null,
      TriviaRound?:  {
        __typename: "ModelTriviaRoundConnection",
        nextToken?: string | null,
      } | null,
      TriviaPlayers?:  {
        __typename: "ModelTriviaPlayerConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    PlayerName: string,
    PusherId?: string | null,
    isReady: boolean,
    isAdmin: boolean,
    isSuperAdmin?: boolean | null,
    isAdminActive?: boolean | null,
    PowerUps?:  Array< {
      __typename: "PlayerPowerUps",
      id: string,
      PowerUp: PowerUp,
      isUsed: boolean,
    } | null > | null,
    Avatar?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateTriviaPlayerAnswerMutationVariables = {
  input?: CreateTriviaPlayerAnswerInput,
  condition?: ModelTriviaPlayerAnswerConditionInput | null,
};

export type CreateTriviaPlayerAnswerMutation = {
  createTriviaPlayerAnswer?:  {
    __typename: "TriviaPlayerAnswer",
    id: string,
    Trivia_Id: string,
    QuestionId: string,
    CollectionId: string,
    Answers?: Array< string | null > | null,
    PlayerId: string,
    PlayerName?: string | null,
    IsAnswerCorrect?: boolean | null,
    Points?: number | null,
    PowerUp?: Array< PowerUp | null > | null,
    ReceivedTime?: string | null,
    ReactionTime?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateTriviaPlayerAnswerMutationVariables = {
  input?: UpdateTriviaPlayerAnswerInput,
  condition?: ModelTriviaPlayerAnswerConditionInput | null,
};

export type UpdateTriviaPlayerAnswerMutation = {
  updateTriviaPlayerAnswer?:  {
    __typename: "TriviaPlayerAnswer",
    id: string,
    Trivia_Id: string,
    QuestionId: string,
    CollectionId: string,
    Answers?: Array< string | null > | null,
    PlayerId: string,
    PlayerName?: string | null,
    IsAnswerCorrect?: boolean | null,
    Points?: number | null,
    PowerUp?: Array< PowerUp | null > | null,
    ReceivedTime?: string | null,
    ReactionTime?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteTriviaPlayerAnswerMutationVariables = {
  input?: DeleteTriviaPlayerAnswerInput,
  condition?: ModelTriviaPlayerAnswerConditionInput | null,
};

export type DeleteTriviaPlayerAnswerMutation = {
  deleteTriviaPlayerAnswer?:  {
    __typename: "TriviaPlayerAnswer",
    id: string,
    Trivia_Id: string,
    QuestionId: string,
    CollectionId: string,
    Answers?: Array< string | null > | null,
    PlayerId: string,
    PlayerName?: string | null,
    IsAnswerCorrect?: boolean | null,
    Points?: number | null,
    PowerUp?: Array< PowerUp | null > | null,
    ReceivedTime?: string | null,
    ReactionTime?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateTriviaPlayerRankingMutationVariables = {
  input?: CreateTriviaPlayerRankingInput,
  condition?: ModelTriviaPlayerRankingConditionInput | null,
};

export type CreateTriviaPlayerRankingMutation = {
  createTriviaPlayerRanking?:  {
    __typename: "TriviaPlayerRanking",
    id: string,
    Trivia_Id: string,
    GameCode: string,
    CorrectAnswersCount: string,
    IncorrectAnswersCount: string,
    Points?: number | null,
    PlayerId: string,
    CurrentRank?: number | null,
    PrevRank?: number | null,
    Player?:  {
      __typename: "TriviaPlayer",
      id: string,
      Trivia_Id?: string | null,
      GameCode: string,
      Trivia?:  {
        __typename: "Trivia",
        id: string,
        GameCode: string,
        DateStarted?: string | null,
        DateCompleted?: string | null,
        HasPowerUps?: boolean | null,
        CurrentRoundId?: number | null,
        CurrentQuestionId?: number | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      PlayerName: string,
      PusherId?: string | null,
      isReady: boolean,
      isAdmin: boolean,
      isSuperAdmin?: boolean | null,
      isAdminActive?: boolean | null,
      PowerUps?:  Array< {
        __typename: "PlayerPowerUps",
        id: string,
        PowerUp: PowerUp,
        isUsed: boolean,
      } | null > | null,
      Avatar?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateTriviaPlayerRankingMutationVariables = {
  input?: UpdateTriviaPlayerRankingInput,
  condition?: ModelTriviaPlayerRankingConditionInput | null,
};

export type UpdateTriviaPlayerRankingMutation = {
  updateTriviaPlayerRanking?:  {
    __typename: "TriviaPlayerRanking",
    id: string,
    Trivia_Id: string,
    GameCode: string,
    CorrectAnswersCount: string,
    IncorrectAnswersCount: string,
    Points?: number | null,
    PlayerId: string,
    CurrentRank?: number | null,
    PrevRank?: number | null,
    Player?:  {
      __typename: "TriviaPlayer",
      id: string,
      Trivia_Id?: string | null,
      GameCode: string,
      Trivia?:  {
        __typename: "Trivia",
        id: string,
        GameCode: string,
        DateStarted?: string | null,
        DateCompleted?: string | null,
        HasPowerUps?: boolean | null,
        CurrentRoundId?: number | null,
        CurrentQuestionId?: number | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      PlayerName: string,
      PusherId?: string | null,
      isReady: boolean,
      isAdmin: boolean,
      isSuperAdmin?: boolean | null,
      isAdminActive?: boolean | null,
      PowerUps?:  Array< {
        __typename: "PlayerPowerUps",
        id: string,
        PowerUp: PowerUp,
        isUsed: boolean,
      } | null > | null,
      Avatar?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteTriviaPlayerRankingMutationVariables = {
  input?: DeleteTriviaPlayerRankingInput,
  condition?: ModelTriviaPlayerRankingConditionInput | null,
};

export type DeleteTriviaPlayerRankingMutation = {
  deleteTriviaPlayerRanking?:  {
    __typename: "TriviaPlayerRanking",
    id: string,
    Trivia_Id: string,
    GameCode: string,
    CorrectAnswersCount: string,
    IncorrectAnswersCount: string,
    Points?: number | null,
    PlayerId: string,
    CurrentRank?: number | null,
    PrevRank?: number | null,
    Player?:  {
      __typename: "TriviaPlayer",
      id: string,
      Trivia_Id?: string | null,
      GameCode: string,
      Trivia?:  {
        __typename: "Trivia",
        id: string,
        GameCode: string,
        DateStarted?: string | null,
        DateCompleted?: string | null,
        HasPowerUps?: boolean | null,
        CurrentRoundId?: number | null,
        CurrentQuestionId?: number | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      PlayerName: string,
      PusherId?: string | null,
      isReady: boolean,
      isAdmin: boolean,
      isSuperAdmin?: boolean | null,
      isAdminActive?: boolean | null,
      PowerUps?:  Array< {
        __typename: "PlayerPowerUps",
        id: string,
        PowerUp: PowerUp,
        isUsed: boolean,
      } | null > | null,
      Avatar?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateTriviaDetailsMutationVariables = {
  input?: CreateTriviaDetailsInput,
  condition?: ModelTriviaDetailsConditionInput | null,
};

export type CreateTriviaDetailsMutation = {
  createTriviaDetails?:  {
    __typename: "TriviaDetails",
    id: string,
    TotalCategory: number,
    TotalCollection: number,
    TotalQuestion: number,
    TotalCorrectAnswer: number,
    TotalAnswer: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateTriviaDetailsMutationVariables = {
  input?: UpdateTriviaDetailsInput,
  condition?: ModelTriviaDetailsConditionInput | null,
};

export type UpdateTriviaDetailsMutation = {
  updateTriviaDetails?:  {
    __typename: "TriviaDetails",
    id: string,
    TotalCategory: number,
    TotalCollection: number,
    TotalQuestion: number,
    TotalCorrectAnswer: number,
    TotalAnswer: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteTriviaDetailsMutationVariables = {
  input?: DeleteTriviaDetailsInput,
  condition?: ModelTriviaDetailsConditionInput | null,
};

export type DeleteTriviaDetailsMutation = {
  deleteTriviaDetails?:  {
    __typename: "TriviaDetails",
    id: string,
    TotalCategory: number,
    TotalCollection: number,
    TotalQuestion: number,
    TotalCorrectAnswer: number,
    TotalAnswer: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateCategoryMutationVariables = {
  input?: CreateCategoryInput,
  condition?: ModelCategoryConditionInput | null,
};

export type CreateCategoryMutation = {
  createCategory?:  {
    __typename: "Category",
    id: string,
    Image: string,
    Type?: string | null,
    Name: string,
    Color: string,
    TotalCollection: number,
    Collection?:  {
      __typename: "ModelCollectionConnection",
      items?:  Array< {
        __typename: "Collection",
        id: string,
        Category_Id: string,
        Image: string,
        Name: string,
        Description: string,
        GameRules: string,
        CollectionType: string,
        Time: number,
        TotalQuestion: number,
        TimesPlayed: number,
        TotalTime: number,
        Difficulty: number,
        GameCode: string,
        owner?: string | null,
        isPublished: boolean,
        isActive?: boolean | null,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      nextToken?: string | null,
    } | null,
    isActive?: boolean | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateCategoryMutationVariables = {
  input?: UpdateCategoryInput,
  condition?: ModelCategoryConditionInput | null,
};

export type UpdateCategoryMutation = {
  updateCategory?:  {
    __typename: "Category",
    id: string,
    Image: string,
    Type?: string | null,
    Name: string,
    Color: string,
    TotalCollection: number,
    Collection?:  {
      __typename: "ModelCollectionConnection",
      items?:  Array< {
        __typename: "Collection",
        id: string,
        Category_Id: string,
        Image: string,
        Name: string,
        Description: string,
        GameRules: string,
        CollectionType: string,
        Time: number,
        TotalQuestion: number,
        TimesPlayed: number,
        TotalTime: number,
        Difficulty: number,
        GameCode: string,
        owner?: string | null,
        isPublished: boolean,
        isActive?: boolean | null,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      nextToken?: string | null,
    } | null,
    isActive?: boolean | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteCategoryMutationVariables = {
  input?: DeleteCategoryInput,
  condition?: ModelCategoryConditionInput | null,
};

export type DeleteCategoryMutation = {
  deleteCategory?:  {
    __typename: "Category",
    id: string,
    Image: string,
    Type?: string | null,
    Name: string,
    Color: string,
    TotalCollection: number,
    Collection?:  {
      __typename: "ModelCollectionConnection",
      items?:  Array< {
        __typename: "Collection",
        id: string,
        Category_Id: string,
        Image: string,
        Name: string,
        Description: string,
        GameRules: string,
        CollectionType: string,
        Time: number,
        TotalQuestion: number,
        TimesPlayed: number,
        TotalTime: number,
        Difficulty: number,
        GameCode: string,
        owner?: string | null,
        isPublished: boolean,
        isActive?: boolean | null,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      nextToken?: string | null,
    } | null,
    isActive?: boolean | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateCollectionMutationVariables = {
  input?: CreateCollectionInput,
  condition?: ModelCollectionConditionInput | null,
};

export type CreateCollectionMutation = {
  createCollection?:  {
    __typename: "Collection",
    id: string,
    Category_Id: string,
    Category?:  {
      __typename: "Category",
      id: string,
      Image: string,
      Type?: string | null,
      Name: string,
      Color: string,
      TotalCollection: number,
      Collection?:  {
        __typename: "ModelCollectionConnection",
        nextToken?: string | null,
      } | null,
      isActive?: boolean | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    Image: string,
    Name: string,
    Description: string,
    GameRules: string,
    CollectionType: string,
    Time: number,
    TotalQuestion: number,
    TimesPlayed: number,
    TotalTime: number,
    Difficulty: number,
    GameCode: string,
    owner?: string | null,
    isPublished: boolean,
    TriviaRound?:  {
      __typename: "ModelTriviaRoundConnection",
      items?:  Array< {
        __typename: "TriviaRound",
        id: string,
        Trivia_Id: string,
        DateStarted?: string | null,
        DateCompleted?: string | null,
        Collection_Id?: string | null,
        CurrentQuestionId?: number | null,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      nextToken?: string | null,
    } | null,
    isActive?: boolean | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateCollectionMutationVariables = {
  input?: UpdateCollectionInput,
  condition?: ModelCollectionConditionInput | null,
};

export type UpdateCollectionMutation = {
  updateCollection?:  {
    __typename: "Collection",
    id: string,
    Category_Id: string,
    Category?:  {
      __typename: "Category",
      id: string,
      Image: string,
      Type?: string | null,
      Name: string,
      Color: string,
      TotalCollection: number,
      Collection?:  {
        __typename: "ModelCollectionConnection",
        nextToken?: string | null,
      } | null,
      isActive?: boolean | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    Image: string,
    Name: string,
    Description: string,
    GameRules: string,
    CollectionType: string,
    Time: number,
    TotalQuestion: number,
    TimesPlayed: number,
    TotalTime: number,
    Difficulty: number,
    GameCode: string,
    owner?: string | null,
    isPublished: boolean,
    TriviaRound?:  {
      __typename: "ModelTriviaRoundConnection",
      items?:  Array< {
        __typename: "TriviaRound",
        id: string,
        Trivia_Id: string,
        DateStarted?: string | null,
        DateCompleted?: string | null,
        Collection_Id?: string | null,
        CurrentQuestionId?: number | null,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      nextToken?: string | null,
    } | null,
    isActive?: boolean | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteCollectionMutationVariables = {
  input?: DeleteCollectionInput,
  condition?: ModelCollectionConditionInput | null,
};

export type DeleteCollectionMutation = {
  deleteCollection?:  {
    __typename: "Collection",
    id: string,
    Category_Id: string,
    Category?:  {
      __typename: "Category",
      id: string,
      Image: string,
      Type?: string | null,
      Name: string,
      Color: string,
      TotalCollection: number,
      Collection?:  {
        __typename: "ModelCollectionConnection",
        nextToken?: string | null,
      } | null,
      isActive?: boolean | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    Image: string,
    Name: string,
    Description: string,
    GameRules: string,
    CollectionType: string,
    Time: number,
    TotalQuestion: number,
    TimesPlayed: number,
    TotalTime: number,
    Difficulty: number,
    GameCode: string,
    owner?: string | null,
    isPublished: boolean,
    TriviaRound?:  {
      __typename: "ModelTriviaRoundConnection",
      items?:  Array< {
        __typename: "TriviaRound",
        id: string,
        Trivia_Id: string,
        DateStarted?: string | null,
        DateCompleted?: string | null,
        Collection_Id?: string | null,
        CurrentQuestionId?: number | null,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      nextToken?: string | null,
    } | null,
    isActive?: boolean | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateQuestionMutationVariables = {
  input?: CreateQuestionInput,
  condition?: ModelQuestionConditionInput | null,
};

export type CreateQuestionMutation = {
  createQuestion?:  {
    __typename: "Question",
    id: string,
    Collection_Id: string,
    Description: string,
    Rank?: number | null,
    Type?: string | null,
    Media: string,
    Hint?: Array< string | null > | null,
    Answers?:  Array< {
      __typename: "AnswerOptions",
      id: string,
      Answer: Array< string | null >,
      Correct: boolean,
      CaseSensitive: boolean,
    } | null > | null,
    isActive?: boolean | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateQuestionMutationVariables = {
  input?: UpdateQuestionInput,
  condition?: ModelQuestionConditionInput | null,
};

export type UpdateQuestionMutation = {
  updateQuestion?:  {
    __typename: "Question",
    id: string,
    Collection_Id: string,
    Description: string,
    Rank?: number | null,
    Type?: string | null,
    Media: string,
    Hint?: Array< string | null > | null,
    Answers?:  Array< {
      __typename: "AnswerOptions",
      id: string,
      Answer: Array< string | null >,
      Correct: boolean,
      CaseSensitive: boolean,
    } | null > | null,
    isActive?: boolean | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteQuestionMutationVariables = {
  input?: DeleteQuestionInput,
  condition?: ModelQuestionConditionInput | null,
};

export type DeleteQuestionMutation = {
  deleteQuestion?:  {
    __typename: "Question",
    id: string,
    Collection_Id: string,
    Description: string,
    Rank?: number | null,
    Type?: string | null,
    Media: string,
    Hint?: Array< string | null > | null,
    Answers?:  Array< {
      __typename: "AnswerOptions",
      id: string,
      Answer: Array< string | null >,
      Correct: boolean,
      CaseSensitive: boolean,
    } | null > | null,
    isActive?: boolean | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateTriviaSettingMutationVariables = {
  input?: CreateTriviaSettingInput,
  condition?: ModelTriviaSettingConditionInput | null,
};

export type CreateTriviaSettingMutation = {
  createTriviaSetting?:  {
    __typename: "TriviaSetting",
    id: string,
    CountDownTime: number,
    TriviaMakerPassword: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateTriviaSettingMutationVariables = {
  input?: UpdateTriviaSettingInput,
  condition?: ModelTriviaSettingConditionInput | null,
};

export type UpdateTriviaSettingMutation = {
  updateTriviaSetting?:  {
    __typename: "TriviaSetting",
    id: string,
    CountDownTime: number,
    TriviaMakerPassword: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteTriviaSettingMutationVariables = {
  input?: DeleteTriviaSettingInput,
  condition?: ModelTriviaSettingConditionInput | null,
};

export type DeleteTriviaSettingMutation = {
  deleteTriviaSetting?:  {
    __typename: "TriviaSetting",
    id: string,
    CountDownTime: number,
    TriviaMakerPassword: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateTriviaErrorMutationVariables = {
  input?: CreateTriviaErrorInput,
  condition?: ModelTriviaErrorConditionInput | null,
};

export type CreateTriviaErrorMutation = {
  createTriviaError?:  {
    __typename: "TriviaError",
    id: string,
    Status: number,
    Message: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateTriviaErrorMutationVariables = {
  input?: UpdateTriviaErrorInput,
  condition?: ModelTriviaErrorConditionInput | null,
};

export type UpdateTriviaErrorMutation = {
  updateTriviaError?:  {
    __typename: "TriviaError",
    id: string,
    Status: number,
    Message: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteTriviaErrorMutationVariables = {
  input?: DeleteTriviaErrorInput,
  condition?: ModelTriviaErrorConditionInput | null,
};

export type DeleteTriviaErrorMutation = {
  deleteTriviaError?:  {
    __typename: "TriviaError",
    id: string,
    Status: number,
    Message: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type GetGameCodeQueryVariables = {
  GameCode?: string,
};

export type GetGameCodeQuery = {
  getGameCode?:  {
    __typename: "GameCode",
    id: string,
    GameCode: string,
    AdminGameCode: string,
    MaxPlayers: number,
    GameType: string,
    Reseller: string,
    DaysValid: number,
    DateActivated?: string | null,
    DateExpired?: number | null,
    CollectionPlayed?: Array< string | null > | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListGameCodesQueryVariables = {
  GameCode?: string | null,
  filter?: ModelGameCodeFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListGameCodesQuery = {
  listGameCodes?:  {
    __typename: "ModelGameCodeConnection",
    items?:  Array< {
      __typename: "GameCode",
      id: string,
      GameCode: string,
      AdminGameCode: string,
      MaxPlayers: number,
      GameType: string,
      Reseller: string,
      DaysValid: number,
      DateActivated?: string | null,
      DateExpired?: number | null,
      CollectionPlayed?: Array< string | null > | null,
      createdAt: string,
      updatedAt: string,
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type GetTriviaQueryVariables = {
  id?: string,
};

export type GetTriviaQuery = {
  getTrivia?:  {
    __typename: "Trivia",
    id: string,
    GameCode: string,
    DateStarted?: string | null,
    DateCompleted?: string | null,
    HasPowerUps?: boolean | null,
    CurrentRoundId?: number | null,
    CurrentQuestionId?: number | null,
    TriviaRound?:  {
      __typename: "ModelTriviaRoundConnection",
      items?:  Array< {
        __typename: "TriviaRound",
        id: string,
        Trivia_Id: string,
        DateStarted?: string | null,
        DateCompleted?: string | null,
        Collection_Id?: string | null,
        CurrentQuestionId?: number | null,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      nextToken?: string | null,
    } | null,
    TriviaPlayers?:  {
      __typename: "ModelTriviaPlayerConnection",
      items?:  Array< {
        __typename: "TriviaPlayer",
        id: string,
        Trivia_Id?: string | null,
        GameCode: string,
        PlayerName: string,
        PusherId?: string | null,
        isReady: boolean,
        isAdmin: boolean,
        isSuperAdmin?: boolean | null,
        isAdminActive?: boolean | null,
        Avatar?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListTriviasQueryVariables = {
  filter?: ModelTriviaFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListTriviasQuery = {
  listTrivias?:  {
    __typename: "ModelTriviaConnection",
    items?:  Array< {
      __typename: "Trivia",
      id: string,
      GameCode: string,
      DateStarted?: string | null,
      DateCompleted?: string | null,
      HasPowerUps?: boolean | null,
      CurrentRoundId?: number | null,
      CurrentQuestionId?: number | null,
      TriviaRound?:  {
        __typename: "ModelTriviaRoundConnection",
        nextToken?: string | null,
      } | null,
      TriviaPlayers?:  {
        __typename: "ModelTriviaPlayerConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type GetTriviaRoundQueryVariables = {
  id?: string,
};

export type GetTriviaRoundQuery = {
  getTriviaRound?:  {
    __typename: "TriviaRound",
    id: string,
    Trivia_Id: string,
    Trivia?:  {
      __typename: "Trivia",
      id: string,
      GameCode: string,
      DateStarted?: string | null,
      DateCompleted?: string | null,
      HasPowerUps?: boolean | null,
      CurrentRoundId?: number | null,
      CurrentQuestionId?: number | null,
      TriviaRound?:  {
        __typename: "ModelTriviaRoundConnection",
        nextToken?: string | null,
      } | null,
      TriviaPlayers?:  {
        __typename: "ModelTriviaPlayerConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    DateStarted?: string | null,
    DateCompleted?: string | null,
    Collection_Id?: string | null,
    Collection?:  {
      __typename: "Collection",
      id: string,
      Category_Id: string,
      Category?:  {
        __typename: "Category",
        id: string,
        Image: string,
        Type?: string | null,
        Name: string,
        Color: string,
        TotalCollection: number,
        isActive?: boolean | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      Image: string,
      Name: string,
      Description: string,
      GameRules: string,
      CollectionType: string,
      Time: number,
      TotalQuestion: number,
      TimesPlayed: number,
      TotalTime: number,
      Difficulty: number,
      GameCode: string,
      owner?: string | null,
      isPublished: boolean,
      TriviaRound?:  {
        __typename: "ModelTriviaRoundConnection",
        nextToken?: string | null,
      } | null,
      isActive?: boolean | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    CurrentQuestionId?: number | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListTriviaRoundsQueryVariables = {
  filter?: ModelTriviaRoundFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListTriviaRoundsQuery = {
  listTriviaRounds?:  {
    __typename: "ModelTriviaRoundConnection",
    items?:  Array< {
      __typename: "TriviaRound",
      id: string,
      Trivia_Id: string,
      Trivia?:  {
        __typename: "Trivia",
        id: string,
        GameCode: string,
        DateStarted?: string | null,
        DateCompleted?: string | null,
        HasPowerUps?: boolean | null,
        CurrentRoundId?: number | null,
        CurrentQuestionId?: number | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      DateStarted?: string | null,
      DateCompleted?: string | null,
      Collection_Id?: string | null,
      Collection?:  {
        __typename: "Collection",
        id: string,
        Category_Id: string,
        Image: string,
        Name: string,
        Description: string,
        GameRules: string,
        CollectionType: string,
        Time: number,
        TotalQuestion: number,
        TimesPlayed: number,
        TotalTime: number,
        Difficulty: number,
        GameCode: string,
        owner?: string | null,
        isPublished: boolean,
        isActive?: boolean | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      CurrentQuestionId?: number | null,
      createdAt: string,
      updatedAt: string,
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type GetTriviaPlayerQueryVariables = {
  id?: string,
};

export type GetTriviaPlayerQuery = {
  getTriviaPlayer?:  {
    __typename: "TriviaPlayer",
    id: string,
    Trivia_Id?: string | null,
    GameCode: string,
    Trivia?:  {
      __typename: "Trivia",
      id: string,
      GameCode: string,
      DateStarted?: string | null,
      DateCompleted?: string | null,
      HasPowerUps?: boolean | null,
      CurrentRoundId?: number | null,
      CurrentQuestionId?: number | null,
      TriviaRound?:  {
        __typename: "ModelTriviaRoundConnection",
        nextToken?: string | null,
      } | null,
      TriviaPlayers?:  {
        __typename: "ModelTriviaPlayerConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    PlayerName: string,
    PusherId?: string | null,
    isReady: boolean,
    isAdmin: boolean,
    isSuperAdmin?: boolean | null,
    isAdminActive?: boolean | null,
    PowerUps?:  Array< {
      __typename: "PlayerPowerUps",
      id: string,
      PowerUp: PowerUp,
      isUsed: boolean,
    } | null > | null,
    Avatar?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListTriviaPlayersQueryVariables = {
  filter?: ModelTriviaPlayerFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListTriviaPlayersQuery = {
  listTriviaPlayers?:  {
    __typename: "ModelTriviaPlayerConnection",
    items?:  Array< {
      __typename: "TriviaPlayer",
      id: string,
      Trivia_Id?: string | null,
      GameCode: string,
      Trivia?:  {
        __typename: "Trivia",
        id: string,
        GameCode: string,
        DateStarted?: string | null,
        DateCompleted?: string | null,
        HasPowerUps?: boolean | null,
        CurrentRoundId?: number | null,
        CurrentQuestionId?: number | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      PlayerName: string,
      PusherId?: string | null,
      isReady: boolean,
      isAdmin: boolean,
      isSuperAdmin?: boolean | null,
      isAdminActive?: boolean | null,
      PowerUps?:  Array< {
        __typename: "PlayerPowerUps",
        id: string,
        PowerUp: PowerUp,
        isUsed: boolean,
      } | null > | null,
      Avatar?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type GetTriviaPlayerAnswerQueryVariables = {
  id?: string,
};

export type GetTriviaPlayerAnswerQuery = {
  getTriviaPlayerAnswer?:  {
    __typename: "TriviaPlayerAnswer",
    id: string,
    Trivia_Id: string,
    QuestionId: string,
    CollectionId: string,
    Answers?: Array< string | null > | null,
    PlayerId: string,
    PlayerName?: string | null,
    IsAnswerCorrect?: boolean | null,
    Points?: number | null,
    PowerUp?: Array< PowerUp | null > | null,
    ReceivedTime?: string | null,
    ReactionTime?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListTriviaPlayerAnswersQueryVariables = {
  filter?: ModelTriviaPlayerAnswerFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListTriviaPlayerAnswersQuery = {
  listTriviaPlayerAnswers?:  {
    __typename: "ModelTriviaPlayerAnswerConnection",
    items?:  Array< {
      __typename: "TriviaPlayerAnswer",
      id: string,
      Trivia_Id: string,
      QuestionId: string,
      CollectionId: string,
      Answers?: Array< string | null > | null,
      PlayerId: string,
      PlayerName?: string | null,
      IsAnswerCorrect?: boolean | null,
      Points?: number | null,
      PowerUp?: Array< PowerUp | null > | null,
      ReceivedTime?: string | null,
      ReactionTime?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type GetTriviaPlayerRankingQueryVariables = {
  id?: string,
};

export type GetTriviaPlayerRankingQuery = {
  getTriviaPlayerRanking?:  {
    __typename: "TriviaPlayerRanking",
    id: string,
    Trivia_Id: string,
    GameCode: string,
    CorrectAnswersCount: string,
    IncorrectAnswersCount: string,
    Points?: number | null,
    PlayerId: string,
    CurrentRank?: number | null,
    PrevRank?: number | null,
    Player?:  {
      __typename: "TriviaPlayer",
      id: string,
      Trivia_Id?: string | null,
      GameCode: string,
      Trivia?:  {
        __typename: "Trivia",
        id: string,
        GameCode: string,
        DateStarted?: string | null,
        DateCompleted?: string | null,
        HasPowerUps?: boolean | null,
        CurrentRoundId?: number | null,
        CurrentQuestionId?: number | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      PlayerName: string,
      PusherId?: string | null,
      isReady: boolean,
      isAdmin: boolean,
      isSuperAdmin?: boolean | null,
      isAdminActive?: boolean | null,
      PowerUps?:  Array< {
        __typename: "PlayerPowerUps",
        id: string,
        PowerUp: PowerUp,
        isUsed: boolean,
      } | null > | null,
      Avatar?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListTriviaPlayerRankingsQueryVariables = {
  filter?: ModelTriviaPlayerRankingFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListTriviaPlayerRankingsQuery = {
  listTriviaPlayerRankings?:  {
    __typename: "ModelTriviaPlayerRankingConnection",
    items?:  Array< {
      __typename: "TriviaPlayerRanking",
      id: string,
      Trivia_Id: string,
      GameCode: string,
      CorrectAnswersCount: string,
      IncorrectAnswersCount: string,
      Points?: number | null,
      PlayerId: string,
      CurrentRank?: number | null,
      PrevRank?: number | null,
      Player?:  {
        __typename: "TriviaPlayer",
        id: string,
        Trivia_Id?: string | null,
        GameCode: string,
        PlayerName: string,
        PusherId?: string | null,
        isReady: boolean,
        isAdmin: boolean,
        isSuperAdmin?: boolean | null,
        isAdminActive?: boolean | null,
        Avatar?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type GetTriviaDetailsQueryVariables = {
  id?: string,
};

export type GetTriviaDetailsQuery = {
  getTriviaDetails?:  {
    __typename: "TriviaDetails",
    id: string,
    TotalCategory: number,
    TotalCollection: number,
    TotalQuestion: number,
    TotalCorrectAnswer: number,
    TotalAnswer: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListTriviaDetailssQueryVariables = {
  filter?: ModelTriviaDetailsFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListTriviaDetailssQuery = {
  listTriviaDetailss?:  {
    __typename: "ModelTriviaDetailsConnection",
    items?:  Array< {
      __typename: "TriviaDetails",
      id: string,
      TotalCategory: number,
      TotalCollection: number,
      TotalQuestion: number,
      TotalCorrectAnswer: number,
      TotalAnswer: number,
      createdAt: string,
      updatedAt: string,
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type GetCategoryQueryVariables = {
  id?: string,
};

export type GetCategoryQuery = {
  getCategory?:  {
    __typename: "Category",
    id: string,
    Image: string,
    Type?: string | null,
    Name: string,
    Color: string,
    TotalCollection: number,
    Collection?:  {
      __typename: "ModelCollectionConnection",
      items?:  Array< {
        __typename: "Collection",
        id: string,
        Category_Id: string,
        Image: string,
        Name: string,
        Description: string,
        GameRules: string,
        CollectionType: string,
        Time: number,
        TotalQuestion: number,
        TimesPlayed: number,
        TotalTime: number,
        Difficulty: number,
        GameCode: string,
        owner?: string | null,
        isPublished: boolean,
        isActive?: boolean | null,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      nextToken?: string | null,
    } | null,
    isActive?: boolean | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListCategorysQueryVariables = {
  filter?: ModelCategoryFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListCategorysQuery = {
  listCategorys?:  {
    __typename: "ModelCategoryConnection",
    items?:  Array< {
      __typename: "Category",
      id: string,
      Image: string,
      Type?: string | null,
      Name: string,
      Color: string,
      TotalCollection: number,
      Collection?:  {
        __typename: "ModelCollectionConnection",
        nextToken?: string | null,
      } | null,
      isActive?: boolean | null,
      createdAt: string,
      updatedAt: string,
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type GetCollectionQueryVariables = {
  id?: string,
};

export type GetCollectionQuery = {
  getCollection?:  {
    __typename: "Collection",
    id: string,
    Category_Id: string,
    Category?:  {
      __typename: "Category",
      id: string,
      Image: string,
      Type?: string | null,
      Name: string,
      Color: string,
      TotalCollection: number,
      Collection?:  {
        __typename: "ModelCollectionConnection",
        nextToken?: string | null,
      } | null,
      isActive?: boolean | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    Image: string,
    Name: string,
    Description: string,
    GameRules: string,
    CollectionType: string,
    Time: number,
    TotalQuestion: number,
    TimesPlayed: number,
    TotalTime: number,
    Difficulty: number,
    GameCode: string,
    owner?: string | null,
    isPublished: boolean,
    TriviaRound?:  {
      __typename: "ModelTriviaRoundConnection",
      items?:  Array< {
        __typename: "TriviaRound",
        id: string,
        Trivia_Id: string,
        DateStarted?: string | null,
        DateCompleted?: string | null,
        Collection_Id?: string | null,
        CurrentQuestionId?: number | null,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      nextToken?: string | null,
    } | null,
    isActive?: boolean | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListCollectionsQueryVariables = {
  filter?: ModelCollectionFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListCollectionsQuery = {
  listCollections?:  {
    __typename: "ModelCollectionConnection",
    items?:  Array< {
      __typename: "Collection",
      id: string,
      Category_Id: string,
      Category?:  {
        __typename: "Category",
        id: string,
        Image: string,
        Type?: string | null,
        Name: string,
        Color: string,
        TotalCollection: number,
        isActive?: boolean | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      Image: string,
      Name: string,
      Description: string,
      GameRules: string,
      CollectionType: string,
      Time: number,
      TotalQuestion: number,
      TimesPlayed: number,
      TotalTime: number,
      Difficulty: number,
      GameCode: string,
      owner?: string | null,
      isPublished: boolean,
      TriviaRound?:  {
        __typename: "ModelTriviaRoundConnection",
        nextToken?: string | null,
      } | null,
      isActive?: boolean | null,
      createdAt: string,
      updatedAt: string,
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type GetQuestionQueryVariables = {
  id?: string,
};

export type GetQuestionQuery = {
  getQuestion?:  {
    __typename: "Question",
    id: string,
    Collection_Id: string,
    Description: string,
    Rank?: number | null,
    Type?: string | null,
    Media: string,
    Hint?: Array< string | null > | null,
    Answers?:  Array< {
      __typename: "AnswerOptions",
      id: string,
      Answer: Array< string | null >,
      Correct: boolean,
      CaseSensitive: boolean,
    } | null > | null,
    isActive?: boolean | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListQuestionsQueryVariables = {
  filter?: ModelQuestionFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListQuestionsQuery = {
  listQuestions?:  {
    __typename: "ModelQuestionConnection",
    items?:  Array< {
      __typename: "Question",
      id: string,
      Collection_Id: string,
      Description: string,
      Rank?: number | null,
      Type?: string | null,
      Media: string,
      Hint?: Array< string | null > | null,
      Answers?:  Array< {
        __typename: "AnswerOptions",
        id: string,
        Answer: Array< string | null >,
        Correct: boolean,
        CaseSensitive: boolean,
      } | null > | null,
      isActive?: boolean | null,
      createdAt: string,
      updatedAt: string,
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type GetTriviaSettingQueryVariables = {
  id?: string,
};

export type GetTriviaSettingQuery = {
  getTriviaSetting?:  {
    __typename: "TriviaSetting",
    id: string,
    CountDownTime: number,
    TriviaMakerPassword: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListTriviaSettingsQueryVariables = {
  filter?: ModelTriviaSettingFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListTriviaSettingsQuery = {
  listTriviaSettings?:  {
    __typename: "ModelTriviaSettingConnection",
    items?:  Array< {
      __typename: "TriviaSetting",
      id: string,
      CountDownTime: number,
      TriviaMakerPassword: string,
      createdAt: string,
      updatedAt: string,
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type GetTriviaErrorQueryVariables = {
  id?: string,
};

export type GetTriviaErrorQuery = {
  getTriviaError?:  {
    __typename: "TriviaError",
    id: string,
    Status: number,
    Message: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListTriviaErrorsQueryVariables = {
  filter?: ModelTriviaErrorFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListTriviaErrorsQuery = {
  listTriviaErrors?:  {
    __typename: "ModelTriviaErrorConnection",
    items?:  Array< {
      __typename: "TriviaError",
      id: string,
      Status: number,
      Message: string,
      createdAt: string,
      updatedAt: string,
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type TriviaByGameCodeQueryVariables = {
  GameCode?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelTriviaFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type TriviaByGameCodeQuery = {
  triviaByGameCode?:  {
    __typename: "ModelTriviaConnection",
    items?:  Array< {
      __typename: "Trivia",
      id: string,
      GameCode: string,
      DateStarted?: string | null,
      DateCompleted?: string | null,
      HasPowerUps?: boolean | null,
      CurrentRoundId?: number | null,
      CurrentQuestionId?: number | null,
      TriviaRound?:  {
        __typename: "ModelTriviaRoundConnection",
        nextToken?: string | null,
      } | null,
      TriviaPlayers?:  {
        __typename: "ModelTriviaPlayerConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type TriviaRoundsByTriviaIdQueryVariables = {
  Trivia_Id?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelTriviaRoundFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type TriviaRoundsByTriviaIdQuery = {
  triviaRoundsByTriviaId?:  {
    __typename: "ModelTriviaRoundConnection",
    items?:  Array< {
      __typename: "TriviaRound",
      id: string,
      Trivia_Id: string,
      Trivia?:  {
        __typename: "Trivia",
        id: string,
        GameCode: string,
        DateStarted?: string | null,
        DateCompleted?: string | null,
        HasPowerUps?: boolean | null,
        CurrentRoundId?: number | null,
        CurrentQuestionId?: number | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      DateStarted?: string | null,
      DateCompleted?: string | null,
      Collection_Id?: string | null,
      Collection?:  {
        __typename: "Collection",
        id: string,
        Category_Id: string,
        Image: string,
        Name: string,
        Description: string,
        GameRules: string,
        CollectionType: string,
        Time: number,
        TotalQuestion: number,
        TimesPlayed: number,
        TotalTime: number,
        Difficulty: number,
        GameCode: string,
        owner?: string | null,
        isPublished: boolean,
        isActive?: boolean | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      CurrentQuestionId?: number | null,
      createdAt: string,
      updatedAt: string,
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type TriviaPlayerByTriviaIdQueryVariables = {
  Trivia_Id?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelTriviaPlayerFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type TriviaPlayerByTriviaIdQuery = {
  triviaPlayerByTriviaId?:  {
    __typename: "ModelTriviaPlayerConnection",
    items?:  Array< {
      __typename: "TriviaPlayer",
      id: string,
      Trivia_Id?: string | null,
      GameCode: string,
      Trivia?:  {
        __typename: "Trivia",
        id: string,
        GameCode: string,
        DateStarted?: string | null,
        DateCompleted?: string | null,
        HasPowerUps?: boolean | null,
        CurrentRoundId?: number | null,
        CurrentQuestionId?: number | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      PlayerName: string,
      PusherId?: string | null,
      isReady: boolean,
      isAdmin: boolean,
      isSuperAdmin?: boolean | null,
      isAdminActive?: boolean | null,
      PowerUps?:  Array< {
        __typename: "PlayerPowerUps",
        id: string,
        PowerUp: PowerUp,
        isUsed: boolean,
      } | null > | null,
      Avatar?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type TriviaPlayerByGameCodeQueryVariables = {
  GameCode?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelTriviaPlayerFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type TriviaPlayerByGameCodeQuery = {
  triviaPlayerByGameCode?:  {
    __typename: "ModelTriviaPlayerConnection",
    items?:  Array< {
      __typename: "TriviaPlayer",
      id: string,
      Trivia_Id?: string | null,
      GameCode: string,
      Trivia?:  {
        __typename: "Trivia",
        id: string,
        GameCode: string,
        DateStarted?: string | null,
        DateCompleted?: string | null,
        HasPowerUps?: boolean | null,
        CurrentRoundId?: number | null,
        CurrentQuestionId?: number | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      PlayerName: string,
      PusherId?: string | null,
      isReady: boolean,
      isAdmin: boolean,
      isSuperAdmin?: boolean | null,
      isAdminActive?: boolean | null,
      PowerUps?:  Array< {
        __typename: "PlayerPowerUps",
        id: string,
        PowerUp: PowerUp,
        isUsed: boolean,
      } | null > | null,
      Avatar?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type PlayerAnswersByTriviaAndCollectionIdQueryVariables = {
  Trivia_Id?: string | null,
  CollectionId?: ModelIDKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelTriviaPlayerAnswerFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type PlayerAnswersByTriviaAndCollectionIdQuery = {
  playerAnswersByTriviaAndCollectionId?:  {
    __typename: "ModelTriviaPlayerAnswerConnection",
    items?:  Array< {
      __typename: "TriviaPlayerAnswer",
      id: string,
      Trivia_Id: string,
      QuestionId: string,
      CollectionId: string,
      Answers?: Array< string | null > | null,
      PlayerId: string,
      PlayerName?: string | null,
      IsAnswerCorrect?: boolean | null,
      Points?: number | null,
      PowerUp?: Array< PowerUp | null > | null,
      ReceivedTime?: string | null,
      ReactionTime?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type PlayerRankingsByTriviaIdQueryVariables = {
  Trivia_Id?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelTriviaPlayerRankingFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type PlayerRankingsByTriviaIdQuery = {
  playerRankingsByTriviaId?:  {
    __typename: "ModelTriviaPlayerRankingConnection",
    items?:  Array< {
      __typename: "TriviaPlayerRanking",
      id: string,
      Trivia_Id: string,
      GameCode: string,
      CorrectAnswersCount: string,
      IncorrectAnswersCount: string,
      Points?: number | null,
      PlayerId: string,
      CurrentRank?: number | null,
      PrevRank?: number | null,
      Player?:  {
        __typename: "TriviaPlayer",
        id: string,
        Trivia_Id?: string | null,
        GameCode: string,
        PlayerName: string,
        PusherId?: string | null,
        isReady: boolean,
        isAdmin: boolean,
        isSuperAdmin?: boolean | null,
        isAdminActive?: boolean | null,
        Avatar?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type PlayerRankingsByGameCodeQueryVariables = {
  GameCode?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelTriviaPlayerRankingFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type PlayerRankingsByGameCodeQuery = {
  playerRankingsByGameCode?:  {
    __typename: "ModelTriviaPlayerRankingConnection",
    items?:  Array< {
      __typename: "TriviaPlayerRanking",
      id: string,
      Trivia_Id: string,
      GameCode: string,
      CorrectAnswersCount: string,
      IncorrectAnswersCount: string,
      Points?: number | null,
      PlayerId: string,
      CurrentRank?: number | null,
      PrevRank?: number | null,
      Player?:  {
        __typename: "TriviaPlayer",
        id: string,
        Trivia_Id?: string | null,
        GameCode: string,
        PlayerName: string,
        PusherId?: string | null,
        isReady: boolean,
        isAdmin: boolean,
        isSuperAdmin?: boolean | null,
        isAdminActive?: boolean | null,
        Avatar?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type CollectionByCategoryIdQueryVariables = {
  Category_Id?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelCollectionFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type CollectionByCategoryIdQuery = {
  collectionByCategoryId?:  {
    __typename: "ModelCollectionConnection",
    items?:  Array< {
      __typename: "Collection",
      id: string,
      Category_Id: string,
      Category?:  {
        __typename: "Category",
        id: string,
        Image: string,
        Type?: string | null,
        Name: string,
        Color: string,
        TotalCollection: number,
        isActive?: boolean | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      Image: string,
      Name: string,
      Description: string,
      GameRules: string,
      CollectionType: string,
      Time: number,
      TotalQuestion: number,
      TimesPlayed: number,
      TotalTime: number,
      Difficulty: number,
      GameCode: string,
      owner?: string | null,
      isPublished: boolean,
      TriviaRound?:  {
        __typename: "ModelTriviaRoundConnection",
        nextToken?: string | null,
      } | null,
      isActive?: boolean | null,
      createdAt: string,
      updatedAt: string,
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type CollectionByGameCodeQueryVariables = {
  GameCode?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelCollectionFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type CollectionByGameCodeQuery = {
  collectionByGameCode?:  {
    __typename: "ModelCollectionConnection",
    items?:  Array< {
      __typename: "Collection",
      id: string,
      Category_Id: string,
      Category?:  {
        __typename: "Category",
        id: string,
        Image: string,
        Type?: string | null,
        Name: string,
        Color: string,
        TotalCollection: number,
        isActive?: boolean | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      Image: string,
      Name: string,
      Description: string,
      GameRules: string,
      CollectionType: string,
      Time: number,
      TotalQuestion: number,
      TimesPlayed: number,
      TotalTime: number,
      Difficulty: number,
      GameCode: string,
      owner?: string | null,
      isPublished: boolean,
      TriviaRound?:  {
        __typename: "ModelTriviaRoundConnection",
        nextToken?: string | null,
      } | null,
      isActive?: boolean | null,
      createdAt: string,
      updatedAt: string,
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type QuestionsByCollectionIdQueryVariables = {
  Collection_Id?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelQuestionFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type QuestionsByCollectionIdQuery = {
  questionsByCollectionId?:  {
    __typename: "ModelQuestionConnection",
    items?:  Array< {
      __typename: "Question",
      id: string,
      Collection_Id: string,
      Description: string,
      Rank?: number | null,
      Type?: string | null,
      Media: string,
      Hint?: Array< string | null > | null,
      Answers?:  Array< {
        __typename: "AnswerOptions",
        id: string,
        Answer: Array< string | null >,
        Correct: boolean,
        CaseSensitive: boolean,
      } | null > | null,
      isActive?: boolean | null,
      createdAt: string,
      updatedAt: string,
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type OnIncrementTriviaDetailsSubscription = {
  onIncrementTriviaDetails?:  {
    __typename: "TriviaDetails",
    id: string,
    TotalCategory: number,
    TotalCollection: number,
    TotalQuestion: number,
    TotalCorrectAnswer: number,
    TotalAnswer: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnIncrementCategoryCollectionsSubscription = {
  onIncrementCategoryCollections?:  {
    __typename: "Category",
    id: string,
    Image: string,
    Type?: string | null,
    Name: string,
    Color: string,
    TotalCollection: number,
    Collection?:  {
      __typename: "ModelCollectionConnection",
      items?:  Array< {
        __typename: "Collection",
        id: string,
        Category_Id: string,
        Image: string,
        Name: string,
        Description: string,
        GameRules: string,
        CollectionType: string,
        Time: number,
        TotalQuestion: number,
        TimesPlayed: number,
        TotalTime: number,
        Difficulty: number,
        GameCode: string,
        owner?: string | null,
        isPublished: boolean,
        isActive?: boolean | null,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      nextToken?: string | null,
    } | null,
    isActive?: boolean | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnIncrementCollectionQuestionsSubscription = {
  onIncrementCollectionQuestions?:  {
    __typename: "Collection",
    id: string,
    Category_Id: string,
    Category?:  {
      __typename: "Category",
      id: string,
      Image: string,
      Type?: string | null,
      Name: string,
      Color: string,
      TotalCollection: number,
      Collection?:  {
        __typename: "ModelCollectionConnection",
        nextToken?: string | null,
      } | null,
      isActive?: boolean | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    Image: string,
    Name: string,
    Description: string,
    GameRules: string,
    CollectionType: string,
    Time: number,
    TotalQuestion: number,
    TimesPlayed: number,
    TotalTime: number,
    Difficulty: number,
    GameCode: string,
    owner?: string | null,
    isPublished: boolean,
    TriviaRound?:  {
      __typename: "ModelTriviaRoundConnection",
      items?:  Array< {
        __typename: "TriviaRound",
        id: string,
        Trivia_Id: string,
        DateStarted?: string | null,
        DateCompleted?: string | null,
        Collection_Id?: string | null,
        CurrentQuestionId?: number | null,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      nextToken?: string | null,
    } | null,
    isActive?: boolean | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreatedTriviaPlayerSubscriptionVariables = {
  GameCode?: string | null,
};

export type OnCreatedTriviaPlayerSubscription = {
  onCreatedTriviaPlayer?:  {
    __typename: "TriviaPlayer",
    id: string,
    Trivia_Id?: string | null,
    GameCode: string,
    Trivia?:  {
      __typename: "Trivia",
      id: string,
      GameCode: string,
      DateStarted?: string | null,
      DateCompleted?: string | null,
      HasPowerUps?: boolean | null,
      CurrentRoundId?: number | null,
      CurrentQuestionId?: number | null,
      TriviaRound?:  {
        __typename: "ModelTriviaRoundConnection",
        nextToken?: string | null,
      } | null,
      TriviaPlayers?:  {
        __typename: "ModelTriviaPlayerConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    PlayerName: string,
    PusherId?: string | null,
    isReady: boolean,
    isAdmin: boolean,
    isSuperAdmin?: boolean | null,
    isAdminActive?: boolean | null,
    PowerUps?:  Array< {
      __typename: "PlayerPowerUps",
      id: string,
      PowerUp: PowerUp,
      isUsed: boolean,
    } | null > | null,
    Avatar?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdatedTriviaPlayerSubscriptionVariables = {
  GameCode?: string | null,
};

export type OnUpdatedTriviaPlayerSubscription = {
  onUpdatedTriviaPlayer?:  {
    __typename: "TriviaPlayer",
    id: string,
    Trivia_Id?: string | null,
    GameCode: string,
    Trivia?:  {
      __typename: "Trivia",
      id: string,
      GameCode: string,
      DateStarted?: string | null,
      DateCompleted?: string | null,
      HasPowerUps?: boolean | null,
      CurrentRoundId?: number | null,
      CurrentQuestionId?: number | null,
      TriviaRound?:  {
        __typename: "ModelTriviaRoundConnection",
        nextToken?: string | null,
      } | null,
      TriviaPlayers?:  {
        __typename: "ModelTriviaPlayerConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    PlayerName: string,
    PusherId?: string | null,
    isReady: boolean,
    isAdmin: boolean,
    isSuperAdmin?: boolean | null,
    isAdminActive?: boolean | null,
    PowerUps?:  Array< {
      __typename: "PlayerPowerUps",
      id: string,
      PowerUp: PowerUp,
      isUsed: boolean,
    } | null > | null,
    Avatar?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeletedTriviaPlayerSubscriptionVariables = {
  GameCode?: string | null,
};

export type OnDeletedTriviaPlayerSubscription = {
  onDeletedTriviaPlayer?:  {
    __typename: "TriviaPlayer",
    id: string,
    Trivia_Id?: string | null,
    GameCode: string,
    Trivia?:  {
      __typename: "Trivia",
      id: string,
      GameCode: string,
      DateStarted?: string | null,
      DateCompleted?: string | null,
      HasPowerUps?: boolean | null,
      CurrentRoundId?: number | null,
      CurrentQuestionId?: number | null,
      TriviaRound?:  {
        __typename: "ModelTriviaRoundConnection",
        nextToken?: string | null,
      } | null,
      TriviaPlayers?:  {
        __typename: "ModelTriviaPlayerConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    PlayerName: string,
    PusherId?: string | null,
    isReady: boolean,
    isAdmin: boolean,
    isSuperAdmin?: boolean | null,
    isAdminActive?: boolean | null,
    PowerUps?:  Array< {
      __typename: "PlayerPowerUps",
      id: string,
      PowerUp: PowerUp,
      isUsed: boolean,
    } | null > | null,
    Avatar?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreatedCollectionSubscriptionVariables = {
  GameCode?: string | null,
};

export type OnCreatedCollectionSubscription = {
  onCreatedCollection?:  {
    __typename: "Collection",
    id: string,
    Category_Id: string,
    Category?:  {
      __typename: "Category",
      id: string,
      Image: string,
      Type?: string | null,
      Name: string,
      Color: string,
      TotalCollection: number,
      Collection?:  {
        __typename: "ModelCollectionConnection",
        nextToken?: string | null,
      } | null,
      isActive?: boolean | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    Image: string,
    Name: string,
    Description: string,
    GameRules: string,
    CollectionType: string,
    Time: number,
    TotalQuestion: number,
    TimesPlayed: number,
    TotalTime: number,
    Difficulty: number,
    GameCode: string,
    owner?: string | null,
    isPublished: boolean,
    TriviaRound?:  {
      __typename: "ModelTriviaRoundConnection",
      items?:  Array< {
        __typename: "TriviaRound",
        id: string,
        Trivia_Id: string,
        DateStarted?: string | null,
        DateCompleted?: string | null,
        Collection_Id?: string | null,
        CurrentQuestionId?: number | null,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      nextToken?: string | null,
    } | null,
    isActive?: boolean | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdatedCollectionSubscriptionVariables = {
  GameCode?: string | null,
};

export type OnUpdatedCollectionSubscription = {
  onUpdatedCollection?:  {
    __typename: "Collection",
    id: string,
    Category_Id: string,
    Category?:  {
      __typename: "Category",
      id: string,
      Image: string,
      Type?: string | null,
      Name: string,
      Color: string,
      TotalCollection: number,
      Collection?:  {
        __typename: "ModelCollectionConnection",
        nextToken?: string | null,
      } | null,
      isActive?: boolean | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    Image: string,
    Name: string,
    Description: string,
    GameRules: string,
    CollectionType: string,
    Time: number,
    TotalQuestion: number,
    TimesPlayed: number,
    TotalTime: number,
    Difficulty: number,
    GameCode: string,
    owner?: string | null,
    isPublished: boolean,
    TriviaRound?:  {
      __typename: "ModelTriviaRoundConnection",
      items?:  Array< {
        __typename: "TriviaRound",
        id: string,
        Trivia_Id: string,
        DateStarted?: string | null,
        DateCompleted?: string | null,
        Collection_Id?: string | null,
        CurrentQuestionId?: number | null,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      nextToken?: string | null,
    } | null,
    isActive?: boolean | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeletedCollectionSubscriptionVariables = {
  GameCode?: string | null,
};

export type OnDeletedCollectionSubscription = {
  onDeletedCollection?:  {
    __typename: "Collection",
    id: string,
    Category_Id: string,
    Category?:  {
      __typename: "Category",
      id: string,
      Image: string,
      Type?: string | null,
      Name: string,
      Color: string,
      TotalCollection: number,
      Collection?:  {
        __typename: "ModelCollectionConnection",
        nextToken?: string | null,
      } | null,
      isActive?: boolean | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    Image: string,
    Name: string,
    Description: string,
    GameRules: string,
    CollectionType: string,
    Time: number,
    TotalQuestion: number,
    TimesPlayed: number,
    TotalTime: number,
    Difficulty: number,
    GameCode: string,
    owner?: string | null,
    isPublished: boolean,
    TriviaRound?:  {
      __typename: "ModelTriviaRoundConnection",
      items?:  Array< {
        __typename: "TriviaRound",
        id: string,
        Trivia_Id: string,
        DateStarted?: string | null,
        DateCompleted?: string | null,
        Collection_Id?: string | null,
        CurrentQuestionId?: number | null,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      nextToken?: string | null,
    } | null,
    isActive?: boolean | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateGameCodeSubscription = {
  onCreateGameCode?:  {
    __typename: "GameCode",
    id: string,
    GameCode: string,
    AdminGameCode: string,
    MaxPlayers: number,
    GameType: string,
    Reseller: string,
    DaysValid: number,
    DateActivated?: string | null,
    DateExpired?: number | null,
    CollectionPlayed?: Array< string | null > | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateGameCodeSubscription = {
  onUpdateGameCode?:  {
    __typename: "GameCode",
    id: string,
    GameCode: string,
    AdminGameCode: string,
    MaxPlayers: number,
    GameType: string,
    Reseller: string,
    DaysValid: number,
    DateActivated?: string | null,
    DateExpired?: number | null,
    CollectionPlayed?: Array< string | null > | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteGameCodeSubscription = {
  onDeleteGameCode?:  {
    __typename: "GameCode",
    id: string,
    GameCode: string,
    AdminGameCode: string,
    MaxPlayers: number,
    GameType: string,
    Reseller: string,
    DaysValid: number,
    DateActivated?: string | null,
    DateExpired?: number | null,
    CollectionPlayed?: Array< string | null > | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateTriviaSubscription = {
  onCreateTrivia?:  {
    __typename: "Trivia",
    id: string,
    GameCode: string,
    DateStarted?: string | null,
    DateCompleted?: string | null,
    HasPowerUps?: boolean | null,
    CurrentRoundId?: number | null,
    CurrentQuestionId?: number | null,
    TriviaRound?:  {
      __typename: "ModelTriviaRoundConnection",
      items?:  Array< {
        __typename: "TriviaRound",
        id: string,
        Trivia_Id: string,
        DateStarted?: string | null,
        DateCompleted?: string | null,
        Collection_Id?: string | null,
        CurrentQuestionId?: number | null,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      nextToken?: string | null,
    } | null,
    TriviaPlayers?:  {
      __typename: "ModelTriviaPlayerConnection",
      items?:  Array< {
        __typename: "TriviaPlayer",
        id: string,
        Trivia_Id?: string | null,
        GameCode: string,
        PlayerName: string,
        PusherId?: string | null,
        isReady: boolean,
        isAdmin: boolean,
        isSuperAdmin?: boolean | null,
        isAdminActive?: boolean | null,
        Avatar?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateTriviaSubscription = {
  onUpdateTrivia?:  {
    __typename: "Trivia",
    id: string,
    GameCode: string,
    DateStarted?: string | null,
    DateCompleted?: string | null,
    HasPowerUps?: boolean | null,
    CurrentRoundId?: number | null,
    CurrentQuestionId?: number | null,
    TriviaRound?:  {
      __typename: "ModelTriviaRoundConnection",
      items?:  Array< {
        __typename: "TriviaRound",
        id: string,
        Trivia_Id: string,
        DateStarted?: string | null,
        DateCompleted?: string | null,
        Collection_Id?: string | null,
        CurrentQuestionId?: number | null,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      nextToken?: string | null,
    } | null,
    TriviaPlayers?:  {
      __typename: "ModelTriviaPlayerConnection",
      items?:  Array< {
        __typename: "TriviaPlayer",
        id: string,
        Trivia_Id?: string | null,
        GameCode: string,
        PlayerName: string,
        PusherId?: string | null,
        isReady: boolean,
        isAdmin: boolean,
        isSuperAdmin?: boolean | null,
        isAdminActive?: boolean | null,
        Avatar?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteTriviaSubscription = {
  onDeleteTrivia?:  {
    __typename: "Trivia",
    id: string,
    GameCode: string,
    DateStarted?: string | null,
    DateCompleted?: string | null,
    HasPowerUps?: boolean | null,
    CurrentRoundId?: number | null,
    CurrentQuestionId?: number | null,
    TriviaRound?:  {
      __typename: "ModelTriviaRoundConnection",
      items?:  Array< {
        __typename: "TriviaRound",
        id: string,
        Trivia_Id: string,
        DateStarted?: string | null,
        DateCompleted?: string | null,
        Collection_Id?: string | null,
        CurrentQuestionId?: number | null,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      nextToken?: string | null,
    } | null,
    TriviaPlayers?:  {
      __typename: "ModelTriviaPlayerConnection",
      items?:  Array< {
        __typename: "TriviaPlayer",
        id: string,
        Trivia_Id?: string | null,
        GameCode: string,
        PlayerName: string,
        PusherId?: string | null,
        isReady: boolean,
        isAdmin: boolean,
        isSuperAdmin?: boolean | null,
        isAdminActive?: boolean | null,
        Avatar?: string | null,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateTriviaRoundSubscription = {
  onCreateTriviaRound?:  {
    __typename: "TriviaRound",
    id: string,
    Trivia_Id: string,
    Trivia?:  {
      __typename: "Trivia",
      id: string,
      GameCode: string,
      DateStarted?: string | null,
      DateCompleted?: string | null,
      HasPowerUps?: boolean | null,
      CurrentRoundId?: number | null,
      CurrentQuestionId?: number | null,
      TriviaRound?:  {
        __typename: "ModelTriviaRoundConnection",
        nextToken?: string | null,
      } | null,
      TriviaPlayers?:  {
        __typename: "ModelTriviaPlayerConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    DateStarted?: string | null,
    DateCompleted?: string | null,
    Collection_Id?: string | null,
    Collection?:  {
      __typename: "Collection",
      id: string,
      Category_Id: string,
      Category?:  {
        __typename: "Category",
        id: string,
        Image: string,
        Type?: string | null,
        Name: string,
        Color: string,
        TotalCollection: number,
        isActive?: boolean | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      Image: string,
      Name: string,
      Description: string,
      GameRules: string,
      CollectionType: string,
      Time: number,
      TotalQuestion: number,
      TimesPlayed: number,
      TotalTime: number,
      Difficulty: number,
      GameCode: string,
      owner?: string | null,
      isPublished: boolean,
      TriviaRound?:  {
        __typename: "ModelTriviaRoundConnection",
        nextToken?: string | null,
      } | null,
      isActive?: boolean | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    CurrentQuestionId?: number | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateTriviaRoundSubscription = {
  onUpdateTriviaRound?:  {
    __typename: "TriviaRound",
    id: string,
    Trivia_Id: string,
    Trivia?:  {
      __typename: "Trivia",
      id: string,
      GameCode: string,
      DateStarted?: string | null,
      DateCompleted?: string | null,
      HasPowerUps?: boolean | null,
      CurrentRoundId?: number | null,
      CurrentQuestionId?: number | null,
      TriviaRound?:  {
        __typename: "ModelTriviaRoundConnection",
        nextToken?: string | null,
      } | null,
      TriviaPlayers?:  {
        __typename: "ModelTriviaPlayerConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    DateStarted?: string | null,
    DateCompleted?: string | null,
    Collection_Id?: string | null,
    Collection?:  {
      __typename: "Collection",
      id: string,
      Category_Id: string,
      Category?:  {
        __typename: "Category",
        id: string,
        Image: string,
        Type?: string | null,
        Name: string,
        Color: string,
        TotalCollection: number,
        isActive?: boolean | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      Image: string,
      Name: string,
      Description: string,
      GameRules: string,
      CollectionType: string,
      Time: number,
      TotalQuestion: number,
      TimesPlayed: number,
      TotalTime: number,
      Difficulty: number,
      GameCode: string,
      owner?: string | null,
      isPublished: boolean,
      TriviaRound?:  {
        __typename: "ModelTriviaRoundConnection",
        nextToken?: string | null,
      } | null,
      isActive?: boolean | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    CurrentQuestionId?: number | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteTriviaRoundSubscription = {
  onDeleteTriviaRound?:  {
    __typename: "TriviaRound",
    id: string,
    Trivia_Id: string,
    Trivia?:  {
      __typename: "Trivia",
      id: string,
      GameCode: string,
      DateStarted?: string | null,
      DateCompleted?: string | null,
      HasPowerUps?: boolean | null,
      CurrentRoundId?: number | null,
      CurrentQuestionId?: number | null,
      TriviaRound?:  {
        __typename: "ModelTriviaRoundConnection",
        nextToken?: string | null,
      } | null,
      TriviaPlayers?:  {
        __typename: "ModelTriviaPlayerConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    DateStarted?: string | null,
    DateCompleted?: string | null,
    Collection_Id?: string | null,
    Collection?:  {
      __typename: "Collection",
      id: string,
      Category_Id: string,
      Category?:  {
        __typename: "Category",
        id: string,
        Image: string,
        Type?: string | null,
        Name: string,
        Color: string,
        TotalCollection: number,
        isActive?: boolean | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      Image: string,
      Name: string,
      Description: string,
      GameRules: string,
      CollectionType: string,
      Time: number,
      TotalQuestion: number,
      TimesPlayed: number,
      TotalTime: number,
      Difficulty: number,
      GameCode: string,
      owner?: string | null,
      isPublished: boolean,
      TriviaRound?:  {
        __typename: "ModelTriviaRoundConnection",
        nextToken?: string | null,
      } | null,
      isActive?: boolean | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    CurrentQuestionId?: number | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateTriviaPlayerSubscription = {
  onCreateTriviaPlayer?:  {
    __typename: "TriviaPlayer",
    id: string,
    Trivia_Id?: string | null,
    GameCode: string,
    Trivia?:  {
      __typename: "Trivia",
      id: string,
      GameCode: string,
      DateStarted?: string | null,
      DateCompleted?: string | null,
      HasPowerUps?: boolean | null,
      CurrentRoundId?: number | null,
      CurrentQuestionId?: number | null,
      TriviaRound?:  {
        __typename: "ModelTriviaRoundConnection",
        nextToken?: string | null,
      } | null,
      TriviaPlayers?:  {
        __typename: "ModelTriviaPlayerConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    PlayerName: string,
    PusherId?: string | null,
    isReady: boolean,
    isAdmin: boolean,
    isSuperAdmin?: boolean | null,
    isAdminActive?: boolean | null,
    PowerUps?:  Array< {
      __typename: "PlayerPowerUps",
      id: string,
      PowerUp: PowerUp,
      isUsed: boolean,
    } | null > | null,
    Avatar?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateTriviaPlayerSubscription = {
  onUpdateTriviaPlayer?:  {
    __typename: "TriviaPlayer",
    id: string,
    Trivia_Id?: string | null,
    GameCode: string,
    Trivia?:  {
      __typename: "Trivia",
      id: string,
      GameCode: string,
      DateStarted?: string | null,
      DateCompleted?: string | null,
      HasPowerUps?: boolean | null,
      CurrentRoundId?: number | null,
      CurrentQuestionId?: number | null,
      TriviaRound?:  {
        __typename: "ModelTriviaRoundConnection",
        nextToken?: string | null,
      } | null,
      TriviaPlayers?:  {
        __typename: "ModelTriviaPlayerConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    PlayerName: string,
    PusherId?: string | null,
    isReady: boolean,
    isAdmin: boolean,
    isSuperAdmin?: boolean | null,
    isAdminActive?: boolean | null,
    PowerUps?:  Array< {
      __typename: "PlayerPowerUps",
      id: string,
      PowerUp: PowerUp,
      isUsed: boolean,
    } | null > | null,
    Avatar?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteTriviaPlayerSubscription = {
  onDeleteTriviaPlayer?:  {
    __typename: "TriviaPlayer",
    id: string,
    Trivia_Id?: string | null,
    GameCode: string,
    Trivia?:  {
      __typename: "Trivia",
      id: string,
      GameCode: string,
      DateStarted?: string | null,
      DateCompleted?: string | null,
      HasPowerUps?: boolean | null,
      CurrentRoundId?: number | null,
      CurrentQuestionId?: number | null,
      TriviaRound?:  {
        __typename: "ModelTriviaRoundConnection",
        nextToken?: string | null,
      } | null,
      TriviaPlayers?:  {
        __typename: "ModelTriviaPlayerConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    PlayerName: string,
    PusherId?: string | null,
    isReady: boolean,
    isAdmin: boolean,
    isSuperAdmin?: boolean | null,
    isAdminActive?: boolean | null,
    PowerUps?:  Array< {
      __typename: "PlayerPowerUps",
      id: string,
      PowerUp: PowerUp,
      isUsed: boolean,
    } | null > | null,
    Avatar?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateTriviaPlayerAnswerSubscription = {
  onCreateTriviaPlayerAnswer?:  {
    __typename: "TriviaPlayerAnswer",
    id: string,
    Trivia_Id: string,
    QuestionId: string,
    CollectionId: string,
    Answers?: Array< string | null > | null,
    PlayerId: string,
    PlayerName?: string | null,
    IsAnswerCorrect?: boolean | null,
    Points?: number | null,
    PowerUp?: Array< PowerUp | null > | null,
    ReceivedTime?: string | null,
    ReactionTime?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateTriviaPlayerAnswerSubscription = {
  onUpdateTriviaPlayerAnswer?:  {
    __typename: "TriviaPlayerAnswer",
    id: string,
    Trivia_Id: string,
    QuestionId: string,
    CollectionId: string,
    Answers?: Array< string | null > | null,
    PlayerId: string,
    PlayerName?: string | null,
    IsAnswerCorrect?: boolean | null,
    Points?: number | null,
    PowerUp?: Array< PowerUp | null > | null,
    ReceivedTime?: string | null,
    ReactionTime?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteTriviaPlayerAnswerSubscription = {
  onDeleteTriviaPlayerAnswer?:  {
    __typename: "TriviaPlayerAnswer",
    id: string,
    Trivia_Id: string,
    QuestionId: string,
    CollectionId: string,
    Answers?: Array< string | null > | null,
    PlayerId: string,
    PlayerName?: string | null,
    IsAnswerCorrect?: boolean | null,
    Points?: number | null,
    PowerUp?: Array< PowerUp | null > | null,
    ReceivedTime?: string | null,
    ReactionTime?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateTriviaPlayerRankingSubscription = {
  onCreateTriviaPlayerRanking?:  {
    __typename: "TriviaPlayerRanking",
    id: string,
    Trivia_Id: string,
    GameCode: string,
    CorrectAnswersCount: string,
    IncorrectAnswersCount: string,
    Points?: number | null,
    PlayerId: string,
    CurrentRank?: number | null,
    PrevRank?: number | null,
    Player?:  {
      __typename: "TriviaPlayer",
      id: string,
      Trivia_Id?: string | null,
      GameCode: string,
      Trivia?:  {
        __typename: "Trivia",
        id: string,
        GameCode: string,
        DateStarted?: string | null,
        DateCompleted?: string | null,
        HasPowerUps?: boolean | null,
        CurrentRoundId?: number | null,
        CurrentQuestionId?: number | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      PlayerName: string,
      PusherId?: string | null,
      isReady: boolean,
      isAdmin: boolean,
      isSuperAdmin?: boolean | null,
      isAdminActive?: boolean | null,
      PowerUps?:  Array< {
        __typename: "PlayerPowerUps",
        id: string,
        PowerUp: PowerUp,
        isUsed: boolean,
      } | null > | null,
      Avatar?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateTriviaPlayerRankingSubscription = {
  onUpdateTriviaPlayerRanking?:  {
    __typename: "TriviaPlayerRanking",
    id: string,
    Trivia_Id: string,
    GameCode: string,
    CorrectAnswersCount: string,
    IncorrectAnswersCount: string,
    Points?: number | null,
    PlayerId: string,
    CurrentRank?: number | null,
    PrevRank?: number | null,
    Player?:  {
      __typename: "TriviaPlayer",
      id: string,
      Trivia_Id?: string | null,
      GameCode: string,
      Trivia?:  {
        __typename: "Trivia",
        id: string,
        GameCode: string,
        DateStarted?: string | null,
        DateCompleted?: string | null,
        HasPowerUps?: boolean | null,
        CurrentRoundId?: number | null,
        CurrentQuestionId?: number | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      PlayerName: string,
      PusherId?: string | null,
      isReady: boolean,
      isAdmin: boolean,
      isSuperAdmin?: boolean | null,
      isAdminActive?: boolean | null,
      PowerUps?:  Array< {
        __typename: "PlayerPowerUps",
        id: string,
        PowerUp: PowerUp,
        isUsed: boolean,
      } | null > | null,
      Avatar?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteTriviaPlayerRankingSubscription = {
  onDeleteTriviaPlayerRanking?:  {
    __typename: "TriviaPlayerRanking",
    id: string,
    Trivia_Id: string,
    GameCode: string,
    CorrectAnswersCount: string,
    IncorrectAnswersCount: string,
    Points?: number | null,
    PlayerId: string,
    CurrentRank?: number | null,
    PrevRank?: number | null,
    Player?:  {
      __typename: "TriviaPlayer",
      id: string,
      Trivia_Id?: string | null,
      GameCode: string,
      Trivia?:  {
        __typename: "Trivia",
        id: string,
        GameCode: string,
        DateStarted?: string | null,
        DateCompleted?: string | null,
        HasPowerUps?: boolean | null,
        CurrentRoundId?: number | null,
        CurrentQuestionId?: number | null,
        createdAt: string,
        updatedAt: string,
      } | null,
      PlayerName: string,
      PusherId?: string | null,
      isReady: boolean,
      isAdmin: boolean,
      isSuperAdmin?: boolean | null,
      isAdminActive?: boolean | null,
      PowerUps?:  Array< {
        __typename: "PlayerPowerUps",
        id: string,
        PowerUp: PowerUp,
        isUsed: boolean,
      } | null > | null,
      Avatar?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateTriviaDetailsSubscription = {
  onCreateTriviaDetails?:  {
    __typename: "TriviaDetails",
    id: string,
    TotalCategory: number,
    TotalCollection: number,
    TotalQuestion: number,
    TotalCorrectAnswer: number,
    TotalAnswer: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateTriviaDetailsSubscription = {
  onUpdateTriviaDetails?:  {
    __typename: "TriviaDetails",
    id: string,
    TotalCategory: number,
    TotalCollection: number,
    TotalQuestion: number,
    TotalCorrectAnswer: number,
    TotalAnswer: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteTriviaDetailsSubscription = {
  onDeleteTriviaDetails?:  {
    __typename: "TriviaDetails",
    id: string,
    TotalCategory: number,
    TotalCollection: number,
    TotalQuestion: number,
    TotalCorrectAnswer: number,
    TotalAnswer: number,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateCategorySubscription = {
  onCreateCategory?:  {
    __typename: "Category",
    id: string,
    Image: string,
    Type?: string | null,
    Name: string,
    Color: string,
    TotalCollection: number,
    Collection?:  {
      __typename: "ModelCollectionConnection",
      items?:  Array< {
        __typename: "Collection",
        id: string,
        Category_Id: string,
        Image: string,
        Name: string,
        Description: string,
        GameRules: string,
        CollectionType: string,
        Time: number,
        TotalQuestion: number,
        TimesPlayed: number,
        TotalTime: number,
        Difficulty: number,
        GameCode: string,
        owner?: string | null,
        isPublished: boolean,
        isActive?: boolean | null,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      nextToken?: string | null,
    } | null,
    isActive?: boolean | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateCategorySubscription = {
  onUpdateCategory?:  {
    __typename: "Category",
    id: string,
    Image: string,
    Type?: string | null,
    Name: string,
    Color: string,
    TotalCollection: number,
    Collection?:  {
      __typename: "ModelCollectionConnection",
      items?:  Array< {
        __typename: "Collection",
        id: string,
        Category_Id: string,
        Image: string,
        Name: string,
        Description: string,
        GameRules: string,
        CollectionType: string,
        Time: number,
        TotalQuestion: number,
        TimesPlayed: number,
        TotalTime: number,
        Difficulty: number,
        GameCode: string,
        owner?: string | null,
        isPublished: boolean,
        isActive?: boolean | null,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      nextToken?: string | null,
    } | null,
    isActive?: boolean | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteCategorySubscription = {
  onDeleteCategory?:  {
    __typename: "Category",
    id: string,
    Image: string,
    Type?: string | null,
    Name: string,
    Color: string,
    TotalCollection: number,
    Collection?:  {
      __typename: "ModelCollectionConnection",
      items?:  Array< {
        __typename: "Collection",
        id: string,
        Category_Id: string,
        Image: string,
        Name: string,
        Description: string,
        GameRules: string,
        CollectionType: string,
        Time: number,
        TotalQuestion: number,
        TimesPlayed: number,
        TotalTime: number,
        Difficulty: number,
        GameCode: string,
        owner?: string | null,
        isPublished: boolean,
        isActive?: boolean | null,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      nextToken?: string | null,
    } | null,
    isActive?: boolean | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateCollectionSubscription = {
  onCreateCollection?:  {
    __typename: "Collection",
    id: string,
    Category_Id: string,
    Category?:  {
      __typename: "Category",
      id: string,
      Image: string,
      Type?: string | null,
      Name: string,
      Color: string,
      TotalCollection: number,
      Collection?:  {
        __typename: "ModelCollectionConnection",
        nextToken?: string | null,
      } | null,
      isActive?: boolean | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    Image: string,
    Name: string,
    Description: string,
    GameRules: string,
    CollectionType: string,
    Time: number,
    TotalQuestion: number,
    TimesPlayed: number,
    TotalTime: number,
    Difficulty: number,
    GameCode: string,
    owner?: string | null,
    isPublished: boolean,
    TriviaRound?:  {
      __typename: "ModelTriviaRoundConnection",
      items?:  Array< {
        __typename: "TriviaRound",
        id: string,
        Trivia_Id: string,
        DateStarted?: string | null,
        DateCompleted?: string | null,
        Collection_Id?: string | null,
        CurrentQuestionId?: number | null,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      nextToken?: string | null,
    } | null,
    isActive?: boolean | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateCollectionSubscription = {
  onUpdateCollection?:  {
    __typename: "Collection",
    id: string,
    Category_Id: string,
    Category?:  {
      __typename: "Category",
      id: string,
      Image: string,
      Type?: string | null,
      Name: string,
      Color: string,
      TotalCollection: number,
      Collection?:  {
        __typename: "ModelCollectionConnection",
        nextToken?: string | null,
      } | null,
      isActive?: boolean | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    Image: string,
    Name: string,
    Description: string,
    GameRules: string,
    CollectionType: string,
    Time: number,
    TotalQuestion: number,
    TimesPlayed: number,
    TotalTime: number,
    Difficulty: number,
    GameCode: string,
    owner?: string | null,
    isPublished: boolean,
    TriviaRound?:  {
      __typename: "ModelTriviaRoundConnection",
      items?:  Array< {
        __typename: "TriviaRound",
        id: string,
        Trivia_Id: string,
        DateStarted?: string | null,
        DateCompleted?: string | null,
        Collection_Id?: string | null,
        CurrentQuestionId?: number | null,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      nextToken?: string | null,
    } | null,
    isActive?: boolean | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteCollectionSubscription = {
  onDeleteCollection?:  {
    __typename: "Collection",
    id: string,
    Category_Id: string,
    Category?:  {
      __typename: "Category",
      id: string,
      Image: string,
      Type?: string | null,
      Name: string,
      Color: string,
      TotalCollection: number,
      Collection?:  {
        __typename: "ModelCollectionConnection",
        nextToken?: string | null,
      } | null,
      isActive?: boolean | null,
      createdAt: string,
      updatedAt: string,
    } | null,
    Image: string,
    Name: string,
    Description: string,
    GameRules: string,
    CollectionType: string,
    Time: number,
    TotalQuestion: number,
    TimesPlayed: number,
    TotalTime: number,
    Difficulty: number,
    GameCode: string,
    owner?: string | null,
    isPublished: boolean,
    TriviaRound?:  {
      __typename: "ModelTriviaRoundConnection",
      items?:  Array< {
        __typename: "TriviaRound",
        id: string,
        Trivia_Id: string,
        DateStarted?: string | null,
        DateCompleted?: string | null,
        Collection_Id?: string | null,
        CurrentQuestionId?: number | null,
        createdAt: string,
        updatedAt: string,
      } | null > | null,
      nextToken?: string | null,
    } | null,
    isActive?: boolean | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateQuestionSubscription = {
  onCreateQuestion?:  {
    __typename: "Question",
    id: string,
    Collection_Id: string,
    Description: string,
    Rank?: number | null,
    Type?: string | null,
    Media: string,
    Hint?: Array< string | null > | null,
    Answers?:  Array< {
      __typename: "AnswerOptions",
      id: string,
      Answer: Array< string | null >,
      Correct: boolean,
      CaseSensitive: boolean,
    } | null > | null,
    isActive?: boolean | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateQuestionSubscription = {
  onUpdateQuestion?:  {
    __typename: "Question",
    id: string,
    Collection_Id: string,
    Description: string,
    Rank?: number | null,
    Type?: string | null,
    Media: string,
    Hint?: Array< string | null > | null,
    Answers?:  Array< {
      __typename: "AnswerOptions",
      id: string,
      Answer: Array< string | null >,
      Correct: boolean,
      CaseSensitive: boolean,
    } | null > | null,
    isActive?: boolean | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteQuestionSubscription = {
  onDeleteQuestion?:  {
    __typename: "Question",
    id: string,
    Collection_Id: string,
    Description: string,
    Rank?: number | null,
    Type?: string | null,
    Media: string,
    Hint?: Array< string | null > | null,
    Answers?:  Array< {
      __typename: "AnswerOptions",
      id: string,
      Answer: Array< string | null >,
      Correct: boolean,
      CaseSensitive: boolean,
    } | null > | null,
    isActive?: boolean | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateTriviaSettingSubscription = {
  onCreateTriviaSetting?:  {
    __typename: "TriviaSetting",
    id: string,
    CountDownTime: number,
    TriviaMakerPassword: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateTriviaSettingSubscription = {
  onUpdateTriviaSetting?:  {
    __typename: "TriviaSetting",
    id: string,
    CountDownTime: number,
    TriviaMakerPassword: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteTriviaSettingSubscription = {
  onDeleteTriviaSetting?:  {
    __typename: "TriviaSetting",
    id: string,
    CountDownTime: number,
    TriviaMakerPassword: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateTriviaErrorSubscription = {
  onCreateTriviaError?:  {
    __typename: "TriviaError",
    id: string,
    Status: number,
    Message: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateTriviaErrorSubscription = {
  onUpdateTriviaError?:  {
    __typename: "TriviaError",
    id: string,
    Status: number,
    Message: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteTriviaErrorSubscription = {
  onDeleteTriviaError?:  {
    __typename: "TriviaError",
    id: string,
    Status: number,
    Message: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};
