import { API, graphqlOperation } from "aws-amplify";

export const Subscription = (
  statement: string,
  variables: Object = {},
  name: string,
  callback: (data: any) => void
) => {
  let subscribeCategory: any = null;
  const event = API.graphql(graphqlOperation(statement, variables)) as any;
  subscribeCategory = event.subscribe({
    next: ({ provider, value }: any) => {
      let data = value.data[name];
      if (data) callback(data);
    },
  });
  return subscribeCategory;
};
