import { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { useGameStateContext } from "../../context/GameState/GameStateContainer";
import { useGameAdminContext } from "../../context/GameAdmin/GameAdminContainer";
import { OnAdminRefresh } from "../../containers/GameScreen/GameScreen";
import {
  GAME_LOBBY,
  GAME_SCREEN,
  ROUND_END_ROUTE,
  ROUND_SCREEN_ROUTE,
} from "../../containers/routes/RoutesConstants";
import { CircularSpinner } from "../Spinner/Spinner";

const BackAlert: React.FC<{}> = () => {
  const history = useHistory();
  const { setGameState } = useGameStateContext();
  const { resetAdmin } = useGameAdminContext();

  const [finishStatus, setFinishStatus] = useState(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  let currentPage = history?.location?.pathname;

  useEffect(() => {
    if (checkPageURL()) {
      window.history.pushState(null, "", window.location.pathname);
      window.addEventListener("popstate", onBackButtonEvent);
    }
    return () => {
      if (checkPageURL()) {
        window.removeEventListener("popstate", onBackButtonEvent);
      }
    };
  }, [currentPage]); // eslint-disable-line react-hooks/exhaustive-deps

  const checkPageURL = () => {
    switch (currentPage) {
      case GAME_LOBBY:
      case ROUND_SCREEN_ROUTE:
      case GAME_SCREEN:
      case ROUND_END_ROUTE:
        return true;

      default:
        return false;
    }
  };

  const onBackButtonEvent = (e: PopStateEvent) => {
    e.preventDefault();
    if (!finishStatus) {
      if (window.confirm("Do you want to go back?")) {
        setFinishStatus(true);
        setIsLoading(true);
        OnAdminRefresh(history, setGameState, resetAdmin);
      } else {
        window.history.pushState(null, "", window.location.pathname);
        setFinishStatus(false);
      }
    }
  };
  const renderBackAlert = () => {
    if (isLoading) {
      return <CircularSpinner></CircularSpinner>;
    }
    return null;
  };

  return renderBackAlert();
};

export default BackAlert;
