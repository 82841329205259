import { MEDIUM_PURPLE } from "../../../color";
import { GAME_SCREEN } from "../../../containers/routes/RoutesConstants";
import { useGameScreenContext } from "../../../context/GameScreen/GameScreenContainer";
import { isSuperAdmin } from "../../../utilities/util";
import MessageSlot from "../../Trivia/MessageSlot/MessageSlot";
import "../navbar.scss";

interface IPauseButton {
  controlButtons: {
    hideButton: boolean;
    hideLogo: boolean;
    connection: boolean;
    showConnection: boolean;
  };
}

const PauseButton: React.FC<IPauseButton> = ({ controlButtons }) => {
  const { isPause, onPauseButtonClick } = useGameScreenContext();

  const isPauseGamePages = () => {
    let currentPage = window?.location?.pathname;
    if (isSuperAdmin() && currentPage === GAME_SCREEN) {
      return true;
    }
    return false;
  };
  const renderPauseButton = () => {
    if (isPauseGamePages()) {
      return pauseScreen();
    }
    return null;
  };

  const pauseScreen = () => {
    return (
      <div className="navbar-nav ml-2 ">
        {controlButtons?.showConnection ? (
          <div className="nav-item pause-button">
            <MessageSlot
              style={{
                color: controlButtons?.connection ? "#fff" : "#222027",
                cursor: "pointer",
                width: "100%",
              }}
              color={controlButtons?.connection ? "transparent" : MEDIUM_PURPLE}
              className={"connected-users"}
              onClick={() => {
                if (onPauseButtonClick) onPauseButtonClick();
              }}
            >
              {controlButtons?.connection ? (
                <span></span>
              ) : (
                <i
                  className={isPause ? "fas fa-play mr-2" : "fas fa-pause mr-2"}
                  style={{
                    fontSize: "1em",
                  }}
                ></i>
              )}
              {isPause ? "PLAY" : "PAUSE"}
            </MessageSlot>
          </div>
        ) : null}
      </div>
    );
  };

  return renderPauseButton();
};
export default PauseButton;
