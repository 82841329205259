import { useContext, useState } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import {
  TRIVIA_MAKER_ADMIN_GAME_CODE,
  TRIVIA_MAKER_ADMIN_TOKEN,
} from "../../constants";
import LocalStorage from "../../services/LocalStorage";
import { useMusicContext } from "../Music/MusicContainer";
import { AdminAuthContext, AdminAuthProvider } from "./AdminAuthContext";
import { AdminAuthStateInterface } from "./AdminAuthInterface";

const AdminAuthContextContainer: React.FunctionComponent<RouteComponentProps> = ({
  children,
}) => {
  const defaultAdminAuthState: AdminAuthStateInterface = {
    isAuthenticated: false,
    token: "",
    errorMessage: {},
  };
  const [state, setState] = useState<AdminAuthStateInterface>(
    defaultAdminAuthState
  );
  const { resetAudio, resetSFXAudio } = useMusicContext();

  const authenticateUser: (token: string) => void = (token: string): void => {
    LocalStorage.SetItem(TRIVIA_MAKER_ADMIN_TOKEN, token);

    setState((prev) => {
      return {
        ...prev,
        isAuthenticated: true,
        token: token,
      };
    });
  };

  const logoutTriviaAdmin: () => void = (): void => {
    try {
      LocalStorage.RemoveItem(TRIVIA_MAKER_ADMIN_TOKEN);
      LocalStorage.RemoveItem(TRIVIA_MAKER_ADMIN_GAME_CODE);
    } catch (err) {
      console.log("Error while Logout:", err);
    } finally {
      setState((prev) => {
        return {
          ...prev,
          isAuthenticated: false,
        };
      });
      resetAudio();
      resetSFXAudio();
    }
  };

  const checkAdminAuthentication: () => boolean = (): boolean => {
    return !!LocalStorage.GetItem(TRIVIA_MAKER_ADMIN_TOKEN);
  };
  return (
    <AdminAuthProvider
      value={{
        isAuthenticated: state.isAuthenticated,
        errorMessage: state.errorMessage,
        authenticateUser,
        logoutTriviaAdmin,
        checkAdminAuthentication,
      }}
    >
      {children}
    </AdminAuthProvider>
  );
};

export default withRouter(AdminAuthContextContainer);

export const useAdminAuthContext = () => {
  return useContext(AdminAuthContext);
};
