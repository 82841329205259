import React, { useContext, useState } from "react";
import { AuthContext, AuthProvider } from "./AuthContext";
import { withRouter, RouteComponentProps } from "react-router";
import { AuthStateInterface, Message } from "./AuthInterface";
import {
  ADMIN_GAME_CODE,
  ADMIN_TOKEN,
  GAME_CODE,
  GAME_CODE_DATA,
  GAME_TYPE,
  PLAYER_ID,
  PLAYER_PUSHER_ID,
  TOKEN,
  USER_NAME,
} from "../../constants";
import { unSubscribePusher } from "../../services/pusher";
import LocalStorage from "../../services/LocalStorage";
import SessionStorage from "../../services/SessionStorage";
import { removeSuperAdmin } from "../../utilities/util";
//add audio
import { useMusicContext } from "../Music/MusicContainer";

const AuthContextContainer: React.FunctionComponent<RouteComponentProps> = ({
  children,
}) => {
  const defaultAuthState: AuthStateInterface = {
    isAuthenticated: false,
    isAdminAuthenticated: false,
    adminToken: "",
    token: "",
    currentOnlineUser: 0,
    errorMessage: {},
  };

  const [state, setState] = useState<AuthStateInterface>(defaultAuthState);
  ///add audio
  const { resetAudio, resetSFXAudio } = useMusicContext();

  const authenticateUser: (token: string) => void = (token: string): void => {
    LocalStorage.SetItem(TOKEN, token);

    setState((prev) => {
      return {
        ...prev,
        isAuthenticated: true,
        token: token,
      };
    });
  };

  const logoutUser: () => void = (): void => {
    // let code = LocalStorage.GetItem(GAME_CODE);

    // pusher().unsubscribe(`presence-${code}`);
    try {
      removeSuperAdmin();
      LocalStorage.RemoveItem(TOKEN);
      LocalStorage.RemoveItem(GAME_CODE);
      LocalStorage.RemoveItem(GAME_TYPE);
      LocalStorage.RemoveItem(ADMIN_TOKEN);
      LocalStorage.RemoveItem(ADMIN_GAME_CODE);
      LocalStorage.RemoveItem(USER_NAME);
      LocalStorage.RemoveItem(GAME_CODE_DATA);
      SessionStorage.RemoveItem(PLAYER_PUSHER_ID);
      SessionStorage.RemoveItem(PLAYER_ID);
      unSubscribePusher();
      // unSubscribeAllChannels();
      // pusher().disconnect();
    } catch (err) {
      console.log("Error while Logout:", err);
    } finally {
      setState((prev) => {
        return {
          ...prev,
          isAuthenticated: false,
        };
      });
      // add audio
      resetAudio();
      resetSFXAudio();
    }
  };

  const checkAuthentication: () => boolean = (): boolean => {
    return !!LocalStorage.GetItem(TOKEN);
    // return true;
  };

  const UPDATE_ONLINE_USER: (totalUserOnline: number) => void = (
    totalUserOnline: number
  ): void => {
    setState((prev) => {
      return {
        ...prev,
        currentOnlineUser: totalUserOnline,
      };
    });
  };

  const authenticateAdmin: (token: string) => void = (token: string): void => {
    LocalStorage.SetItem(ADMIN_TOKEN, token);
    setState((prev) => {
      return {
        ...prev,
        adminToken: token,
        isAdminAuthenticated: true,
      };
    });
  };

  const logoutAdmin: () => void = (): void => {
    LocalStorage.RemoveItem(ADMIN_TOKEN);
    LocalStorage.RemoveItem(ADMIN_GAME_CODE);
    //pusher.disconnect();
    // let code = LocalStorage.GetItem(GAME_CODE);
    // subscribePusher(code || "");

    setState((prev) => {
      return {
        ...prev,
        isAdminAuthenticated: false,
      };
    });
  };

  const checkAdminAuthentication: () => boolean = (): boolean => {
    return !!LocalStorage.GetItem(ADMIN_TOKEN);
    // return true;
  };

  const setErrorMessage: (err: Message) => void = (err: Message): void => {
    setState((prev) => {
      return {
        ...prev,
        errorMessage: err,
      };
    });
  };

  return (
    <AuthProvider
      value={{
        isAuthenticated: state.isAuthenticated,
        isAdminAuthenticated: state.isAdminAuthenticated,
        currentOnlineUser: state.currentOnlineUser,
        errorMessage: state.errorMessage,
        authenticateUser,
        logoutUser,
        checkAuthentication,
        UPDATE_ONLINE_USER,
        authenticateAdmin,
        logoutAdmin,
        checkAdminAuthentication,
        setErrorMessage,
      }}
    >
      {children}
    </AuthProvider>
  );
};

export default withRouter(AuthContextContainer);

export const useAuthContext = () => {
  return useContext(AuthContext);
};
