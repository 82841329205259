import { EQuestionType } from "../../../containers/Enums/Enums";
import {
  TAnswerOptions,
  TQuestion,
  TTriviaPlayer,
} from "../../../types/ApiTypes";
import { isSuperAdmin, ShuffleArray } from "../../../utilities/util";

export const QUESTION_OPTIONS_COLOR = [
  "#EF5760",
  "#44CD88",
  "#9C59DE",
  "#42A1E5",
];
export const checkSuperAdmin = (adminStatus: boolean | undefined) => {
  if (isSuperAdmin() && !adminStatus) {
    return true;
  }
  return false;
};
export const checkQuestionType = (answerType: string | null | undefined) => {
  switch (answerType) {
    case EQuestionType["Single-Select"]:
      return "SINGLE-SELECT";
    case EQuestionType["Multi-Select"]:
      return "MULTI-SELECT";
    case EQuestionType.Input:
      return "FREE TEXT";
    default:
      return "";
  }
};
export const getPlayerPowerUps = (
  dbPlayers: TTriviaPlayer[] | undefined,
  playerId: string | null
) => {
  const myPlayer: TTriviaPlayer[] =
    dbPlayers?.filter((player) => player.id === playerId) || [];
  return myPlayer[0]?.PowerUps;
};
export const getRandomPlayer = (players: TTriviaPlayer[]) => {
  const randomPlayer = generateRandomNo(1, players.length);
  const selectedPlayer = players[randomPlayer - 1];
  return selectedPlayer;
};
export const generateRandomNo = (min: number, max: number) => {
  return Math.floor(Math.random() * (max - min + 1) + min);
};

export const generateFiftyFiftyAnswers = (question: TQuestion) => {
  const answers = [...(question.Answers || [])] as TAnswerOptions[];
  const inCorrectAnswers = answers.map((x, ind) => (x?.Correct ? null : ind));
  let indexes = inCorrectAnswers.filter((x) => x) as number[];
  ShuffleArray(indexes);
  indexes = indexes.slice(0, 2);
  answers.forEach((x) => (x.Correct = true));
  indexes.forEach((x) => (answers[x].Correct = false));
  return { ...question, Answers: [...answers] } as TQuestion;
};
export const applyFiftyFiftyPowerUp = (
  isApplied: boolean,
  answer:
    | {
        option: string;
        color: string;
        value: string | undefined;
        submit: boolean;
      }
    | undefined
) => {
  if (isApplied && answer?.submit === false) {
    return true;
  }
  return false;
};

export const checkPowerApplied = (
  powers: string[] | undefined,
  currentPower: string
) => {
  powers = powers || [];
  if (powers.includes(currentPower)) {
    return true;
  }
  return false;
};

export const IsApplyFiftyFifty = (questionType: string | null | undefined) => {
  if (
    questionType === EQuestionType["Single-Select"] ||
    questionType === EQuestionType["Multi-Select"]
  ) {
    return true;
  }
  return false;
};

export const updateItem = (itemToPut: any, array: any[]) => {
  const index = array.findIndex((item) => {
    return item[Object.keys(itemToPut)[0]] === Object.keys([itemToPut])[0];
  });

  if (index >= 0) {
    const allData = [...array];
    allData.splice(index, 1, itemToPut);
    return [...allData];
  }
  return array;
};
export const isFiftyFiftyAvailable = (question: TQuestion) => {
  if (question.Type === EQuestionType.Input) {
    return false;
  } else if (question.Type === EQuestionType["Single-Select"]) {
    if (isLessThanFourAnswers(question)) {
      return false;
    } else {
      return true;
    }
  } else if (question.Type === EQuestionType["Multi-Select"]) {
    if (isLessThanFourAnswers(question)) {
      return false;
    } else {
      const correctAnswers = question?.Answers?.filter(
        (answer) => answer?.Correct
      );
      if (correctAnswers && correctAnswers.length === 1) {
        return true;
      } else {
        return false;
      }
    }
  }
  return false;
};
const isLessThanFourAnswers = (question: TQuestion) => {
  if (question && question?.Answers && question?.Answers?.length < 4) {
    return true;
  }
};
