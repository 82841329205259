import React from "react";
import classes from "./Inputfield.module.scss";

export interface IInputField
  extends React.InputHTMLAttributes<HTMLInputElement> {
  onValueChanged?: (event: React.ChangeEvent<HTMLInputElement>) => void;
}
const InputField: React.FC<IInputField> = ({
  onValueChanged = (e) => {},
  ...rest
}) => {
  //const [value, setValue] = useState<string>("");
  const onValueChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.pattern === "") {
      onValueChanged(e);
    } else {
      var regPat = new RegExp(e.target.pattern);
      if (regPat.test(e.target.value)) {
        onValueChanged(e);
      }
    }
  };
  return (
    <input
      className={`${classes["input-field"]} ${rest.className}`}
      {...rest}
      onChange={onValueChange}
      autoComplete={"off"}
    ></input>
  );
};

export default InputField;
