import { useHistory } from "react-router-dom";
import { DARK_GREEN, GREY, YELLOW } from "../../../../color";
import { IGameLocationState } from "../../../../containers/GameScreen/GameScreen";
import { PlainText } from "../../../Text/Text";
import {
  EPowerUp,
  IPowerUpsButton,
} from "../../gameScreenInterfaces/gameScreenInterfaces";
import classes from "./powerUpsButton.module.scss";

const PowerUpsButton: React.FC<IPowerUpsButton> = ({
  name,
  icon,
  isUsed,
  onClick,
  isClicked,
  isDisable,
}) => {
  const history = useHistory();
  const routeState: IGameLocationState = history.location
    .state as IGameLocationState;

  const disablePowerUp = () => {
    if (
      name === EPowerUp.FiftyFifty &&
      routeState?.availablePowerUps &&
      routeState?.availablePowerUps?.fiftyFifty === false
    ) {
      return true;
    }
    return false;
  };
  const isClickedValue = () => {
    if (isClicked === EPowerUp.None) {
      return false;
    } else {
      if (isClicked !== name) return true;
    }
    return false;
  };
  const powerUpColor = () => {
    if (isClicked === name) {
      return DARK_GREEN;
    } else if (isUsed || disablePowerUp() || isClickedValue() || isDisable) {
      return GREY;
    } else {
      return YELLOW;
    }
  };

  return (
    <div
      className={classes["buttonContainer"]}
      onClick={() => {
        onClick();
      }}
      style={
        powerUpColor() !== YELLOW
          ? { pointerEvents: "none", cursor: "default" }
          : { cursor: "pointer" }
      }
    >
      <div
        className={classes["powerIcon"]}
        style={{ backgroundColor: powerUpColor() }}
      >
        <img alt="icon" src={icon} className={classes["powerUpCardIcon"]} />
        <PlainText size={"1.375em"} className={classes["cardTitle"]}>
          {name}
        </PlainText>
      </div>
      {isUsed && powerUpColor() === GREY ? (
        <img
          alt="icon"
          src={"/assests/Icons/PowerUps/close.svg"}
          className={classes["powerUpCardClose"]}
        />
      ) : null}
    </div>
  );
};
export default PowerUpsButton;
