/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const onIncrementTriviaDetails = /* GraphQL */ `
  subscription OnIncrementTriviaDetails {
    onIncrementTriviaDetails {
      id
      TotalCategory
      TotalCollection
      TotalQuestion
      TotalCorrectAnswer
      TotalAnswer
      createdAt
      updatedAt
    }
  }
`;
export const onIncrementCategoryCollections = /* GraphQL */ `
  subscription OnIncrementCategoryCollections {
    onIncrementCategoryCollections {
      id
      Image
      Type
      Name
      Color
      TotalCollection
      Collection {
        items {
          id
          Category_Id
          Image
          Name
          Description
          GameRules
          CollectionType
          Time
          TotalQuestion
          TimesPlayed
          TotalTime
          Difficulty
          GameCode
          owner
          isPublished
          isActive
          createdAt
          updatedAt
        }
        nextToken
      }
      isActive
      createdAt
      updatedAt
    }
  }
`;
export const onIncrementCollectionQuestions = /* GraphQL */ `
  subscription OnIncrementCollectionQuestions {
    onIncrementCollectionQuestions {
      id
      Category_Id
      Category {
        id
        Image
        Type
        Name
        Color
        TotalCollection
        Collection {
          nextToken
        }
        isActive
        createdAt
        updatedAt
      }
      Image
      Name
      Description
      GameRules
      CollectionType
      Time
      TotalQuestion
      TimesPlayed
      TotalTime
      Difficulty
      GameCode
      owner
      isPublished
      TriviaRound {
        items {
          id
          Trivia_Id
          DateStarted
          DateCompleted
          Collection_Id
          CurrentQuestionId
          createdAt
          updatedAt
        }
        nextToken
      }
      isActive
      createdAt
      updatedAt
    }
  }
`;
export const onCreatedTriviaPlayer = /* GraphQL */ `
  subscription OnCreatedTriviaPlayer($GameCode: String) {
    onCreatedTriviaPlayer(GameCode: $GameCode) {
      id
      Trivia_Id
      GameCode
      Trivia {
        id
        GameCode
        DateStarted
        DateCompleted
        HasPowerUps
        CurrentRoundId
        CurrentQuestionId
        TriviaRound {
          nextToken
        }
        TriviaPlayers {
          nextToken
        }
        createdAt
        updatedAt
      }
      PlayerName
      PusherId
      isReady
      isAdmin
      isSuperAdmin
      isAdminActive
      PowerUps {
        id
        PowerUp
        isUsed
      }
      Avatar
      createdAt
      updatedAt
    }
  }
`;
export const onUpdatedTriviaPlayer = /* GraphQL */ `
  subscription OnUpdatedTriviaPlayer($GameCode: String) {
    onUpdatedTriviaPlayer(GameCode: $GameCode) {
      id
      Trivia_Id
      GameCode
      Trivia {
        id
        GameCode
        DateStarted
        DateCompleted
        HasPowerUps
        CurrentRoundId
        CurrentQuestionId
        TriviaRound {
          nextToken
        }
        TriviaPlayers {
          nextToken
        }
        createdAt
        updatedAt
      }
      PlayerName
      PusherId
      isReady
      isAdmin
      isSuperAdmin
      isAdminActive
      PowerUps {
        id
        PowerUp
        isUsed
      }
      Avatar
      createdAt
      updatedAt
    }
  }
`;
export const onDeletedTriviaPlayer = /* GraphQL */ `
  subscription OnDeletedTriviaPlayer($GameCode: String) {
    onDeletedTriviaPlayer(GameCode: $GameCode) {
      id
      Trivia_Id
      GameCode
      Trivia {
        id
        GameCode
        DateStarted
        DateCompleted
        HasPowerUps
        CurrentRoundId
        CurrentQuestionId
        TriviaRound {
          nextToken
        }
        TriviaPlayers {
          nextToken
        }
        createdAt
        updatedAt
      }
      PlayerName
      PusherId
      isReady
      isAdmin
      isSuperAdmin
      isAdminActive
      PowerUps {
        id
        PowerUp
        isUsed
      }
      Avatar
      createdAt
      updatedAt
    }
  }
`;
export const onCreatedCollection = /* GraphQL */ `
  subscription OnCreatedCollection($GameCode: String) {
    onCreatedCollection(GameCode: $GameCode) {
      id
      Category_Id
      Category {
        id
        Image
        Type
        Name
        Color
        TotalCollection
        Collection {
          nextToken
        }
        isActive
        createdAt
        updatedAt
      }
      Image
      Name
      Description
      GameRules
      CollectionType
      Time
      TotalQuestion
      TimesPlayed
      TotalTime
      Difficulty
      GameCode
      owner
      isPublished
      TriviaRound {
        items {
          id
          Trivia_Id
          DateStarted
          DateCompleted
          Collection_Id
          CurrentQuestionId
          createdAt
          updatedAt
        }
        nextToken
      }
      isActive
      createdAt
      updatedAt
    }
  }
`;
export const onUpdatedCollection = /* GraphQL */ `
  subscription OnUpdatedCollection($GameCode: String) {
    onUpdatedCollection(GameCode: $GameCode) {
      id
      Category_Id
      Category {
        id
        Image
        Type
        Name
        Color
        TotalCollection
        Collection {
          nextToken
        }
        isActive
        createdAt
        updatedAt
      }
      Image
      Name
      Description
      GameRules
      CollectionType
      Time
      TotalQuestion
      TimesPlayed
      TotalTime
      Difficulty
      GameCode
      owner
      isPublished
      TriviaRound {
        items {
          id
          Trivia_Id
          DateStarted
          DateCompleted
          Collection_Id
          CurrentQuestionId
          createdAt
          updatedAt
        }
        nextToken
      }
      isActive
      createdAt
      updatedAt
    }
  }
`;
export const onDeletedCollection = /* GraphQL */ `
  subscription OnDeletedCollection($GameCode: String) {
    onDeletedCollection(GameCode: $GameCode) {
      id
      Category_Id
      Category {
        id
        Image
        Type
        Name
        Color
        TotalCollection
        Collection {
          nextToken
        }
        isActive
        createdAt
        updatedAt
      }
      Image
      Name
      Description
      GameRules
      CollectionType
      Time
      TotalQuestion
      TimesPlayed
      TotalTime
      Difficulty
      GameCode
      owner
      isPublished
      TriviaRound {
        items {
          id
          Trivia_Id
          DateStarted
          DateCompleted
          Collection_Id
          CurrentQuestionId
          createdAt
          updatedAt
        }
        nextToken
      }
      isActive
      createdAt
      updatedAt
    }
  }
`;
export const onCreateGameCode = /* GraphQL */ `
  subscription OnCreateGameCode {
    onCreateGameCode {
      id
      GameCode
      AdminGameCode
      MaxPlayers
      GameType
      Reseller
      DaysValid
      DateActivated
      DateExpired
      CollectionPlayed
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateGameCode = /* GraphQL */ `
  subscription OnUpdateGameCode {
    onUpdateGameCode {
      id
      GameCode
      AdminGameCode
      MaxPlayers
      GameType
      Reseller
      DaysValid
      DateActivated
      DateExpired
      CollectionPlayed
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteGameCode = /* GraphQL */ `
  subscription OnDeleteGameCode {
    onDeleteGameCode {
      id
      GameCode
      AdminGameCode
      MaxPlayers
      GameType
      Reseller
      DaysValid
      DateActivated
      DateExpired
      CollectionPlayed
      createdAt
      updatedAt
    }
  }
`;
export const onCreateTrivia = /* GraphQL */ `
  subscription OnCreateTrivia {
    onCreateTrivia {
      id
      GameCode
      DateStarted
      DateCompleted
      HasPowerUps
      CurrentRoundId
      CurrentQuestionId
      TriviaRound {
        items {
          id
          Trivia_Id
          DateStarted
          DateCompleted
          Collection_Id
          CurrentQuestionId
          createdAt
          updatedAt
        }
        nextToken
      }
      TriviaPlayers {
        items {
          id
          Trivia_Id
          GameCode
          PlayerName
          PusherId
          isReady
          isAdmin
          isSuperAdmin
          isAdminActive
          Avatar
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateTrivia = /* GraphQL */ `
  subscription OnUpdateTrivia {
    onUpdateTrivia {
      id
      GameCode
      DateStarted
      DateCompleted
      HasPowerUps
      CurrentRoundId
      CurrentQuestionId
      TriviaRound {
        items {
          id
          Trivia_Id
          DateStarted
          DateCompleted
          Collection_Id
          CurrentQuestionId
          createdAt
          updatedAt
        }
        nextToken
      }
      TriviaPlayers {
        items {
          id
          Trivia_Id
          GameCode
          PlayerName
          PusherId
          isReady
          isAdmin
          isSuperAdmin
          isAdminActive
          Avatar
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteTrivia = /* GraphQL */ `
  subscription OnDeleteTrivia {
    onDeleteTrivia {
      id
      GameCode
      DateStarted
      DateCompleted
      HasPowerUps
      CurrentRoundId
      CurrentQuestionId
      TriviaRound {
        items {
          id
          Trivia_Id
          DateStarted
          DateCompleted
          Collection_Id
          CurrentQuestionId
          createdAt
          updatedAt
        }
        nextToken
      }
      TriviaPlayers {
        items {
          id
          Trivia_Id
          GameCode
          PlayerName
          PusherId
          isReady
          isAdmin
          isSuperAdmin
          isAdminActive
          Avatar
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const onCreateTriviaRound = /* GraphQL */ `
  subscription OnCreateTriviaRound {
    onCreateTriviaRound {
      id
      Trivia_Id
      Trivia {
        id
        GameCode
        DateStarted
        DateCompleted
        HasPowerUps
        CurrentRoundId
        CurrentQuestionId
        TriviaRound {
          nextToken
        }
        TriviaPlayers {
          nextToken
        }
        createdAt
        updatedAt
      }
      DateStarted
      DateCompleted
      Collection_Id
      Collection {
        id
        Category_Id
        Category {
          id
          Image
          Type
          Name
          Color
          TotalCollection
          isActive
          createdAt
          updatedAt
        }
        Image
        Name
        Description
        GameRules
        CollectionType
        Time
        TotalQuestion
        TimesPlayed
        TotalTime
        Difficulty
        GameCode
        owner
        isPublished
        TriviaRound {
          nextToken
        }
        isActive
        createdAt
        updatedAt
      }
      CurrentQuestionId
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateTriviaRound = /* GraphQL */ `
  subscription OnUpdateTriviaRound {
    onUpdateTriviaRound {
      id
      Trivia_Id
      Trivia {
        id
        GameCode
        DateStarted
        DateCompleted
        HasPowerUps
        CurrentRoundId
        CurrentQuestionId
        TriviaRound {
          nextToken
        }
        TriviaPlayers {
          nextToken
        }
        createdAt
        updatedAt
      }
      DateStarted
      DateCompleted
      Collection_Id
      Collection {
        id
        Category_Id
        Category {
          id
          Image
          Type
          Name
          Color
          TotalCollection
          isActive
          createdAt
          updatedAt
        }
        Image
        Name
        Description
        GameRules
        CollectionType
        Time
        TotalQuestion
        TimesPlayed
        TotalTime
        Difficulty
        GameCode
        owner
        isPublished
        TriviaRound {
          nextToken
        }
        isActive
        createdAt
        updatedAt
      }
      CurrentQuestionId
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteTriviaRound = /* GraphQL */ `
  subscription OnDeleteTriviaRound {
    onDeleteTriviaRound {
      id
      Trivia_Id
      Trivia {
        id
        GameCode
        DateStarted
        DateCompleted
        HasPowerUps
        CurrentRoundId
        CurrentQuestionId
        TriviaRound {
          nextToken
        }
        TriviaPlayers {
          nextToken
        }
        createdAt
        updatedAt
      }
      DateStarted
      DateCompleted
      Collection_Id
      Collection {
        id
        Category_Id
        Category {
          id
          Image
          Type
          Name
          Color
          TotalCollection
          isActive
          createdAt
          updatedAt
        }
        Image
        Name
        Description
        GameRules
        CollectionType
        Time
        TotalQuestion
        TimesPlayed
        TotalTime
        Difficulty
        GameCode
        owner
        isPublished
        TriviaRound {
          nextToken
        }
        isActive
        createdAt
        updatedAt
      }
      CurrentQuestionId
      createdAt
      updatedAt
    }
  }
`;
export const onCreateTriviaPlayer = /* GraphQL */ `
  subscription OnCreateTriviaPlayer {
    onCreateTriviaPlayer {
      id
      Trivia_Id
      GameCode
      Trivia {
        id
        GameCode
        DateStarted
        DateCompleted
        HasPowerUps
        CurrentRoundId
        CurrentQuestionId
        TriviaRound {
          nextToken
        }
        TriviaPlayers {
          nextToken
        }
        createdAt
        updatedAt
      }
      PlayerName
      PusherId
      isReady
      isAdmin
      isSuperAdmin
      isAdminActive
      PowerUps {
        id
        PowerUp
        isUsed
      }
      Avatar
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateTriviaPlayer = /* GraphQL */ `
  subscription OnUpdateTriviaPlayer {
    onUpdateTriviaPlayer {
      id
      Trivia_Id
      GameCode
      Trivia {
        id
        GameCode
        DateStarted
        DateCompleted
        HasPowerUps
        CurrentRoundId
        CurrentQuestionId
        TriviaRound {
          nextToken
        }
        TriviaPlayers {
          nextToken
        }
        createdAt
        updatedAt
      }
      PlayerName
      PusherId
      isReady
      isAdmin
      isSuperAdmin
      isAdminActive
      PowerUps {
        id
        PowerUp
        isUsed
      }
      Avatar
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteTriviaPlayer = /* GraphQL */ `
  subscription OnDeleteTriviaPlayer {
    onDeleteTriviaPlayer {
      id
      Trivia_Id
      GameCode
      Trivia {
        id
        GameCode
        DateStarted
        DateCompleted
        HasPowerUps
        CurrentRoundId
        CurrentQuestionId
        TriviaRound {
          nextToken
        }
        TriviaPlayers {
          nextToken
        }
        createdAt
        updatedAt
      }
      PlayerName
      PusherId
      isReady
      isAdmin
      isSuperAdmin
      isAdminActive
      PowerUps {
        id
        PowerUp
        isUsed
      }
      Avatar
      createdAt
      updatedAt
    }
  }
`;
export const onCreateTriviaPlayerAnswer = /* GraphQL */ `
  subscription OnCreateTriviaPlayerAnswer {
    onCreateTriviaPlayerAnswer {
      id
      Trivia_Id
      QuestionId
      CollectionId
      Answers
      PlayerId
      PlayerName
      IsAnswerCorrect
      Points
      PowerUp
      ReceivedTime
      ReactionTime
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateTriviaPlayerAnswer = /* GraphQL */ `
  subscription OnUpdateTriviaPlayerAnswer {
    onUpdateTriviaPlayerAnswer {
      id
      Trivia_Id
      QuestionId
      CollectionId
      Answers
      PlayerId
      PlayerName
      IsAnswerCorrect
      Points
      PowerUp
      ReceivedTime
      ReactionTime
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteTriviaPlayerAnswer = /* GraphQL */ `
  subscription OnDeleteTriviaPlayerAnswer {
    onDeleteTriviaPlayerAnswer {
      id
      Trivia_Id
      QuestionId
      CollectionId
      Answers
      PlayerId
      PlayerName
      IsAnswerCorrect
      Points
      PowerUp
      ReceivedTime
      ReactionTime
      createdAt
      updatedAt
    }
  }
`;
export const onCreateTriviaPlayerRanking = /* GraphQL */ `
  subscription OnCreateTriviaPlayerRanking {
    onCreateTriviaPlayerRanking {
      id
      Trivia_Id
      GameCode
      CorrectAnswersCount
      IncorrectAnswersCount
      Points
      PlayerId
      CurrentRank
      PrevRank
      Player {
        id
        Trivia_Id
        GameCode
        Trivia {
          id
          GameCode
          DateStarted
          DateCompleted
          HasPowerUps
          CurrentRoundId
          CurrentQuestionId
          createdAt
          updatedAt
        }
        PlayerName
        PusherId
        isReady
        isAdmin
        isSuperAdmin
        isAdminActive
        PowerUps {
          id
          PowerUp
          isUsed
        }
        Avatar
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateTriviaPlayerRanking = /* GraphQL */ `
  subscription OnUpdateTriviaPlayerRanking {
    onUpdateTriviaPlayerRanking {
      id
      Trivia_Id
      GameCode
      CorrectAnswersCount
      IncorrectAnswersCount
      Points
      PlayerId
      CurrentRank
      PrevRank
      Player {
        id
        Trivia_Id
        GameCode
        Trivia {
          id
          GameCode
          DateStarted
          DateCompleted
          HasPowerUps
          CurrentRoundId
          CurrentQuestionId
          createdAt
          updatedAt
        }
        PlayerName
        PusherId
        isReady
        isAdmin
        isSuperAdmin
        isAdminActive
        PowerUps {
          id
          PowerUp
          isUsed
        }
        Avatar
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteTriviaPlayerRanking = /* GraphQL */ `
  subscription OnDeleteTriviaPlayerRanking {
    onDeleteTriviaPlayerRanking {
      id
      Trivia_Id
      GameCode
      CorrectAnswersCount
      IncorrectAnswersCount
      Points
      PlayerId
      CurrentRank
      PrevRank
      Player {
        id
        Trivia_Id
        GameCode
        Trivia {
          id
          GameCode
          DateStarted
          DateCompleted
          HasPowerUps
          CurrentRoundId
          CurrentQuestionId
          createdAt
          updatedAt
        }
        PlayerName
        PusherId
        isReady
        isAdmin
        isSuperAdmin
        isAdminActive
        PowerUps {
          id
          PowerUp
          isUsed
        }
        Avatar
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const onCreateTriviaDetails = /* GraphQL */ `
  subscription OnCreateTriviaDetails {
    onCreateTriviaDetails {
      id
      TotalCategory
      TotalCollection
      TotalQuestion
      TotalCorrectAnswer
      TotalAnswer
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateTriviaDetails = /* GraphQL */ `
  subscription OnUpdateTriviaDetails {
    onUpdateTriviaDetails {
      id
      TotalCategory
      TotalCollection
      TotalQuestion
      TotalCorrectAnswer
      TotalAnswer
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteTriviaDetails = /* GraphQL */ `
  subscription OnDeleteTriviaDetails {
    onDeleteTriviaDetails {
      id
      TotalCategory
      TotalCollection
      TotalQuestion
      TotalCorrectAnswer
      TotalAnswer
      createdAt
      updatedAt
    }
  }
`;
export const onCreateCategory = /* GraphQL */ `
  subscription OnCreateCategory {
    onCreateCategory {
      id
      Image
      Type
      Name
      Color
      TotalCollection
      Collection {
        items {
          id
          Category_Id
          Image
          Name
          Description
          GameRules
          CollectionType
          Time
          TotalQuestion
          TimesPlayed
          TotalTime
          Difficulty
          GameCode
          owner
          isPublished
          isActive
          createdAt
          updatedAt
        }
        nextToken
      }
      isActive
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateCategory = /* GraphQL */ `
  subscription OnUpdateCategory {
    onUpdateCategory {
      id
      Image
      Type
      Name
      Color
      TotalCollection
      Collection {
        items {
          id
          Category_Id
          Image
          Name
          Description
          GameRules
          CollectionType
          Time
          TotalQuestion
          TimesPlayed
          TotalTime
          Difficulty
          GameCode
          owner
          isPublished
          isActive
          createdAt
          updatedAt
        }
        nextToken
      }
      isActive
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteCategory = /* GraphQL */ `
  subscription OnDeleteCategory {
    onDeleteCategory {
      id
      Image
      Type
      Name
      Color
      TotalCollection
      Collection {
        items {
          id
          Category_Id
          Image
          Name
          Description
          GameRules
          CollectionType
          Time
          TotalQuestion
          TimesPlayed
          TotalTime
          Difficulty
          GameCode
          owner
          isPublished
          isActive
          createdAt
          updatedAt
        }
        nextToken
      }
      isActive
      createdAt
      updatedAt
    }
  }
`;
export const onCreateCollection = /* GraphQL */ `
  subscription OnCreateCollection {
    onCreateCollection {
      id
      Category_Id
      Category {
        id
        Image
        Type
        Name
        Color
        TotalCollection
        Collection {
          nextToken
        }
        isActive
        createdAt
        updatedAt
      }
      Image
      Name
      Description
      GameRules
      CollectionType
      Time
      TotalQuestion
      TimesPlayed
      TotalTime
      Difficulty
      GameCode
      owner
      isPublished
      TriviaRound {
        items {
          id
          Trivia_Id
          DateStarted
          DateCompleted
          Collection_Id
          CurrentQuestionId
          createdAt
          updatedAt
        }
        nextToken
      }
      isActive
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateCollection = /* GraphQL */ `
  subscription OnUpdateCollection {
    onUpdateCollection {
      id
      Category_Id
      Category {
        id
        Image
        Type
        Name
        Color
        TotalCollection
        Collection {
          nextToken
        }
        isActive
        createdAt
        updatedAt
      }
      Image
      Name
      Description
      GameRules
      CollectionType
      Time
      TotalQuestion
      TimesPlayed
      TotalTime
      Difficulty
      GameCode
      owner
      isPublished
      TriviaRound {
        items {
          id
          Trivia_Id
          DateStarted
          DateCompleted
          Collection_Id
          CurrentQuestionId
          createdAt
          updatedAt
        }
        nextToken
      }
      isActive
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteCollection = /* GraphQL */ `
  subscription OnDeleteCollection {
    onDeleteCollection {
      id
      Category_Id
      Category {
        id
        Image
        Type
        Name
        Color
        TotalCollection
        Collection {
          nextToken
        }
        isActive
        createdAt
        updatedAt
      }
      Image
      Name
      Description
      GameRules
      CollectionType
      Time
      TotalQuestion
      TimesPlayed
      TotalTime
      Difficulty
      GameCode
      owner
      isPublished
      TriviaRound {
        items {
          id
          Trivia_Id
          DateStarted
          DateCompleted
          Collection_Id
          CurrentQuestionId
          createdAt
          updatedAt
        }
        nextToken
      }
      isActive
      createdAt
      updatedAt
    }
  }
`;
export const onCreateQuestion = /* GraphQL */ `
  subscription OnCreateQuestion {
    onCreateQuestion {
      id
      Collection_Id
      Description
      Rank
      Type
      Media
      Hint
      Answers {
        id
        Answer
        Correct
        CaseSensitive
      }
      isActive
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateQuestion = /* GraphQL */ `
  subscription OnUpdateQuestion {
    onUpdateQuestion {
      id
      Collection_Id
      Description
      Rank
      Type
      Media
      Hint
      Answers {
        id
        Answer
        Correct
        CaseSensitive
      }
      isActive
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteQuestion = /* GraphQL */ `
  subscription OnDeleteQuestion {
    onDeleteQuestion {
      id
      Collection_Id
      Description
      Rank
      Type
      Media
      Hint
      Answers {
        id
        Answer
        Correct
        CaseSensitive
      }
      isActive
      createdAt
      updatedAt
    }
  }
`;
export const onCreateTriviaSetting = /* GraphQL */ `
  subscription OnCreateTriviaSetting {
    onCreateTriviaSetting {
      id
      CountDownTime
      TriviaMakerPassword
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateTriviaSetting = /* GraphQL */ `
  subscription OnUpdateTriviaSetting {
    onUpdateTriviaSetting {
      id
      CountDownTime
      TriviaMakerPassword
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteTriviaSetting = /* GraphQL */ `
  subscription OnDeleteTriviaSetting {
    onDeleteTriviaSetting {
      id
      CountDownTime
      TriviaMakerPassword
      createdAt
      updatedAt
    }
  }
`;
export const onCreateTriviaError = /* GraphQL */ `
  subscription OnCreateTriviaError {
    onCreateTriviaError {
      id
      Status
      Message
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateTriviaError = /* GraphQL */ `
  subscription OnUpdateTriviaError {
    onUpdateTriviaError {
      id
      Status
      Message
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteTriviaError = /* GraphQL */ `
  subscription OnDeleteTriviaError {
    onDeleteTriviaError {
      id
      Status
      Message
      createdAt
      updatedAt
    }
  }
`;
