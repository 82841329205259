import React, { useContext, useState, useEffect } from "react";
import { useAuthContext } from "../Auth/AuthContainer";

import { GameScreenContext, GameScreenProvider } from "./GameScreenContext";
import {
  defaultGameScreenContext,
  IGameScreenInterface,
} from "./GameScreenInterface";

const GameScreenContextContainer: React.FC = ({ children }) => {
  const { isAuthenticated } = useAuthContext();
  const [gameScreenState, setGameScreenState] = useState<IGameScreenInterface>(
    defaultGameScreenContext
  );

  useEffect(() => {
    if (!isAuthenticated) {
      setGameScreenState(defaultGameScreenContext);
    }
  }, [isAuthenticated]);

  const triggerSetGameScreenState = (data: IGameScreenInterface) => {
    setGameScreenState((prev) => {
      return { ...prev, ...data };
    });
  };

  const onPauseButtonClick = () => {
    setGameScreenState((prev) => {
      return { ...prev, isPause: !prev.isPause };
    });
  };

  return (
    <GameScreenProvider
      value={{
        isDoubleTimeActive: gameScreenState.isDoubleTimeActive,
        isPause: gameScreenState.isPause,
        receivedPowers: gameScreenState.receivedPowers,
        changeGameScreenState: triggerSetGameScreenState,
        setGameScreenState,
        onPauseButtonClick,
      }}
    >
      {children}
    </GameScreenProvider>
  );
};

export default GameScreenContextContainer;

export const useGameScreenContext = () => {
  return useContext(GameScreenContext);
};
