import { useEffect } from "react";
import { useGameStateContext } from "../../../context/GameState/GameStateContainer";
import { pusher, unSubscribeChannelByName } from "../../../services/pusher";

interface IPusherSubscription {
  channel: string;
  events: string[];
  callback: (eventName: string, data: any) => void;
}
const PusherSubscription: React.FC<IPusherSubscription> = ({
  channel,
  events,
  callback,
}) => {
  const { gameCode } = useGameStateContext();

  useEffect(() => {
    console.log("Mounted");
    let isMount = true;
    if (isMount) {
      let memberChannel = pusher().subscribe(channel);
      events.forEach((event) => {
        memberChannel.bind(event, async (data: any) => {
          callback(event, data);
        });
      });

      return () => {
        console.log("Unmounted Pusher Subscriptions");
        isMount = false;
        memberChannel.unbind_all();
        unSubscribeChannelByName(channel);
        // pusher().unsubscribe(channel);
      };
    }
  }, [gameCode]); // eslint-disable-line react-hooks/exhaustive-deps

  return null;
};

export default PusherSubscription;
