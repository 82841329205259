import {
  TTriviaDetails,
  TCategory,
  TCollection,
  TQuestion,
} from "../../types/ApiTypes";

const defaultTriviaDetails: TTriviaDetails = {
  TotalCategory: 0,
  TotalCollection: 0,
  TotalQuestion: 0,
  TotalCorrectAnswer: 0,
  TotalAnswer: 0,
};

export interface TriviaMakerInterface {
  categories: TCategory[];
  collections: TCollection[];
  questions: TQuestion[];
  triviaDetails: TTriviaDetails;
  fetchCategories: () => void;
  fetchCollection: () => void;
  fetchQuestionById: (id?: string) => Promise<TQuestion[]>;
  deleteQuestionById: (id: string) => void;
  deleteCollectionById: (id: string) => void;
  deleteCategoryById: (id: string) => void;
}

export const defaultTriviaMakerContext: TriviaMakerInterface = {
  categories: [],
  collections: [],
  questions: [],
  triviaDetails: defaultTriviaDetails,
  fetchCategories: () => {},
  fetchCollection: () => {},
  fetchQuestionById: (id?: string) => Promise.resolve([]),
  deleteQuestionById: (id: string) => {},
  deleteCollectionById: (id: string) => {},
  deleteCategoryById: (id: string) => {},
};

// export interface ICategory {
//   id: string;
//   Image: string;
//   Type: string;
//   Name: string;
//   Color: string;
//   TotalCollection: number;
// }

// export interface ICollection {
//   id: string;
//   Category_Id: string;
//   Image: string;
//   Type: string;
//   Name: string;
//   Description: string;
//   GameRules: string;
//   CollectionType: string;
//   Time: number;
//   TotalQuestion: number;
//   TotalTime: number;
//   Difficulty: number;
// }

// export interface IQuestion {
//   id: string;
//   Collection_Id: string;
//   Description: string;
//   Media: string;
//   Hint: string[];
//   Answers: IAnswer[];
// }

// export interface IAnswer {
//   id: string;
//   Answer: string[];
//   Correct: boolean;
//   CaseSensitive: boolean;
// }
