/** @format */

import React, { useContext, useEffect, useState } from "react";
import TriviaMakerManager from "../../services/TriviaMakerService";
import { TriviaMakerContext, TriviaMakerProvider } from "./TriviaMakerContext";
import {
  TriviaMakerInterface,
  defaultTriviaMakerContext,
} from "./TriviaMakerInterface";
import { Subscription } from "../Common/Subscriptions.service";
import * as Subscriptions from "../../graphql/subscriptions";
import { removeItem, updateItem } from "../Common/helper";
import { Category, Collection, Question } from "../../API";
import { TCollection } from "../../types/ApiTypes";
import { getPlayerCode } from "../../utilities/util";
import { MANAGE_PLAYER_COLLECTION } from "../../containers/routes/RoutesConstants";
import {
  ADMIN_LABEL,
  ADMIN_PANEL,
  PLAYER_PANEL,
  PUBLIC,
} from "../../constants";
import { useHistory } from "react-router-dom";
import { RouteChecker } from "../../metadata/RoutesChecker";

const TriviaMakerContextContainer: React.FC<{}> = ({ children }) => {
  const [state, setState] = useState<TriviaMakerInterface>(
    defaultTriviaMakerContext
  );
  const [gameCode, setGameCode] = useState<string>("");
  const history = useHistory<any>();
  const playerType = history.location.state?.player || "";

  useEffect(() => {
    fetchCategories();
    fetchTriviaDetails();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const checkCollectionType = () => {
    const currentRoute = window.location.pathname;
    if (
      currentRoute === MANAGE_PLAYER_COLLECTION ||
      (playerType && playerType !== ADMIN_LABEL)
    ) {
      const playerCode = getPlayerCode() || "";
      setGameCode(playerCode);
    } else {
      setGameCode(ADMIN_LABEL);
    }
  };
  const checkSubscriptionType = (data: Collection) => {
    const currentPlayer = history.location.state?.player;
    if (
      RouteChecker() === ADMIN_PANEL ||
      (currentPlayer && currentPlayer === ADMIN_LABEL)
    ) {
      if (data.GameCode === ADMIN_LABEL) {
        return true;
      }
    } else if (
      RouteChecker() === PLAYER_PANEL ||
      (currentPlayer && currentPlayer !== ADMIN_LABEL)
    ) {
      if (
        data.GameCode === getPlayerCode() ||
        (data.GameCode === PUBLIC && data.owner === getPlayerCode())
      ) {
        return true;
      }
    }
    return false;
  };
  useEffect(() => {
    checkCollectionType();
    fetchCollection();
  }, [gameCode]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const updateDetails = Subscription(
      Subscriptions.onIncrementTriviaDetails,
      {},
      "onIncrementTriviaDetails",
      (data) => {
        setState((prev) => {
          return { ...prev, triviaDetails: { ...data } };
        });
      }
    );

    return () => {
      updateDetails?.unsubscribe();
      console.log("Unmounted");
    };
  }, []);

  useEffect(() => {
    const addCategory = Subscription(
      Subscriptions.onCreateCategory,
      {},
      "onCreateCategory",
      (data) => {
        console.log("On Category Created:", data);
        setState((prev) => {
          return { ...prev, categories: [...prev.categories, data] };
        });
      }
    );
    const updateCategory = Subscription(
      Subscriptions.onUpdateCategory,
      {},
      "onUpdateCategory",
      (data: Category) => {
        console.log("On Category Update:", data);
        onCategoryUpdated(data);
        setState((prev) => {
          return {
            ...prev,
            categories: [...updateItem(data, prev.categories)],
          };
        });
      }
    );
    const removeCategory = Subscription(
      Subscriptions.onDeleteCategory,
      {},
      "onDeleteCategory",
      (data: Category) => {
        setState((prev) => {
          return {
            ...prev,
            categories: [...removeItem(data.id || "", prev.categories)],
          };
        });
      }
    );
    return () => {
      addCategory?.unsubscribe();
      updateCategory?.unsubscribe();
      removeCategory?.unsubscribe();
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const updateCollectionData = (data: Collection) => {
    if (data.isActive) {
      setState((prev) => {
        return {
          ...prev,
          collections: [...updateItem(data, prev.collections)],
        };
      });
    } else {
      setState((prev) => {
        const filtered = prev?.collections?.filter(
          (singleCollection) => singleCollection?.id !== data?.id
        );
        return {
          ...prev,
          collections: [...filtered],
        };
      });
    }
  };
  const createCollectionData = (data: Collection) => {
    setState((prev) => {
      return { ...prev, collections: [...prev.collections, data] };
    });
  };
  const removeCollectionData = (data: Collection) => {
    setState((prev) => {
      return {
        ...prev,
        collections: [...removeItem(data.id || "", prev.collections)],
      };
    });
  };
  useEffect(() => {
    const addCollection = Subscription(
      Subscriptions.onCreateCollection,
      {},
      "onCreateCollection",
      (data: Collection) => {
        if (checkSubscriptionType(data)) {
          createCollectionData(data);
        }
      }
    );
    const updateCollection = Subscription(
      Subscriptions.onUpdateCollection,
      {},
      "onUpdateCollection",
      (data: Collection) => {
        if (checkSubscriptionType(data)) {
          updateCollectionData(data);
        }
      }
    );
    const removeCollection = Subscription(
      Subscriptions.onDeleteCollection,
      {},
      "onDeleteCollection",
      (data: Collection) => {
        if (checkSubscriptionType(data)) {
          removeCollectionData(data);
        }
      }
    );
    const addedQuestion = Subscription(
      Subscriptions.onIncrementCollectionQuestions,
      {},
      "onIncrementCollectionQuestions",
      (data: Collection) => {
        setState((prev) => {
          return {
            ...prev,
            collections: [...updateItem(data, prev.collections)],
          };
        });
      }
    );
    return () => {
      addCollection?.unsubscribe();
      updateCollection?.unsubscribe();
      removeCollection?.unsubscribe();
      addedQuestion?.unsubscribe();
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  // useEffect(() => {
  //   const addCollection = Subscription(
  //     Subscriptions.onCreatedCollection,
  //     { GameCode: gameCode },
  //     "onCreatedCollection",
  //     (data: Collection) => {
  //       createCollectionData(data);
  //     }
  //   );
  //   const updateCollection = Subscription(
  //     Subscriptions.onUpdatedCollection,
  //     { GameCode: gameCode },
  //     "onUpdatedCollection",
  //     (data: Collection) => {
  //       updateCollectionData(data);
  //     }
  //   );
  //   const removeCollection = Subscription(
  //     Subscriptions.onDeletedCollection,
  //     { GameCode: gameCode },
  //     "onDeletedCollection",
  //     (data: Collection) => {
  //       removeCollectionData(data);
  //     }
  //   );
  //   const addedQuestion = Subscription(
  //     Subscriptions.onIncrementCollectionQuestions,
  //     {},
  //     "onIncrementCollectionQuestions",
  //     (data: Collection) => {
  //       setState((prev) => {
  //         return {
  //           ...prev,
  //           collections: [...updateItem(data, prev.collections)],
  //         };
  //       });
  //     }
  //   );
  //   return () => {
  //     addCollection?.unsubscribe();
  //     updateCollection?.unsubscribe();
  //     removeCollection?.unsubscribe();
  //     addedQuestion?.unsubscribe();
  //   };
  // }, [gameCode]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const addQuestion = Subscription(
      Subscriptions.onCreateQuestion,
      {},
      "onCreateQuestion",
      (data: Question) => {
        setState((prev) => {
          return { ...prev, questions: [...prev.questions, data] };
        });
      }
    );
    const updateQuestion = Subscription(
      Subscriptions.onUpdateQuestion,
      {},
      "onUpdateQuestion",
      (data: Question) => {
        setState((prev) => {
          return {
            ...prev,
            questions: [...updateItem(data, prev.questions)],
          };
        });
      }
    );
    const removeQuestion = Subscription(
      Subscriptions.onDeleteQuestion,
      {},
      "onDeleteQuestion",
      (data: Question) => {
        setState((prev) => {
          return {
            ...prev,
            questions: [...removeItem(data.id || "", prev.questions)],
          };
        });
      }
    );
    return () => {
      addQuestion?.unsubscribe();
      updateQuestion?.unsubscribe();
      removeQuestion?.unsubscribe();
    };
  }, []);

  const onCategoryUpdated = (data: Category) => {
    setState((prev) => {
      const updatedCollections: TCollection[] = prev.collections.map(
        (collection: TCollection) => {
          if (collection.Category_Id === data.id) {
            delete data.Collection;
            return {
              ...collection,
              Category: { ...collection.Category, ...data },
            };
          }
          return collection;
        }
      );
      return { ...prev, collections: [...updatedCollections] };
    });
  };
  const fetchTriviaDetails = async () => {
    try {
      const { body } = await TriviaMakerManager.GetTriviaDetails();
      setState((prev) => {
        return { ...prev, triviaDetails: { ...body } };
      });
    } catch (err) {
      console.log("Error while fetching Trivia Details");
    }
  };
  const fetchCategories = async () => {
    try {
      const { body } = await TriviaMakerManager.GetCategoryList();

      setState((prev) => {
        return { ...prev, categories: [...prev.categories, ...body] };
      });
    } catch (err) {
      console.log("Error while fetching Categories");
    }
  };
  const getPublicCollections = async () => {
    let publicCollections: TCollection[] = [];
    if (gameCode !== "admin") {
      const sharedCollections = await TriviaMakerManager.GetCollections(PUBLIC);
      publicCollections = [...(sharedCollections?.body || [])];
      const filtered = publicCollections?.filter(
        (singleCollection) => singleCollection.owner === gameCode
      );
      publicCollections = [...filtered];
    }
    return [...publicCollections];
  };
  const fetchCollection = async () => {
    try {
      if (gameCode) {
        const { body } = await TriviaMakerManager.GetCollections(gameCode);
        const publicCollections: TCollection[] = await getPublicCollections();

        setState((prev) => {
          return {
            ...prev,
            collections: [...prev.collections, ...body, ...publicCollections],
          };
        });
      }
    } catch (err) {
      console.log("Error while fetching Collections");
    }
  };
  const fetchQuestionById = async (id?: string) => {
    try {
      if (id) {
        const questions = state.questions.filter(
          (item) => item.Collection_Id === id
        );
        if (questions.length === 0) {
          const { body } = await TriviaMakerManager.GetQuestionList(id);
          setState((prev) => {
            return { ...prev, questions: [...body] };
          });
          return [...body];
        } else {
          setState((prev) => {
            return { ...prev, questions: [...questions] };
          });
        }
      } else {
        setState((prev) => {
          return { ...prev, questions: [] };
        });
      }
    } catch (err) {
      console.log("Error while fetching Questions");
    }
    return [];
  };
  const deleteQuestionById = (id: string) => {
    try {
      if (state.questions.length > 0) {
        // const { body } = await TriviaMakerManager.GetQuestionList(id);
        const filteredQuestions = state.questions.filter(
          (ques) => ques.id !== id
        );
        setState((prev) => {
          return { ...prev, questions: [...filteredQuestions] };
        });
      }
    } catch (err) {
      console.log("Error while fetching Questions");
    }
  };
  const deleteCollectionById = (id: string) => {
    try {
      if (state.collections.length > 0) {
        const filteredCollections = state.collections.filter(
          (collection) => collection.id !== id
        );
        setState((prev) => {
          return { ...prev, collections: [...filteredCollections] };
        });
      }
    } catch (err) {
      console.log("Error while deleting Collections");
    }
  };
  const deleteCategoryById = (id: string) => {
    try {
      if (state.categories.length > 0) {
        const filteredCategories = state.categories.filter(
          (category) => category.id !== id
        );
        setState((prev) => {
          return { ...prev, categories: [...filteredCategories] };
        });
      }
    } catch (err) {
      console.log("Error while deleting categories");
    }
  };

  return (
    <TriviaMakerProvider
      value={{
        categories: state.categories,
        collections: state.collections,
        questions: state.questions,
        triviaDetails: state.triviaDetails,
        fetchCategories,
        fetchCollection,
        fetchQuestionById,
        deleteQuestionById,
        deleteCollectionById,
        deleteCategoryById,
      }}
    >
      {children}
    </TriviaMakerProvider>
  );
};

export default TriviaMakerContextContainer;

export const useTriviaMakerContext = () => {
  return useContext(TriviaMakerContext);
};
