import { ADMIN_PANEL, PLAYER_PANEL } from "../constants";
import {
  ADMIN_LOGIN,
  CREATE_CATEGORY,
  GAME_INTRO,
  GAME_LOBBY,
  GAME_SCREEN,
  JOINING,
  LOGIN_ROUTE,
  MAIN_MENU_ROUTE,
  MANAGE_CATEGORIES,
  MANAGE_COLLECTION,
  MANAGE_PLAYER_COLLECTION,
  POWER_UPS,
  RESUME_GAME_RULES,
  ROUND_END_ROUTE,
  ROUND_SCREEN_ROUTE,
  SELECT_COLLECTION,
  TRIVIA_GAMES,
  TRIVIA_MAKER,
  TRIVIA_RESULTS,
} from "../containers/routes/RoutesConstants";

export const RouteChecker = () => {
  const currentRoute = window.location.pathname;
  switch (currentRoute) {
    case LOGIN_ROUTE:
    case MAIN_MENU_ROUTE:
    case RESUME_GAME_RULES:
    case GAME_LOBBY:
    case SELECT_COLLECTION:
    case ROUND_SCREEN_ROUTE:
    case ROUND_END_ROUTE:
    case GAME_SCREEN:
    case GAME_INTRO:
    case TRIVIA_GAMES:
    case TRIVIA_RESULTS:
    case MANAGE_PLAYER_COLLECTION:
    case JOINING:
    case POWER_UPS:
      return PLAYER_PANEL;
    case TRIVIA_MAKER:
    case MANAGE_CATEGORIES:
    case CREATE_CATEGORY:
    case MANAGE_COLLECTION:
    case ADMIN_LOGIN:
      return ADMIN_PANEL;

    default:
      return "";
  }
};
