import {
  ISubmitAnswers,
  ISubmitAnswer,
} from "../../context/GameAdmin/GameAdminInterface";
import { TQuestion, TTriviaPlayer } from "../../types/ApiTypes";
import { ILeaderboardSlot } from "../EndRound/EndRound";

export const getRoundStanding = (questions: ISubmitAnswers[]) => {
  const roundPlayers: any = {};
  questions.forEach((question) => {
    question.answers.forEach((player) => {
      if (roundPlayers[player.playerId]) {
        const correct = roundPlayers[player.playerId].correct;
        const incorrect = roundPlayers[player.playerId].incorrect;
        roundPlayers[player.playerId] = {
          ...roundPlayers[player.playerId],
          correct: player.isCorrect ? correct + 1 : correct,
          incorrect: player.isCorrect ? incorrect : incorrect + 1,
          points: player.isCorrect ? correct + 1 : correct,
        } as ILeaderboardSlot;
      } else {
        roundPlayers[player.playerId] = {
          correct: player.isCorrect ? 1 : 0,
          incorrect: player.isCorrect ? 0 : 1,
          points: player.isCorrect ? 1 : 0,
          player: player.playerName,
          rank: 1,
          id: player.playerId,
        } as ILeaderboardSlot;
      }
    });
  });
  return roundPlayers;
};

export const getRoundPlayers = (players: any) => {
  return players.map((player: any) => {
    return {
      correct: player.CorrectAnswersCount,
      incorrect: player.IncorrectAnswersCount,
      points: player.Points,
      player: player.Player?.PlayerName,
      avatar: player.Player?.Avatar,
      rank: player.CurrentRank,
      prevRank: player.PrevRank,
      id: player.PlayerId,
    };
  });
};

export const getDefaultPlayers = (
  players: TTriviaPlayer[]
): ISubmitAnswer[] => {
  return players.map((player) => {
    return {
      playerId: player.id as string,
      playerName: player.PlayerName || "",
      answers: [],
      isCorrect: false,
    };
  });
};

export const updateItem = (itemToPut: any, array: any[]) => {
  const index = array.findIndex((item) => {
    return item.playerId === itemToPut.playerId;
  });

  if (index >= 0) {
    const allData = [...array];
    allData.splice(index, 1, itemToPut);
    return [...allData];
  }
  return array;
};

export const removeCorrectField = (question: TQuestion) => {
  const mappedAnswers =
    question.Answers?.map((answer) => {
      const mapAnswer = { ...answer };
      mapAnswer.Correct = false;
      return mapAnswer;
    }) || [];
  return { ...question, Answers: [...mappedAnswers] } as TQuestion;
};
