import Pusher, { Channel } from "pusher-js";
import Aws from "../aws-exports.js";
import { ADMIN_TOKEN, USER_NAME } from "../constants";
import LocalStorage from "./LocalStorage";
import { nanoid } from "nanoid";
import { getPusherPlayer } from "../utilities/util";

// Pusher.logToConsole = true;
const currentURL = Aws.aws_cloud_logic_custom[0].endpoint;
var pusherInstance: any = null;
export const pusher = () => {
  if (pusherInstance) return pusherInstance;
  console.log("Creating Pusher Instance");
  pusherInstance = getPusherInstance();
  return pusherInstance;
};

export const getPusherInstance = () => {
  const userId = getPusherPlayer();
  return new Pusher(process.env.REACT_APP_PUSHER_KEY || "", {
    cluster: process.env.REACT_APP_PUSHER_CLUSTER,
    forceTLS: true,
    authEndpoint: `${currentURL}/v1/pusher/auth`,
    auth: {
      params: {
        userId: userId ? userId : nanoid(),
        userName: LocalStorage.GetItem(USER_NAME)?.toUpperCase(),
        isAdmin: LocalStorage.GetItem(ADMIN_TOKEN) ? true : false,
      },
    },
  });
};

const unSubscribeAllChannels = () => {
  if (pusherInstance) {
    const allChannels = pusherInstance.allChannels();
    allChannels.forEach((channel: Channel) => {
      pusherInstance.unsubscribe(channel.name);
    });
  }
};
export const unSubscribeChannelByName = (name: string) => {
  if (pusherInstance) {
    pusherInstance.unsubscribe(name);
  }
};
export const unSubscribePusher = () => {
  if (pusherInstance) {
    unSubscribeAllChannels();
    pusherInstance = null;
  }
};

export const triggerClientEvent = (
  channel: string,
  event: string,
  data: any
) => {
  let memberChannel = pusher().subscribe(channel);
  memberChannel.trigger(event, data);
  // memberChannel.bind(CHANNEL_JOIN_SUCCESS, () => {

  // });
};
