import API from "@aws-amplify/api";
import {
  ADMIN_LOGIN,
  GET_GAME_STATUS,
  LOGIN,
  LOG_ERROR,
  NEXT_ROUND,
  START_GAME,
  SUBMIT_ANSWER,
  SUBMIT_ROUND,
  TRIVIA_MAKER_LOGIN,
} from "./ServicesConstants";
import { TTriviaPlayer } from "../types/ApiTypes";
import LocalStorage from "./LocalStorage";
import { TOKEN } from "../constants";
// import { PowerUp } from "../API";

const API_NAME = "quizapi";
const API_NAME_REST = "questiongamerest";
const path = "/v1/reseller";
const HEADERS = {
  headers: {
    "content-type": "application/json",
  },
};
const GetHeader = () => {
  const headers = { ...HEADERS.headers };
  return {
    headers: { ...headers, Authorization: LocalStorage.GetItem(TOKEN) },
  };
};
export default class NetworkManager {
  static HealthCheck() {
    return API.get(API_NAME_REST, `/v1/healthcheck`, {
      ...GetHeader(),
    });
  }

  static Login(gameCode: string, name: string) {
    const request = {
      gameCode: gameCode.toUpperCase(),
      name: name,
    };
    return API.post(API_NAME_REST, LOGIN, {
      ...HEADERS,
      body: request,
    });
  }

  static AdminLogin(gameCode: string, adminGameCode: string) {
    const request = {
      gameCode: gameCode.toUpperCase(),
      adminCode: adminGameCode.toLowerCase(),
    };
    return API.post(API_NAME_REST, ADMIN_LOGIN, {
      ...GetHeader(),
      body: request,
    });
  }

  static AdminLoginToTriviaMaker(gameCode: string) {
    const request = {
      gameCode: gameCode,
    };
    return API.post(API_NAME_REST, TRIVIA_MAKER_LOGIN, {
      ...HEADERS,
      body: request,
    });
  }

  static StartGame(
    collectionIds: string[],
    playerIds: string[],
    hasPowerUps: boolean
  ) {
    const request = {
      collectionIds: collectionIds,
      playerIds,
      hasPowerUps,
    };
    const path = START_GAME; //replace this with the path you have configured on your API
    return API.post(API_NAME, path, {
      body: request,
      ...GetHeader(),
    });
  }

  static NextRound(triviaId: string, collectionId: string) {
    const request = {
      triviaId,
      collectionId,
    };
    const path = NEXT_ROUND; //replace this with the path you have configured on your API
    return API.post(API_NAME, path, {
      body: request,
      ...GetHeader(),
    });
  }

  static GetGameStatus = () => {
    return API.get(API_NAME, GET_GAME_STATUS, {
      ...GetHeader(),
    });
  };
  static SubmitAnswer(data: any) {
    return API.post(API_NAME, SUBMIT_ANSWER, {
      body: data,
      ...GetHeader(),
    });
  }

  static SubmitRound(data: any) {
    return API.post(API_NAME, SUBMIT_ROUND, {
      body: data,
      ...GetHeader(),
    });
  }
  static PushError(status: number, message: string) {
    const request = {
      status,
      message,
    };
    return API.post(API_NAME, LOG_ERROR, {
      body: request,
      ...GetHeader(),
    });
  }
  static GetCollections(code?: string) {
    const endpoint = `${path}/listCollection/${code}`;
    return API.get(API_NAME, endpoint, {
      ...HEADERS,
    });
  }

  static TriggerPusherEvent(
    eventName: string,
    channelName: string | undefined,
    data: any
  ) {
    const payload = {
      eventName,
      data,
      channelName,
    };
    return API.post(API_NAME_REST, `/triggerPusherEvent`, {
      ...GetHeader(),
      body: payload,
    });
  }

  static GetChannelStatus(channelName: string | undefined) {
    return API.get(
      API_NAME_REST,
      `/v1/pusher/getChannelStatus/${channelName}`,
      {
        ...GetHeader(),
      }
    );
  }

  static GetAllPlayer() {
    return API.get(API_NAME_REST, `/v1/getAllPlayers`, {
      ...GetHeader(),
    });
  }
  static CreatePlayer(payload: TTriviaPlayer) {
    return API.post(API_NAME_REST, `/v1/createPlayer`, {
      ...GetHeader(),
      body: payload,
    });
  }
  static UpdatePlayer(playerId: string, payload: TTriviaPlayer) {
    return API.post(API_NAME_REST, `/v1/updatePlayer/${playerId}`, {
      ...GetHeader(),
      body: payload,
    });
  }

  static GetPlayerByName(playerName: string, triviaId: string) {
    return API.get(API_NAME_REST, `/v1/getPlayer/${triviaId}/${playerName}`, {
      ...GetHeader(),
    });
  }

  static UsePowerUp(playerId: string, powerUp: string) {
    return API.post(API_NAME_REST, `/v1/usePowerUp/${playerId}`, {
      ...GetHeader(),
      body: {
        powerUp,
      },
    });
  }
  // static RemovePlayer(playerId: string) {
  //   return API.del(API_NAME_REST, `/v1/deletePlayer/${playerId}`, {
  //     ...GetHeader(),
  //   });
  // }
  static GetTriviaResults() {
    return API.get(API_NAME_REST, `/v1/getTriviaResults`, {
      ...GetHeader(),
    });
  }
}
