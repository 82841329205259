export interface IGameScreenInterface {
  isPause?: boolean | undefined;
  receivedPowers?: string[];
  isDoubleTimeActive?: boolean | undefined;
  changeGameScreenState?: (data: IGameScreenInterface) => void;
  setGameScreenState?: (
    value: React.SetStateAction<IGameScreenInterface>
  ) => void;
  onPauseButtonClick?: () => void;
}

export const defaultGameScreenContext: IGameScreenInterface = {
  isPause: false,
  receivedPowers: [],
  isDoubleTimeActive: false,
  changeGameScreenState: (data: IGameScreenInterface) => {},
  setGameScreenState: (value: React.SetStateAction<IGameScreenInterface>) => {},
  onPauseButtonClick: () => {},
};
