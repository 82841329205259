import React, { useContext, useState, useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";
import {
  AUDIO_PLAYING_STATUS,
  AUDIO_VOLUME,
  SFX_PLAYING_STATUS,
  SFX_VOLUME,
} from "../../constants";
import { EAudioSounds } from "../../containers/Enums/Enums";
import { LOGIN_ROUTE } from "../../containers/routes/RoutesConstants";
import LocalStorage from "../../services/LocalStorage";
import {
  delay,
  getMusicVolume,
  getSFXVolume,
  IsMobile,
  isMusicPlaying,
  IsSafari,
  SFXPlayingStatus,
} from "../../utilities/util";
import { MusicContext, MusicProvider } from "./MusicContext";
import { defaultMusicContext } from "./MusicInterface";

interface IMusicState {
  isPlaying: boolean;
  volume: number;
}

const MusicContextContainer: React.FC = ({ children }) => {
  const isPlayingAudio = useRef(true);
  const audioVolume = useRef(0.1);
  const [audioState, setAudioState] = useState<IMusicState>({
    isPlaying: isPlayingAudio?.current,
    volume: audioVolume?.current,
  });
  const [audio, setAudio] = useState<HTMLAudioElement>(new Audio());
  const [audioProperties, setAudioProperties] = useState({
    loop: false,
    path: "",
  });
  const isSFXAudio = useRef(true);
  const SFXVolume = useRef(0.1);
  const [sfxState, setSFXState] = useState<IMusicState>({
    isPlaying: isSFXAudio?.current,
    volume: SFXVolume?.current,
  });
  const [sfxAudio, setSFXAudio] = useState<HTMLAudioElement>(new Audio());
  const [sfxAudioProperties, setSFXAudioProperties] = useState({
    loop: false,
    path: "",
  });
  const history = useHistory();

  useEffect(() => {
    const isaudioPlaying = isMusicPlaying();
    const isSFXPlaying = SFXPlayingStatus();
    const getAudioVolume = getMusicVolume();
    const sfxVolume = getSFXVolume();
    isPlayingAudio.current = isaudioPlaying;
    audioVolume.current = getAudioVolume;
    isSFXAudio.current = isSFXPlaying;
    SFXVolume.current = sfxVolume;
    setSFXState((prev) => ({
      ...prev,
      isPlaying: isSFXAudio?.current,
      volume: SFXVolume?.current,
    }));
    setAudioState((prev) => ({
      ...prev,
      isPlaying: isPlayingAudio?.current,
      volume: audioVolume?.current,
    }));
  }, []);

  useEffect(() => {
    if (audio) {
      audio.onloadedmetadata = () => {};
      audio.onerror = () => {
        console.log("Error on Loading Audio");
        setAudio(
          new Audio(`${process.env.PUBLIC_URL}/${audioProperties.path}`)
        );
      };
    }
    return () => {
      audio.pause();
      audio.onloadedmetadata = null;
      audio.ontimeupdate = null;
    };
  }, [audio]); // eslint-disable-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (sfxAudio) {
      sfxAudio.onloadedmetadata = () => {};
      sfxAudio.onerror = () => {
        console.log("Error on Loading Audio");
        setAudio(
          new Audio(`${process.env.PUBLIC_URL}/${sfxAudioProperties.path}`)
        );
      };
    }
    return () => {
      sfxAudio.pause();
      sfxAudio.onloadedmetadata = null;
      sfxAudio.ontimeupdate = null;
    };
  }, [sfxAudio]); // eslint-disable-line react-hooks/exhaustive-deps

  const changeAudioVolume = (value: number) => {
    audioVolume.current = value / 100;
    setAudioState((prev) => {
      return { ...prev, volume: audioVolume.current };
    });
    audio.volume = audioVolume.current;
    LocalStorage.SetItem(AUDIO_VOLUME, JSON.stringify(audio.volume));
  };
  const changeSFXVolume = (value: number) => {
    SFXVolume.current = value / 100;
    setSFXState((prev) => {
      return { ...prev, volume: SFXVolume.current };
    });
    sfxAudio.volume = SFXVolume.current;
    LocalStorage.SetItem(SFX_VOLUME, JSON.stringify(sfxAudio.volume));
  };

  const playMusic = () => {
    isPlayingAudio.current = true;
    setAudioState((prev) => {
      return { ...prev, isPlaying: isPlayingAudio.current };
    });
    LocalStorage.SetItem(
      AUDIO_PLAYING_STATUS,
      JSON.stringify(isPlayingAudio.current)
    );
  };
  const playSFXMusic = () => {
    isSFXAudio.current = true;
    setSFXState((prev) => {
      return { ...prev, isPlaying: isSFXAudio.current };
    });
    LocalStorage.SetItem(
      SFX_PLAYING_STATUS,
      JSON.stringify(isSFXAudio.current)
    );
  };
  const pauseMusic = () => {
    audio.pause();
    isPlayingAudio.current = false;
    setAudioState((prev) => {
      return { ...prev, isPlaying: isPlayingAudio.current };
    });
    LocalStorage.SetItem(
      AUDIO_PLAYING_STATUS,
      JSON.stringify(isPlayingAudio.current)
    );
  };

  const pauseSFXMusic = () => {
    sfxAudio.pause();
    isSFXAudio.current = false;
    setSFXState((prev) => {
      return { ...prev, isPlaying: isSFXAudio.current };
    });
    LocalStorage.SetItem(
      SFX_PLAYING_STATUS,
      JSON.stringify(isSFXAudio.current)
    );
  };

  const toggleAudioState = () => {
    try {
      if (!isPlayingAudio.current) {
        isPlayingAudio.current = true;
        audio.play();
      } else {
        audio.pause();
        isPlayingAudio.current = false;
      }
      setAudioState((prev) => {
        return { ...prev, isPlaying: isPlayingAudio.current };
      });
      LocalStorage.SetItem(
        AUDIO_PLAYING_STATUS,
        JSON.stringify(isPlayingAudio.current)
      );
    } catch (err) {
      console.log("Music Error....", err);
    }
  };

  const toggleSFXState = () => {
    try {
      if (!isSFXAudio.current) {
        isSFXAudio.current = true;
        sfxAudio.play();
      } else {
        sfxAudio.pause();
        isSFXAudio.current = false;
      }
      setSFXState((prev) => {
        return { ...prev, isPlaying: isSFXAudio.current };
      });
      LocalStorage.SetItem(
        SFX_PLAYING_STATUS,
        JSON.stringify(isSFXAudio.current)
      );
    } catch (err) {
      console.log("SFX Error....", err);
    }
  };

  const playAudio = async (audio: HTMLAudioElement) => {
    if (history.location.pathname !== LOGIN_ROUTE) {
      try {
        // audio.muted = true;
        await audio.play();
        // audio.muted = false;
        playMusic();
      } catch (err) {
        await delay(200);
        playAudio(audio);
      }
    }
  };
  const playSFXAudio = async (newAudio: HTMLAudioElement) => {
    if (history.location.pathname !== LOGIN_ROUTE) {
      try {
        // newAudio.muted = true;
        await newAudio.play();
        // newAudio.muted = false;
        playSFXMusic();
      } catch (err) {
        await delay(200);
        playSFXAudio(newAudio);
      }
    }
  };

  const mainSounds = (path: string, loop: boolean = false) => {
    try {
      if (path !== audioProperties.path) {
        const newAudio = new Audio(`${process.env.PUBLIC_URL}/${path}`);
        newAudio.loop = loop;
        newAudio.volume = audioVolume?.current;
        if (isPlayingAudio.current) {
          playAudio(newAudio);
        }
        setAudio(newAudio);
        setAudioProperties((prev) => {
          return { ...prev, loop: loop, path: path };
        });
      }
    } catch (err) {
      console.log("set music error", err);
    }
  };
  const changeSFXSounds = (path: string, loop: boolean = false) => {
    try {
      if (path !== sfxAudioProperties.path) {
        const newAudio = new Audio(`${process.env.PUBLIC_URL}/${path}`);
        newAudio.loop = loop;
        newAudio.volume = SFXVolume.current;
        if (isSFXAudio.current) {
          playSFXAudio(newAudio);
        }
        setSFXAudio(newAudio);
        setSFXAudioProperties((prev) => {
          return { ...prev, loop: loop, path: path };
        });
      }
    } catch (err) {
      console.log("set music error", err);
    }
  };

  const changeAudioClip = (path: string, loop: boolean = false) => {
    if (path === EAudioSounds.BGMusic) {
      resetSFXAudio();
      mainSounds(path, loop);
    } else {
      resetAudio();
      changeSFXSounds(path, loop);
    }
  };
  const createNewAudioInstance = (path: string) => {
    try {
      if (isPlayingAudio.current) {
        const newAudio = new Audio(`${process.env.PUBLIC_URL}/${path}`);
        newAudio.play();
        newAudio.volume = audioVolume.current;
      }
    } catch (err) {}
  };

  const createNewSFXInstance = (path: string) => {
    try {
      if (isSFXAudio.current) {
        const newAudio = new Audio(`${process.env.PUBLIC_URL}/${path}`);
        newAudio.play();
        newAudio.volume = SFXVolume.current;
      }
    } catch (err) {}
  };
  const resetAudio = async () => {
    await delay(200);
    let audioInstance = new Audio();
    audioInstance.volume = audioVolume.current;
    setAudio(audioInstance);
    setAudioProperties((prev) => ({ ...prev, path: "" }));
  };
  const resetSFXAudio = async () => {
    await delay(200);
    let audioInstance = new Audio();
    audioInstance.volume = SFXVolume.current;
    setSFXAudio(audioInstance);
    setSFXAudioProperties((prev) => ({ ...prev, path: "" }));
  };

  return IsMobile() || IsSafari() ? (
    <MusicProvider
      value={{
        ...defaultMusicContext,
      }}
    >
      {children}
    </MusicProvider>
  ) : (
    <MusicProvider
      value={{
        isAudioPlaying: audioState.isPlaying,
        isSFXPlaying: sfxState.isPlaying,
        audioVolume: audio?.volume || 0,
        sfxVolume: sfxAudio?.volume || 0,
        audio: audio,
        sfxAudio: sfxAudio,
        toggleAudioState,
        toggleSFXState,
        changeAudioVolume,
        changeSFXVolume,
        changeAudioClip,
        playMusic,
        playSFXMusic,
        pauseMusic,
        pauseSFXMusic,
        createNewAudioInstance,
        createNewSFXInstance,
        resetAudio,
        resetSFXAudio,
      }}
    >
      {children}
    </MusicProvider>
  );
};

export default MusicContextContainer;

export const useMusicContext = () => {
  return useContext(MusicContext);
};
