import React from "react";
import { YELLOW } from "../../../color";
import { BoldText } from "../../Text/Text";

interface IMessageSlot {
  rotation?: number;
  color?: string;
  style?: React.CSSProperties;
  onClick?: () => void;
  className?: string;
}
const MessageSlot: React.FC<IMessageSlot> = ({
  children,
  rotation,
  color,
  style,
  onClick,
  className,
}) => {
  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        transform: `rotate(${rotation}deg)`,
        backgroundColor: color,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        fontSize: "1.3em",
        ...style,
      }}
      onClick={onClick}
    >
      <BoldText
        className={className}
        style={{ fontFamily: "Bebas Neue", marginTop: "3px" }}
      >
        {children}
      </BoldText>
    </div>
  );
};

MessageSlot.defaultProps = {
  rotation: 0,
  color: YELLOW,
};
export default MessageSlot;
