import React, { useEffect, useState } from "react";
import { Clamp } from "../../utilities/util";

interface ITimer {
  totalTime: number;
  startDate: number;
  callback: (remainingTime: number, progress: number) => void;
}
const Timer: React.FC<ITimer> = ({ totalTime, startDate, callback }) => {
  const [time, setTime] = useState(totalTime);

  useEffect(() => {
    setTime(totalTime);
  }, [totalTime]);

  useEffect(() => {
    let timer: ReturnType<typeof setTimeout>;
    timer = setInterval(() => {
      setTime((prev) => {
        const serverDate = new Date(startDate).getTime();
        const diff = (new Date().getTime() - serverDate) / 1000;
        const time = totalTime - diff;
        if (time <= 0) {
          clearTimeout(timer);
        }
        return Clamp(time, 0, Number.MAX_SAFE_INTEGER);
      });
    }, 10);
    return () => {
      clearTimeout(timer);
    };
  }, [startDate, totalTime]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (time === 0) {
      setTime(0);
      callback(0, 0);
      return;
    }
    const clampProgress = Clamp(
      100 - ((totalTime - time) / totalTime) * 100,
      0,
      100
    );
    // setProgress(clampProgress);
    callback(time, clampProgress);
  }, [time, totalTime]); // eslint-disable-line react-hooks/exhaustive-deps

  return null;
};

export default Timer;
