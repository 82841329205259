import React, { useState, useEffect } from "react";
import { styleTime } from "../../../utilities/util";
import CFontAwesomeIcon from "../../cfontawesomicon/cfontawesomicon";
import classes from "./recordingPannel.module.scss";
import { Storage } from "aws-amplify";
import { useMusicContext } from "../../../context/Music/MusicContainer";
import { useGameAdminContext } from "../../../context/GameAdmin/GameAdminContainer";
import { checkSuperAdmin } from "../../gameScreenHelper/gameScreenUtils/gameScreenUtils";
export interface IRecording {
  header: string;
  path: string;
  hasSlider?: boolean;
  isSubmit: boolean;
}
const Recording: React.FC<IRecording> = ({
  header,
  path,
  hasSlider,
  isSubmit,
}) => {
  const [isPlaying, setPlaying] = useState<boolean>(false);
  const [sliderValue, setSliderValue] = useState<number>(0);
  const [currentTime, setCurrentTime] = useState<number>(0);
  const [audio, setAudioClip] = useState(new Audio());
  const { resetSFXAudio, resetAudio, sfxVolume } = useMusicContext();
  const { adminStatus } = useGameAdminContext();

  const newAudio = React.useRef(new Audio());
  useEffect(() => {
    resetSFXAudio();
    resetAudio();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isSubmit) {
      newAudio.current = new Audio();
      setAudioClip(new Audio());
    }
  }, [isSubmit]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const getS3Audio = async () => {
      try {
        if (isSubmit) {
          return;
        }
        const url = await Storage.get(path);
        newAudio.current = new Audio(`${url}`);
        newAudio.current.volume = sfxVolume || 0.1;
        if (!checkSuperAdmin(adminStatus)) {
          newAudio.current.autoplay = true;
          setPlaying(true);
        }
        resetSFXAudio();
        resetAudio();
        setAudioClip(newAudio.current);
      } catch (err) {}
    };
    getS3Audio();
  }, [path]); // eslint-disable-line react-hooks/exhaustive-deps
  const soundPlayedPercentage = () => {
    if (audio) {
      let value = (audio.currentTime / audio.duration) * 100;
      return Math.ceil(value) + 0.5;
    } else {
      return 0;
    }
  };

  const getSlider = () => {
    if (hasSlider) {
      return (
        <div
          style={{
            display: "flex",
            justifyContent: "space-around",
            textAlign: "center",
            width: "100%",
          }}
        >
          <input
            className={classes["slide-bar"]}
            style={{
              background: `linear-gradient(to right, #F9CE29 0%, #F9CE29 ${soundPlayedPercentage()}%, #F3E1B9 ${soundPlayedPercentage()}%, #F3E1B9 100%)`,
              cursor: "pointer",
            }}
            type="range"
            min={0}
            max={audio.duration ? audio.duration : "100"}
            value={Math.ceil(sliderValue)}
            onChange={(e) => {
              setSliderValue(parseInt(e.target.value));
              audio.currentTime = parseInt(e.target.value);
            }}
          />
        </div>
      );
    }
    return null;
  };
  const toggleAudioStateFunction = () => {
    try {
      if (!isPlaying) {
        audio.play();
      } else {
        audio.pause();
      }
      setPlaying(!isPlaying);
    } catch (err) {}
  };

  useEffect(() => {
    if (isSubmit) {
      newAudio.current = new Audio();
    }
    audio.onloadedmetadata = () => {};
    audio.ontimeupdate = () => {
      setCurrentTime(audio.currentTime);
      setSliderValue(audio.currentTime);
    };
    audio.onended = () => {
      audio.currentTime = 0;
      setPlaying(false);
    };
    audio.onerror = () => {
      console.log("Error on Loading Audio");
      setAudioClip(new Audio(`${path}`));
    };
    return () => {
      audio.pause();
      audio.onloadedmetadata = null;
      audio.ontimeupdate = null;
    };
  }, [audio, path]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className={classes["audio-container"]}>
      <span
        style={{
          fontSize: "1.25em",
          fontFamily: "SegoeUI",
          color: "#FFFFFF",
          marginLeft: "2%",
          marginBottom: "11px",
        }}
      >
        <span
          style={{
            height: "18px",
            marginBottom: "4px",
            marginRight: "4px",
          }}
        >
          <img src="/assests/Icons/music.png" alt="" />
        </span>
        {header}
      </span>
      <div
        className={`${classes["audio-bar"]}`}
        style={{
          backgroundColor: `${!hasSlider ? "#f9ce29" : ""}`,
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-around",
            textAlign: "center",
            width: "80%",
            height: "70px",
            marginLeft: "3px",
            cursor: "pointer",
          }}
        >
          <CFontAwesomeIcon
            parentIcon={"fas fa-circle"}
            childIcon={isPlaying ? "fas fa-pause" : "fas fa-play"}
            style={{
              fontSize: "6em",
              // marginBottom: "130px",
            }}
            childStyle={{
              fontSize: "0.6em",
              display: "flex",
              justifyContent: "center",
            }}
            onClick={() => {
              toggleAudioStateFunction();
            }}
          ></CFontAwesomeIcon>
        </div>
      </div>
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
          fontSize: "0.8em",
          color: "white",
          marginTop: "10px",
          marginBottom: "5px",
        }}
        className={classes["audio-circle"]}
      >
        <span
          style={{
            width: "20%",
            color: "#FFFFFF",
            fontSize: "1.25em",
            fontFamily: "SegoeUI",
          }}
        >
          {isNaN(audio.currentTime) ? "00:00" : styleTime(currentTime)}
        </span>
        <span
          style={{
            width: "25%",
            color: "#FFFFFF",
            fontSize: "1.25em",
            fontFamily: "SegoeUI",
          }}
        >
          {isNaN(audio.duration) ? "00:00" : styleTime(audio.duration)}
        </span>
      </div>
      {getSlider()}
    </div>
  );
};

export default Recording;
// export default React.memo(Recording, function arePropsAreEqual(prev, next) {
//   return prev.path === next.path || prev.isSubmit !== next.isSubmit;
// });
