import { PowerUp } from "../../../API";

export interface IAnswers {
  answer: string[];
  playerId: string;
  playerName: string;
  receivedTime: string | undefined;
  reactionTime: string | undefined;
}

export interface IPowerUpsButton {
  name?: string;
  icon?: string;
  isUsed?: boolean;
  onClick: () => void;
  isClicked?: string;
  isDisable: boolean;
}
export interface IPowerUpsState {
  isUsed: boolean;
  color: string;
  disabled: boolean;
  powerUp?: string;
  id?: string;
}
export interface IPowerUpSection {
  isDisable: boolean;
  handleSabotagePowerUpResponse: (data: any) => void;
  handleDoublePointsPowerUpResponse: (data: any) => void;
  handleFiftyFiftyPowerUpResponse: (data: any) => void;
  handleDoubleTimePowerUpResponse: (data: any) => void;
}
export enum EPowerUp {
  None = "None",
  FiftyFifty = "50/50",
  DoubleTime = "X2 TIME",
  DoublePoint = "X2 POINTS",
  Sabotage = "SABOTAGE",
}
export const POWER_UPS_BUTTONS_DATA = [
  {
    key: PowerUp.FiftyFifty,
    icon: "/assests/Icons/PowerUps/divide.svg",
    name: EPowerUp.FiftyFifty,
  },
  {
    key: PowerUp.DoubleTime,
    icon: "/assests/Icons/PowerUps/timer.svg",
    name: EPowerUp.DoubleTime,
  },
  {
    key: PowerUp.DoublePoint,
    icon: "/assests/Icons/PowerUps/arrow.svg",
    name: EPowerUp.DoublePoint,
  },
  {
    key: PowerUp.Sabotage,
    icon: "/assests/Icons/PowerUps/face.svg",
    name: EPowerUp.Sabotage,
  },
];
