import React from "react";
import {
  defaultGameScreenContext,
  IGameScreenInterface,
} from "./GameScreenInterface";

export const GameScreenContext = React.createContext<IGameScreenInterface>(
  defaultGameScreenContext
);
export const GameScreenProvider = GameScreenContext.Provider;
export const GameScreenConsumer = GameScreenContext.Consumer;
