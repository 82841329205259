/** @format */

import { useState, useEffect, useRef } from "react";
import classes from "./GameScreen.module.scss";
import { BoldText, NeonText, PlainText } from "../../components/Text/Text";
import ProgressBar from "../../components/Trivia/ProgressBar/ProgressBar";
import SelectAnswer from "../../components/SelectAnswer/SelectAnswer";
import HeaderSlot from "../../components/Trivia/GameRules/HeaderSlot";
import { EQuestionType } from "../Enums/Enums";
import { GREEN_POPUP, PURPLE, RED, WHITE, YELLOW } from "../../color";
import InputField from "../../components/inputfield/Inputfield";
import { useGameStateContext } from "../../context/GameState/GameStateContainer";
import { TCollection, TQuestion, TTriviaPlayer } from "../../types/ApiTypes";
import { useHistory } from "react-router";
import { IRoundLocationState } from "../Trivia/Round/RoundScreen";
import CustomS3Image from "../../components/s3Image/S3Image";
import {
  GAME_END_ROUTE,
  GAME_SCREEN,
  MAIN_MENU_ROUTE,
  ROUND_END_ROUTE,
} from "../routes/RoutesConstants";
import PusherSubscription from "../../components/Trivia/GameLobby/PusherSubscription";
import {
  delay,
  getChannel,
  getOnlinePlayers,
  getPlayer,
  isSuperAdmin,
  styleTime,
  IsImage,
  IsAudio,
} from "../../utilities/util";
import {
  ANSWER_RESPONSE,
  MOVE_TO_QUESTION,
  ROUND_ENDED,
  SUBMIT_ANSWER,
  USER_NAME,
  ADMIN_SUBMISSION_DELAY,
  PLAYER_ID,
  RELOAD_STATUS,
  WINDOW_RELOAD,
  PAUSE_GAME,
  POWER_UP_REQUEST,
} from "../../constants";
import { triggerClientEvent } from "../../services/pusher";
import { useGameAdminContext } from "../../context/GameAdmin/GameAdminContainer";
import { getRoundPlayers, removeCorrectField } from "./helper";
import { ISubmitAnswer } from "../../context/GameAdmin/GameAdminInterface";
import LocalStorage from "../../services/LocalStorage";
import Timer from "../../components/timer/timer";
import NetworkManager from "../../services/NetworkManager";
import { QUESTION_OPTIONS } from "../TriviaCreator/CreateQuestion/QuestionsStats/AddAnswerDetails/AddAnswerSlot";

///add audio
import { EAudioSounds } from "../Enums/Enums";
import { useMusicContext } from "../../context/Music/MusicContainer";
import { addSeconds } from "date-fns";
import SessionStorage from "../../services/SessionStorage";
import { IGameState } from "../../context/GameState/GameStateInterface";
import { CircularSpinner } from "../../components/Spinner/Spinner";
import Recording from "../../components/game/recordings/recordingPanel";
import { useGameScreenContext } from "../../context/GameScreen/GameScreenContainer";
import { IAnswers } from "../../components/gameScreenHelper/gameScreenInterfaces/gameScreenInterfaces";
import {
  applyFiftyFiftyPowerUp,
  checkPowerApplied,
  checkQuestionType,
  checkSuperAdmin,
  generateFiftyFiftyAnswers,
  getRandomPlayer,
  isFiftyFiftyAvailable,
  QUESTION_OPTIONS_COLOR,
} from "../../components/gameScreenHelper/gameScreenUtils/gameScreenUtils";
import PowerUpsSection from "../../components/gameScreenHelper/gameScreenComponents/powerUpsSection/powerUpsSection";
import { PowerUp } from "../../API";

export interface IGameLocationState extends IRoundLocationState {
  question: TQuestion;
  questionNo: number;
  availablePowerUps?: { [key: string]: boolean };
}

// const defaultRouteState: IGameLocationState = {
//   question: defaultQuestion,
//   questionNo: 0,
//   round: 0,
//   totalRounds: 8,
//   collection: defaultCollection,
//   startDelay: 20,
//   startDate: new Date().getTime(),
//   triviaId: "",
// };
const GameScreen = () => {
  const history = useHistory();
  const routeState: IGameLocationState = history.location
    .state as IGameLocationState;
  // const routeState: IGameLocationState = defaultRouteState;
  const collection: TCollection = routeState?.collection;

  const {
    gameCode,
    dbPlayers,
    connectedPlayer,
    adminId,
    setGameState,
  } = useGameStateContext();
  const {
    currentRoundQuestion,
    setAdminState,
    roundResult,
    selectedCollections,
    resetAdmin,
    adminStatus,
  } = useGameAdminContext();
  const currentQuestion = useRef<TQuestion>(routeState?.question);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [revealAnswer, setRevealAnswer] = useState<boolean>(false);

  const questionNo = useRef<number>(routeState?.questionNo);
  const isStepFreeze = useRef<boolean>(false);
  const startingDate = useRef(new Date(routeState?.startDate).getTime());
  const dbPlayersRef = useRef<TTriviaPlayer[]>([]);
  const connectedPlayersRef = useRef<string[] | undefined>([]);
  const playersAnswers = useRef<ISubmitAnswer[]>([]);
  const readyPlayersRef = useRef<TTriviaPlayer[]>([]);
  const [totalPlayers, setTotalPlayers] = useState<number>(0);
  const [answeredPlayers, setAnsweredPlayers] = useState<number>(0);

  const [progress, setProgress] = useState(0);
  const [hasBanner, showBanner] = useState(false);
  const [popup, setPopup] = useState({ value: "", color: "" });
  const [isSubmit, setIsSubmit] = useState(false);
  const [correctAnswer, setCorrectAnswer] = useState<string[]>([]);
  const [answerType, setAnswerType] = useState<string | null | undefined>(
    routeState?.question?.Type
  );
  const [isTimerStop, stopTimer] = useState(false);
  const reactionTimeStartRef = useRef<number>(Date.now());
  const [timerUp, setTimerUp] = useState<boolean>(false);
  const [correctChecked, setCorrectChecked] = useState<boolean>(false);
  const totalTime = useRef(collection?.Time || 10);
  const [time, setTime] = useState(totalTime?.current);
  interface IPlayerPowers {
    [key: string]: string[];
  }
  const requestedPower = useRef<IPlayerPowers>({});

  const adminAnswersList = useRef<TQuestion>(routeState?.question);
  //add audio
  const {
    changeAudioClip,
    sfxAudio,
    createNewSFXInstance,
    resetSFXAudio,
  } = useMusicContext();
  const {
    isPause,
    changeGameScreenState,
    setGameScreenState,
    receivedPowers,
  } = useGameScreenContext();
  const pauseTime = useRef<number>(0);
  const isFirstRender = useRef(false);

  useEffect(() => {
    if (isFirstRender.current) {
      if (isSuperAdmin()) {
        const channel: string = getChannel(gameCode);
        const eventData: any = {
          isPause: isPause,
        };
        if (isPause) {
          const serverDate = new Date(startingDate.current).getTime();
          const diff = (new Date().getTime() - serverDate) / 1000;
          pauseTime.current = diff;
          triggerClientEvent(channel, PAUSE_GAME, eventData);
        } else {
          eventData.pauseTime = pauseTime.current;
          triggerClientEvent(channel, PAUSE_GAME, eventData);
          pauseScreenEvent(eventData);
        }
      }
    } else {
      isFirstRender.current = true;
    }
  }, [isPause]); // eslint-disable-line react-hooks/exhaustive-deps

  const pauseScreenEvent = (data: any) => {
    if (!data.isPause) {
      const newDate = new Date().getTime();
      const pauseTime = data.pauseTime * 1000;
      startingDate.current = newDate - pauseTime;
    }
    if (setGameScreenState)
      setGameScreenState((prev) => {
        return { ...prev, isPause: data.isPause };
      });
  };

  useEffect(() => {
    if (isSubmit || timerUp) {
      sfxAudio?.pause();
    }
    if (IsAudio(currentQuestion?.current?.Media || "")) {
      resetSFXAudio();
    } else {
      changeAudioClip(EAudioSounds.QuestionCountdown, true);
    }
  }, [isSubmit, timerUp]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (adminId && adminId.length === 0) {
      setIsLoading(true);
      OnAdminRefresh(history, setGameState, resetAdmin);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (timerUp) {
      sendAnswerResponseToUsers();
    }
  }, [timerUp]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    reactionTimeStartRef.current = Date.now();
    setTimerUp(false);
    setCorrectChecked(false);
    requestedPower.current = {};
    if (changeGameScreenState) {
      changeGameScreenState({
        receivedPowers: [],
      });
    }
  }, [routeState?.questionNo, setTimerUp]); // eslint-disable-line react-hooks/exhaustive-deps

  /////////check players added
  useEffect(() => {
    dbPlayersRef.current = dbPlayers || [];
    connectedPlayersRef.current = connectedPlayer;
  }, [dbPlayers, connectedPlayer]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    fetchOnlinePlayers();
  }, [connectedPlayer, dbPlayers]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (checkSuperAdmin(adminStatus)) {
      const readPlayers = getReadyPlayers();
      setTotalPlayers((prev) => readyPlayersRef.current.length);
      setAnsweredPlayers((prev) => matchSubmittedAnswerPlayers(readPlayers));
    }
  }, [questionNo.current, connectedPlayer, dbPlayers, totalPlayers]); // eslint-disable-line react-hooks/exhaustive-deps

  const matchSubmittedAnswerPlayers = (readPlayers: TTriviaPlayer[]) => {
    let count = 0;
    playersAnswers.current.forEach((answer) => {
      readPlayers.forEach((player) => {
        if (player.id === answer.playerId) count = count + 1;
      });
    });
    return count;
  };

  const getReadyPlayers = () => {
    const players = getOnlinePlayers(
      dbPlayersRef.current || [],
      connectedPlayersRef.current || []
    );
    const readPlayers = players.filter(
      (player) => player.isReady && player.Trivia_Id
    );
    return readPlayers;
  };

  const fetchOnlinePlayers = () => {
    const readPlayers = getReadyPlayers();
    readyPlayersRef.current = readPlayers;
    const playersAnswerStatus = checkAllUsersAnswered();
    if (playersAnswerStatus) {
      if (isSuperAdmin()) {
        setTimerUp(true);
      }
    }
  };
  const triggerTimeUpAnswerSubmit = () => {
    const playerId = getPlayer() || "";
    const channel: string = getChannel(gameCode);
    const data = {
      playerId: playerId,
      playerName: LocalStorage.GetItem(USER_NAME) || "",
      answers: [],
      isCorrect: false,
    } as ISubmitAnswer;
    if (isSuperAdmin()) {
      submitAnswerEvent({ ...data });
    } else {
      triggerClientEvent(channel, SUBMIT_ANSWER, { ...data });
    }
  };
  const getDoubleTimePlayers = () => {
    let doubleTimePlayers = [];
    for (const property in requestedPower.current) {
      if (requestedPower.current[property].includes(PowerUp.DoubleTime)) {
        doubleTimePlayers.push(property);
      }
    }
    return doubleTimePlayers;
  };
  const isAdminUseDoubleTime = () => {
    const adminId = getPlayer();
    const players = getDoubleTimePlayers();
    if (players.includes(adminId || "") || players.length === 0) {
      return true;
    }
    return false;
  };
  const timeUpCase = async () => {
    if (!isStepFreeze.current) {
      if (!isSubmit) {
        setIsSubmit(true);
        showBanner(true);
        //add audio
        // createNewSFXInstance(EAudioSounds.Correct);
        setPopup({ value: "YOU RAN OUT OF TIME!", color: YELLOW });
        triggerTimeUpAnswerSubmit();
      }
      if (isSuperAdmin()) {
        await delay(ADMIN_SUBMISSION_DELAY);
        if (isAdminUseDoubleTime()) {
          setTimerUp(true);
        }
      }
    }
  };

  const handleMultiSelect = (value: string) => {
    if (correctAnswer.includes(value)) {
      let currentIndex = correctAnswer.indexOf(value);
      correctAnswer.splice(currentIndex, currentIndex + 1);
      setCorrectAnswer([...correctAnswer]);
    } else {
      setCorrectAnswer([...correctAnswer, value]);
    }
  };

  const handleAnswerClick = (value: string) => {
    if (answerType === EQuestionType["Single-Select"]) {
      setCorrectAnswer([value]);
    } else if (answerType === EQuestionType["Multi-Select"]) {
      handleMultiSelect(value);
    } else if (answerType === EQuestionType.Input) {
      setCorrectAnswer([value]);
    }
  };

  const handleSubmitButtonDisable = () => {
    if (answerType === EQuestionType["Single-Select"]) {
      return correctAnswer.length === 0;
    } else if (answerType === EQuestionType["Multi-Select"]) {
      return correctAnswer.length === 0;
    } else if (answerType === EQuestionType.Input) {
      if (correctAnswer.length > 0) {
        return correctAnswer[0] === "";
      }
      return true;
    }
    return true;
  };
  const handleDisable = (value: string | undefined) => {
    if (answerType === EQuestionType["Single-Select"]) {
      return correctAnswer.includes(value || "");
    }
    return false;
  };

  const checkSelectedAnswer = (value: string) => {
    if (
      answerType === EQuestionType["Single-Select"] ||
      answerType === EQuestionType["Multi-Select"]
    ) {
      return correctAnswer.includes(value);
    }
    return false;
  };

  const triggerAnswerSubmit = () => {
    const playerId = getPlayer() || "";
    console.log("Trigger Submit Answer:", playerId);
    const channel: string = getChannel(gameCode);
    const reactionTime = (Date.now() - reactionTimeStartRef.current) / 1000;
    const data = {
      playerId: playerId,
      playerName: LocalStorage.GetItem(USER_NAME) || "",
      answers: [...correctAnswer],
      reactionTime: reactionTime.toString(),
    } as ISubmitAnswer;
    console.log("Data:", data);
    if (isSuperAdmin()) {
      submitAnswerEvent(data);
    } else {
      triggerClientEvent(channel, SUBMIT_ANSWER, { ...data });
    }
  };

  const showAnswerResponseMessages = (isCorrect: boolean, points: number) => {
    if (isCorrect && points) {
      //add audio
      changeAudioClip(EAudioSounds.Correct, false);
      setPopup({
        value: `GREAT WORK! YOU'VE SCORED +${points} PTS`,
        color: GREEN_POPUP,
      });
    } else {
      //add audio
      changeAudioClip(EAudioSounds.InCorrect, false);
      setPopup({
        value: "OH NO! THAT'S INCORRECT!",
        color: RED,
      });
    }
  };
  const removeReceivedPowers = () => {
    setCorrectChecked(true);
    if (changeGameScreenState) {
      changeGameScreenState({
        receivedPowers: [],
      });
    }
  };

  const showAnswerStatusBanner = async (data: any) => {
    const playerId = getPlayer() || "";
    const players = data.players as ISubmitAnswer[];
    const mine = players.find((player) => player.playerId === playerId);
    const wasCorrect = mine ? mine.isCorrect : false;
    const answers = mine?.answers || [];
    const points = mine?.points || 0;
    //add audio
    createNewSFXInstance(EAudioSounds.DrumRoll);
    showBanner(true);
    setPopup({ value: "THE CORRECT ANSWER WAS...", color: PURPLE });
    await delay(1500);
    currentQuestion.current.Answers = [...data?.question?.Answers];
    removeReceivedPowers();
    await delay(2000);
    if (answers && answers.length > 0) {
      showAnswerResponseMessages(wasCorrect, points);
    } else {
      resetSFXAudio();
    }

    isStepFreeze.current = true;
  };

  const handleSubmit = () => {
    if (checkSuperAdmin(adminStatus)) {
      setRevealAnswer(true);
    } else {
      triggerAnswerSubmit();
      setIsSubmit(true);
    }
  };

  const onKeyDownEvent = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (
      e.key === "Enter" &&
      correctAnswer?.length > 0 &&
      correctAnswer[0] !== ""
    ) {
      handleSubmit();
    }
  };
  const mappedData = () => {
    const mappedValues = currentQuestion.current?.Answers?.map((answer) => {
      return {
        option: QUESTION_OPTIONS[parseInt(answer?.id || "") - 1],
        color: QUESTION_OPTIONS_COLOR[parseInt(answer?.id || "") - 1],
        value: answer?.Answer?.toString(),
        submit: !!answer?.Correct,
      };
    });
    return mappedValues;
  };

  const moveToQuestionEvent = (data: any) => {
    history.replace({
      pathname: GAME_SCREEN,
      state: {
        ...data,
      },
    });
    currentQuestion.current = data.question;
    // if (checkSuperAdmin(adminStatus)) {
    if (isSuperAdmin()) {
      currentQuestion.current = adminAnswersList.current;
    }
    questionNo.current = data.questionNo;
    setAnswerType(data.question.Type);
    startingDate.current = data.startDate;
    resetValues();
  };

  const resetValues = async () => {
    playersAnswers.current = [];
    setAnsweredPlayers((prev) => 0);
    setTotalPlayers((prev) => 0);
    setTime(collection?.Time || 10);
    totalTime.current = collection?.Time || 10;
    pauseTime.current = 0;
    if (changeGameScreenState)
      changeGameScreenState({
        isDoubleTimeActive: false,
        isPause: false,
      });
    setProgress(0);
    setPopup({ value: "", color: "" });
    setIsSubmit(false);
    showBanner(false);
    setCorrectAnswer([]);
    isStepFreeze.current = false;
    stopTimer(false);
    setRevealAnswer(false);
    await delay(6000);
    fetchOnlinePlayers();
  };

  const submitAnswerEvent = (data: ISubmitAnswer) => {
    if (isStepFreeze.current) return;
    playersAnswers.current = [
      ...playersAnswers.current,
      { ...data, receivedTime: new Date().toISOString() },
    ];
    setAnsweredPlayers((prev) =>
      matchSubmittedAnswerPlayers(readyPlayersRef.current)
    );
    const playersAnswerStatus = checkAllUsersAnswered();
    if (playersAnswerStatus) {
      setTimerUp(true);
    }
  };
  const checkAllUsersAnswered = () => {
    if (
      readyPlayersRef &&
      readyPlayersRef.current &&
      readyPlayersRef.current.length > 0
    ) {
      let gamePlayers = [...readyPlayersRef.current];
      if (checkSuperAdmin(adminStatus)) {
        const players = readyPlayersRef.current?.filter(
          (player) => !player.isSuperAdmin
        );
        gamePlayers = [...players];
      }

      return gamePlayers?.every((player) => {
        return playersAnswers.current.some(
          (answer) => answer.playerId === player.id
        );
      });
    }
    return false;
  };

  const filterAdminStatusAnswer = (answers: IAnswers[]) => {
    const findAdmin = dbPlayersRef.current?.filter(
      (player) => player.isAdmin && player.isAdminActive && player.isSuperAdmin
    );
    const updatedAnswers = answers.filter((ans) => {
      return !findAdmin?.some((admin) => ans.playerId === admin.id);
    });
    return updatedAnswers;
  };
  const addTimeUpPlayers = () => {
    readyPlayersRef.current.forEach((player) => {
      const answerExist = playersAnswers.current.some(
        (answer) => answer.playerId === player.id
      );
      if (!answerExist) {
        playersAnswers.current = [
          ...playersAnswers.current,
          {
            playerId: player.id as string,
            playerName: player.PlayerName || "",
            answers: [],
            isCorrect: false,
          },
        ];
      }
    });
  };

  const transformToSubmitAnswer = () => {
    addTimeUpPlayers();
    const answers = playersAnswers.current.map((answer) => {
      return {
        answer: answer.answers,
        playerId: answer.playerId,
        playerName: answer.playerName,
        receivedTime: answer.receivedTime,
        reactionTime: answer.reactionTime,
        powerUp: requestedPower.current[answer.playerId] || [],
      };
    });

    const updatedAnswers = filterAdminStatusAnswer(answers);
    return {
      questionId: currentQuestion.current.id,
      collectionId: collection.id,
      triviaId: routeState.triviaId,
      answers: updatedAnswers,
    };
  };
  const sendAnswerResponseToUsers = async () => {
    try {
      isStepFreeze.current = true;
      await delay(ADMIN_SUBMISSION_DELAY);
      await NetworkManager.SubmitAnswer(transformToSubmitAnswer());
      answerResponseSended();
    } catch (err) {
      console.log("Error while sending responses to Users:", err);
      // logoutUser();
    }
  };

  const answerResponseSended = () => {
    if (setAdminState) {
      const currentRoundResult = [
        ...(roundResult || []),
        {
          questionNo: questionNo.current,
          answers: [...playersAnswers.current],
        },
      ];
      setAdminState({
        roundResult: currentRoundResult,
      });
      setTimeout(() => {
        console.log("Triggering next question");
        triggerMoveToNextQuestion();
      }, 5000);
    }
  };

  const roundEndScreen = () => {
    const path =
      selectedCollections && routeState.round < selectedCollections?.length
        ? ROUND_END_ROUTE
        : GAME_END_ROUTE;
    return path;
  };
  const triggerRoundEndEvent = async () => {
    try {
      const { body } = await NetworkManager.SubmitRound({
        triviaId: routeState.triviaId,
        collectionId: collection.id,
      });
      const players = body.playerRoundResult;
      const channel: string = getChannel(gameCode);
      await NetworkManager.TriggerPusherEvent(ROUND_ENDED, channel, {
        path: roundEndScreen(),
        players: getRoundPlayers(players),
      });
    } catch (err) {
      console.log("Error while ending round:", err);
      // logoutUser();
    }
  };
  const onRoundEndEvent = (data: any) => {
    const { players, path } = data;
    history.replace({
      pathname: path,
      state: {
        ...routeState,
        players: players,
      },
    });
  };
  const triggerMoveToNextQuestion = async () => {
    const nextQuestion = questionNo.current + 1;
    if (currentRoundQuestion && nextQuestion < currentRoundQuestion.length) {
      const channel: string = getChannel(gameCode);
      adminAnswersList.current = currentRoundQuestion[nextQuestion];
      const newQuestion = removeCorrectField(
        currentRoundQuestion[nextQuestion]
      );
      const availablePowerUps = {
        fiftyFifty: isFiftyFiftyAvailable(currentRoundQuestion[nextQuestion]),
        sabotage: false,
        doublePoints: false,
        doubleTime: false,
      };

      await NetworkManager.TriggerPusherEvent(MOVE_TO_QUESTION, channel, {
        ...routeState,
        question: newQuestion,
        questionNo: nextQuestion,
        startDate: addSeconds(new Date(), 0).getTime(),
        adminId: adminId,
        availablePowerUps,
      });
    } else {
      triggerRoundEndEvent();
    }
  };
  const triggerSabotageEvent = (selectedPlayer: TTriviaPlayer) => {
    const eventData = { power: PowerUp.Sabotage };
    if (selectedPlayer?.id === getPlayer()) {
      applyReceivedPowersEvent(eventData);
    } else {
      const channel: string = getChannel(gameCode);
      const selectedEvent = PlayerEvent(selectedPlayer?.id || "");
      triggerClientEvent(channel, selectedEvent, eventData);
    }
  };
  const sendSabotagePowerUpResponse = (data: any) => {
    addReceivedPowerUp(data);
    let otherPlayers = readyPlayersRef?.current?.filter(
      (player) => player?.id !== data?.playerId
    );
    if (checkSuperAdmin(adminStatus)) {
      otherPlayers = otherPlayers.filter((player) => !player.isSuperAdmin);
    }
    const randomPlayer = getRandomPlayer(otherPlayers);
    triggerSabotageEvent(randomPlayer);
  };
  const addReceivedPowerUp = (data: any) => {
    const playerPower = requestedPower?.current[data.playerId];
    if (!playerPower) {
      requestedPower.current[data.playerId] = [data.power];
    } else {
      const oldPowers = requestedPower.current[data.playerId];
      oldPowers.push(data.power);
    }
  };
  const sendDoublePointsPowerUpResponse = (data: any) => {
    addReceivedPowerUp(data);
  };
  const sendFiftyFiftyPowerUpResponse = (data: any) => {
    addReceivedPowerUp(data);

    const eventData = {
      power: PowerUp.FiftyFifty,
      question: adminAnswersList?.current,
    };
    if (data.playerId === getPlayer()) {
      applyFiftyFiftyEvent(eventData);
    } else {
      const channel: string = getChannel(gameCode);
      const targetPlayer = `client-${data.playerId}`;
      triggerClientEvent(channel, targetPlayer, eventData);
    }
  };
  const triggerDoubleTimeEvent = (data: any, eventData: any) => {
    if (data.playerId === getPlayer()) {
      applyDoubleTimeEvent(eventData);
    } else {
      const channel: string = getChannel(gameCode);
      const targetPlayer = `client-${data.playerId}`;
      triggerClientEvent(channel, targetPlayer, eventData);
    }
  };
  const sendDoubleTimePowerUpResponse = (data: any) => {
    addReceivedPowerUp(data);
    if (changeGameScreenState) {
      changeGameScreenState({ isDoubleTimeActive: true });
    }
    triggerDoubleTimeEvent(data, {
      power: PowerUp.DoubleTime,
    });
  };

  const applyDoubleTimeEvent = (data: any) => {
    totalTime.current = totalTime.current * 2;
  };

  const onAdminReceivedPowerUpRequest = (data: any) => {
    switch (data.power) {
      case PowerUp.Sabotage:
        sendSabotagePowerUpResponse(data);
        break;
      case PowerUp.DoublePoint:
        sendDoublePointsPowerUpResponse(data);
        break;
      case PowerUp.FiftyFifty:
        sendFiftyFiftyPowerUpResponse(data);
        break;
      case PowerUp.DoubleTime:
        sendDoubleTimePowerUpResponse(data);
        break;
    }
  };
  const applyFiftyFiftyEvent = (data: any) => {
    setCorrectAnswer([]);
    currentQuestion.current = generateFiftyFiftyAnswers(data.question);
    applyReceivedPowersEvent(data);
  };
  const applyReceivedPowersEvent = (data: any) => {
    if (setGameScreenState) {
      setGameScreenState((prev) => {
        return {
          ...prev,
          receivedPowers: [...(prev.receivedPowers || []), data?.power],
        };
      });
    }
  };
  const checkReceivedEventType = (data: any) => {
    switch (data.power) {
      case PowerUp.Sabotage:
        applyReceivedPowersEvent(data);
        break;
      case PowerUp.FiftyFifty:
        applyFiftyFiftyEvent(data);
        break;
      case PowerUp.DoubleTime:
        applyDoubleTimeEvent(data);
        break;
    }
  };

  const getPusherEvent = () => {
    let events: string[] = [
      MOVE_TO_QUESTION,
      ROUND_ENDED,
      ANSWER_RESPONSE,
      PAUSE_GAME,
      powerUpResponse(),
    ];
    if (isSuperAdmin()) {
      return [...events, SUBMIT_ANSWER, POWER_UP_REQUEST];
    }
    return [...events];
  };
  const onPusherEventTrigger = (eventName: string, data: any) => {
    switch (eventName) {
      case POWER_UP_REQUEST:
        console.log("POWER UP Request", data);
        onAdminReceivedPowerUpRequest(data);
        break;
      case powerUpResponse():
        console.log("POWER UP RESPONSE", data);
        checkReceivedEventType(data);
        break;
      case PAUSE_GAME:
        console.log("Pause/UnPause Event", data);
        if (pauseScreenEvent) pauseScreenEvent(data);
        break;
      case MOVE_TO_QUESTION: //client request to server
        // after ANSWER_RESPONSE player will receive question
        console.log("Move To Question:", data);
        stopTimer(true);
        moveToQuestionEvent(data);
        break;
      case ANSWER_RESPONSE: //server
        // player receive the response of submitted answer
        console.log("Answer Response:", data);
        showAnswerStatusBanner(data);
        break;
      case SUBMIT_ANSWER: //client
        //admin receive answer of a question from player
        console.log("Answer Submitted:", data);
        submitAnswerEvent(data);
        break;
      case ROUND_ENDED: // client request to server
        console.log("Round Ended:", data);
        onRoundEndEvent(data);
        break;
    }
  };

  const timerCallback = (remainingTime: number, progress: number) => {
    if (isPause) return;
    if (!isSubmit) {
      setTime(remainingTime);
      setProgress(progress);
    }
    if (remainingTime === 0) {
      timeUpCase();
    }
  };

  const setInputValues = () => {
    if (correctChecked) {
      const correctValue = currentQuestion.current.Answers;
      if (correctValue && correctValue.length > 0) {
        const answers = correctValue[0]?.Answer;
        if (answers && answers.length > 0) return answers[0];
      }
    } else {
      if (correctAnswer?.length > 0) {
        return correctAnswer[0];
      }
    }
  };

  const selectAnswerContainer = () => {
    const data = mappedData();
    return (
      <div className={classes["selectAnswerContainer"]}>
        {data?.map((item, index) => {
          return (
            <div
              className={classes["answerWrapper"]}
              key={index}
              style={{
                cursor: checkSuperAdmin(adminStatus) ? "default" : "pointer",
              }}
            >
              <SelectAnswer
                showCheckbox={checkSuperAdmin(adminStatus) || correctChecked}
                color={item.color}
                isDisable={
                  checkSuperAdmin(adminStatus)
                    ? true
                    : handleDisable(item.value) || isSubmit
                }
                selected={
                  correctChecked ? false : checkSelectedAnswer(item.value || "")
                }
                style={{
                  backgroundColor: backgroundColor(
                    correctAnswer[0],
                    item.value,
                    answerType || "",
                    correctChecked
                  ),
                  opacity: checkOpacity(
                    item.submit || false,
                    correctChecked,
                    checkSuperAdmin(adminStatus) ? false : true,
                    revealAnswer,
                    applyFiftyFiftyPowerUp(
                      checkPowerApplied(
                        receivedPowers || [],
                        PowerUp.FiftyFifty
                      ),
                      item
                    )
                  ),
                }}
                onClick={() => handleAnswerClick(item.value || "")}
                data={item}
              />
            </div>
          );
        })}
      </div>
    );
  };
  const InActiveAdminInputAnswers = () => {
    let answers: (string | null)[] | undefined = [];
    const correctValue = currentQuestion.current.Answers;
    if (correctValue && correctValue.length > 0) {
      answers = correctValue[0]?.Answer;
    }
    return answers;
  };
  const InActiveAdminInputValues = () => {
    const answers = InActiveAdminInputAnswers();

    return (
      <div className={classes["inActiveAdminAnswerContainer"]}>
        {answers?.map((answer, index) => {
          return (
            <PlainText key={index} className={classes["inActiveAdminAnswer"]}>
              {answer}
            </PlainText>
          );
        })}
      </div>
    );
  };
  const inputAnswerContainer = () => {
    if (checkSuperAdmin(adminStatus)) {
      if (revealAnswer || correctChecked) {
        return InActiveAdminInputValues();
      } else {
        return <div style={{ width: "80%" }} />;
      }
    } else {
      return (
        <div className={classes["inputAnswerContainer"]}>
          <PlainText
            className={classes["inputAnswerTitle"]}
            style={{ opacity: correctChecked ? 0 : 1 }}
          >
            YOUR ANSWER
          </PlainText>
          <InputField
            className={`${classes["InputField"]} ${checkPowerApplied(
              receivedPowers,
              PowerUp.Sabotage
            ) && classes["sabotage"]}`}
            name={"inputAnswerScreen"}
            id={"inputAnswerScreen"}
            type={"text"}
            maxLength={100}
            placeholder={"START TYPING"}
            value={setInputValues() || ""}
            onValueChanged={(e) => {
              handleAnswerClick(e.target.value);
            }}
            disabled={isSubmit}
            onKeyDown={onKeyDownEvent}
          />
        </div>
      );
    }
  };

  const renderSubmitButton = () => {
    if (checkSuperAdmin(adminStatus) && (revealAnswer || correctChecked)) {
      if (
        answerType === EQuestionType["Single-Select"] ||
        answerType === EQuestionType["Multi-Select"]
      ) {
        return <div className={classes["inActiveSubmit"]} />;
      }
    } else {
      return (
        <button
          style={{ border: "none" }}
          disabled={
            checkSuperAdmin(adminStatus)
              ? false
              : isSubmit || handleSubmitButtonDisable()
          }
          className={classes["submitButtonContainer"]}
          onClick={handleSubmit}
        >
          {getSubmitButton(isSubmit, handleSubmitButtonDisable(), adminStatus)}
        </button>
      );
    }
  };
  const renderPauseScreen = () => {
    return (
      <div className={classes["pauseContainer"]}>
        <BoldText className={classes["pauseTitle"]}>TRIVIA PAUSED...</BoldText>
        <img
          src={"/assests/Icons/pauseImg.svg"}
          alt={"icon"}
          className={classes["pauseImage"]}
        />
        <BoldText className={classes["pauseDescription"]}>
          Hold tight and wait for the quizmaster to unpause the game.
        </BoldText>
      </div>
    );
  };

  const gameComponent = () => {
    return (
      <header className={classes["menuHeaderV2"]}>
        <div className={classes["gameBodyV2"]}>
          <div className={classes["headerSectionV2"]}>
            <div className={classes["headerContainerV2"]}>
              <NeonText
                size="2.3125em"
                className={classes["headerTextV2"]}
              >{`QUESTION ${questionNo.current + 1}`}</NeonText>
              <NeonText
                className={classes["headerTextV2"]}
                size="2.3125em"
              >{`${checkQuestionType(answerType)}`}</NeonText>
            </div>
          </div>
          {isPause && renderPauseScreen()}
          <div
            className={classes["GameQuestionSectionV2"]}
            style={{ display: !isPause ? "flex" : "none" }}
          >
            <PlainText
              className={`${classes["questionV2"]} ${checkPowerApplied(
                receivedPowers,
                PowerUp.Sabotage
              ) && classes["sabotage"]}`}
              size={"3.5em"}
              style={
                currentQuestion?.current?.Media || routeState?.hasPowerUps
                  ? { lineHeight: "55px" }
                  : {
                      width: "100%",
                      lineHeight: "50px",
                    }
              }
            >
              <span>{currentQuestion?.current?.Description}</span>
            </PlainText>
            {currentQuestion.current?.Media ? (
              <div className={classes["imageContainerV2"]}>
                {IsImage(currentQuestion?.current?.Media) ? (
                  <CustomS3Image
                    s3Key={currentQuestion?.current?.Media}
                    className={classes["questionImageV2"]}
                    loader={false}
                  />
                ) : (
                  <Recording
                    header="AUDIO CHALLENGE"
                    path={currentQuestion.current?.Media}
                    hasSlider
                    isSubmit={correctChecked}
                  />
                )}
              </div>
            ) : (
              <div className={classes["imagePlaceholderV2"]} />
            )}
            {/* Power up section */}
            {routeState?.hasPowerUps && (
              <div className={classes["powerUpAreaV2"]}>
                {checkSuperAdmin(adminStatus) ? null : (
                  <PowerUpsSection
                    isDisable={isSubmit || time <= 5}
                    handleSabotagePowerUpResponse={sendSabotagePowerUpResponse}
                    handleDoublePointsPowerUpResponse={
                      sendDoublePointsPowerUpResponse
                    }
                    handleFiftyFiftyPowerUpResponse={
                      sendFiftyFiftyPowerUpResponse
                    }
                    handleDoubleTimePowerUpResponse={
                      sendDoubleTimePowerUpResponse
                    }
                  />
                )}
              </div>
            )}
          </div>
          <div
            className={classes["AnswersSectionV2"]}
            style={{ display: !isPause ? "flex" : "none" }}
          >
            <div className={classes["bodyContainerBottomV2"]}>
              {hasBanner ? (
                <HeaderSlot
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    backgroundColor: popup.color,
                  }}
                >
                  {popup.value}
                  {/* TIME'S UP!! */}
                </HeaderSlot>
              ) : (
                <div className={`${classes["barContainerV2"]} `}>
                  <div className={`${classes["countDownContainerV2"]} mb-2`}>
                    <BoldText
                      className={`${classes["countDownV2"]} pl-3`}
                      style={{
                        opacity: checkSuperAdmin(adminStatus) ? 1 : 0,
                      }}
                    >
                      {`${answeredPlayers}/${totalPlayers - 1}`} PLAYERS
                      ANSWERED
                    </BoldText>
                    <BoldText className={`${classes["countDownV2"]}`}>
                      TIME LEFT: {styleTime(Math.ceil(time))}
                    </BoldText>
                  </div>
                  <ProgressBar percentage={progress}></ProgressBar>
                </div>
              )}
              <div className={classes["answersFooterV2"]}>
                {answerType === EQuestionType["Single-Select"] ||
                answerType === EQuestionType["Multi-Select"]
                  ? selectAnswerContainer()
                  : inputAnswerContainer()}
                {renderSubmitButton()}
              </div>
            </div>
          </div>
        </div>
      </header>
    );
  };
  return (
    <div>
      <PusherSubscription
        channel={getChannel(gameCode)}
        events={getPusherEvent()}
        callback={onPusherEventTrigger}
      />
      <PusherSubscription
        channel={`${gameCode}-game-running`}
        events={[]}
        callback={() => {}}
      />
      {!isTimerStop ? (
        <Timer
          totalTime={totalTime.current}
          startDate={startingDate.current}
          callback={timerCallback}
        />
      ) : null}
      {isLoading ? (
        <CircularSpinner backdropAlpha={false}></CircularSpinner>
      ) : null}
      {gameComponent()}
    </div>
  );
};
export default GameScreen;

const backgroundColor = (
  correctAnswer: string,
  value: string | undefined,
  answerType: string,
  correctChecked: boolean
) => {
  if (answerType === "Single Select" && !correctChecked) {
    if (correctAnswer === value || !correctAnswer) {
      return WHITE;
    } else {
      return "#575757";
    }
  }
  return;
};

const checkOpacity = (
  value: boolean,
  correctChecked: Boolean,
  adminStatus: boolean | undefined,
  answerReveal: boolean,
  powerApplied: boolean
) => {
  if (powerApplied) {
    return 0;
  } else {
    if (!adminStatus && answerReveal) {
      return checkOpacityValue(value);
    } else {
      if (correctChecked) {
        return checkOpacityValue(value);
      }
    }
  }
  return 1;
};
const checkOpacityValue = (value: boolean) => {
  if (value) {
    return 1;
  } else {
    return 0;
  }
};

const getSubmitButton = (
  isSubmit: boolean,
  isAnswerSelected: boolean,
  adminStatus: boolean | undefined
) => {
  const checkText = () => {
    if (checkSuperAdmin(adminStatus)) {
      return "REVEAL ANSWER";
    } else if (isSubmit) {
      return "PLEASE WAIT";
    } else {
      return "SUBMIT";
    }
  };

  const text = checkText();
  isSubmit = isSubmit || isAnswerSelected;
  const color = !isSubmit ? "#e80f0f" : "#575757";
  const checkCursor = () => {
    if (checkSuperAdmin(adminStatus)) {
      return "pointer";
    } else if (isSubmit) {
      return "default";
    } else {
      return "pointer";
    }
  };
  return (
    <div
      className={classes["submitButtonContainer"]}
      // style={{ cursor: isSubmit ? "default" : "pointer" }}
      style={{ cursor: checkCursor() }}
    >
      <div
        style={{
          backgroundColor: checkSuperAdmin(adminStatus) ? "#25AD9E" : color,
        }}
        className={classes["submitButtonSubContainer"]}
      >
        <p>{text}</p>
      </div>
    </div>
  );
};

export const OnAdminRefresh = async (
  history: any,
  setGameState: ((data: IGameState) => void) | undefined,
  resetAdmin: (() => void) | undefined
) => {
  try {
    const playerId = getPlayer() || "";
    if (playerId && setGameState) {
      if (resetAdmin) {
        resetAdmin();
      }
      await NetworkManager.UpdatePlayer(playerId, {
        id: playerId,
        isReady: false,
      });
      SessionStorage.RemoveItem(PLAYER_ID);
    }
    LocalStorage.SetItem(RELOAD_STATUS, "true");
    SessionStorage.SetItem(WINDOW_RELOAD, "true");
    history.replace(MAIN_MENU_ROUTE);
  } catch (err) {
    console.log("Error during exiting game");
  }
};

const powerUpResponse = () => {
  return `client-${getPlayer()}`;
};

const PlayerEvent = (id: string) => {
  return `client-${id}`;
};
