export interface AuthInterface {
  isAuthenticated: boolean;
  isAdminAuthenticated: boolean;
  currentOnlineUser: number;
  errorMessage: Message;

  authenticateUser: (token: string) => void;
  authenticateAdmin: (token: string) => void;
  logoutUser: () => void;
  checkAuthentication: () => boolean;
  logoutAdmin: () => void;
  checkAdminAuthentication: () => boolean;
  UPDATE_ONLINE_USER: (totalUserOnline: number) => void;
  setErrorMessage: (error: Message) => void;
}

export const defaultAuthContext: AuthInterface = {
  isAuthenticated: false,
  isAdminAuthenticated: false,
  currentOnlineUser: 0,
  errorMessage: {
    value: "",
    color: "",
  },
  authenticateUser: (token: string) => {},
  authenticateAdmin: (token: string) => {},
  logoutUser: () => {},
  checkAuthentication: () => false,
  logoutAdmin: () => {},
  checkAdminAuthentication: () => false,
  UPDATE_ONLINE_USER: (totalUserOnline: number) => {},
  setErrorMessage: (error: Message) => {},
};

export interface AuthStateInterface {
  isAuthenticated: boolean;
  token: string;
  isAdminAuthenticated: boolean;
  adminToken: string;
  currentOnlineUser: number;
  errorMessage: Message;
}
export interface Message {
  value?: string;
  color?: string;
}
