import React from "react";
import classes from "./SmallToggle.module.scss";

interface IToggle {
  id?: string;
  checked?: boolean;
  onChange: (value: boolean) => void;
  name?: string;
  optionLabels?: string[];
  small?: boolean;
  disabled?: boolean;
  toggleType?: string;
}

const SmallToggleSwitch: React.FC<IToggle> = ({
  id = "0",
  name,
  checked,
  onChange,
  optionLabels = ["NOT READY", "READY"],
  small,
  disabled,
  toggleType = "Primary",
}) => {
  const handleKeyPress = React.useCallback(
    (e: any) => {
      if (e.keyCode !== 32) return;
      console.log("checked", checked);
      onChange(!checked);
    },
    [checked] // eslint-disable-line react-hooks/exhaustive-deps
  );

  return (
    <div className={classes["toggle-switch" + (small ? " small-switch" : "")]}>
      <input
        type="checkbox"
        name={name}
        className={classes["toggle-switch-checkbox"]}
        id={id}
        checked={checked}
        onChange={(e) => onChange(e.target.checked)}
        disabled={disabled}
      />
      {id ? (
        <label
          className={classes["toggle-switch-label"]}
          tabIndex={disabled ? -1 : 1}
          onKeyDown={(e) => handleKeyPress(e)}
          htmlFor={id}
        >
          <span
            className={
              disabled
                ? classes["toggle-switch-inner toggle-switch-disabled"]
                : `${classes["toggle-switch-inner"]} ${toggleType ===
                    "Secondary" && classes["toggle-switch-inner--modifier"]}`
            }
            data-yes={optionLabels[0]}
            data-no={optionLabels[1]}
            tabIndex={-1}
          />
          <span
            className={
              disabled
                ? classes["toggle-switch-switch toggle-switch-disabled"]
                : classes["toggle-switch-switch"]
            }
            tabIndex={-1}
          />
        </label>
      ) : null}
    </div>
  );
};

export default SmallToggleSwitch;
