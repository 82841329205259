/** @format */

import React from "react";
import { PowerUp } from "../../API";
import { useGameScreenContext } from "../../context/GameScreen/GameScreenContainer";
import { SliceString } from "../../utilities/util";
import { checkPowerApplied } from "../gameScreenHelper/gameScreenUtils/gameScreenUtils";
import { PlainText } from "../Text/Text";
import Checkbox from "../Trivia/Checkbox/Checkbox";
import classes from "./SelectAnswer.module.scss";
interface ISelectAnswer {
  data: any;
  style?: any;
  onClick?: any;
  selected?: Boolean;
  isDisable?: boolean;
  color?: string;
  showCheckbox?: boolean;
}

const SelectAnswer: React.FC<ISelectAnswer> = ({
  data,
  style,
  onClick,
  selected,
  color,
  isDisable = false,
  showCheckbox,
}) => {
  const { receivedPowers } = useGameScreenContext();
  return (
    <div
      className={classes["container"]}
      onClick={onClick}
      style={{
        ...style,
        pointerEvents: isDisable ? "none" : "all",
      }}
    >
      <div className={classes["leftContainer"]}>
        <PlainText
          size={"4.125em"}
          className={classes["option"]}
          style={{
            color: color,
            fontWeight: 800,
          }}
        >
          {data.option}.
        </PlainText>
        <PlainText
          size={data.value.length < 40 ? "2.25em" : "2em"}
          className={`${classes["title"]} ${checkPowerApplied(
            receivedPowers || [],
            PowerUp.Sabotage
          ) && classes["sabotage"]}`}
          style={{
            fontWeight: 800,
            lineHeight: "20px",
            marginTop: "20px",
            maxHeight: "80px",
          }}
        >
          {SliceString(data.value, 100)}
        </PlainText>
      </div>

      {!showCheckbox && (
        <Checkbox
          defaultChecked={selected ? true : false}
          isDisable
          className={classes["checkbox"]}
          tickHeight={"48"}
          tickWidth={"61"}
          color="#000"
          tickStyles={classes["tick"]}
        />
      )}
    </div>
  );
};
export default SelectAnswer;
