import React from "react";

interface IText extends React.HTMLAttributes<HTMLElement> {
  size?: string;
}

const defaultPlainTextProps: IText = {
  size: "1em",
};
export const PlainText: React.FC<IText> = ({
  className,
  size,
  children,
  style,
  ...rest
}) => {
  return (
    <span
      className={className}
      style={{
        fontSize: size,
        fontFamily: "SegoeUI",
        ...style,
      }}
      {...rest}
    >
      {children}
    </span>
  );
};
PlainText.defaultProps = defaultPlainTextProps;
export const BoldText: React.FC<IText> = ({
  className,
  size,
  children,
  style,
}) => {
  return (
    <span
      className={className}
      style={{
        fontSize: size,
        color: "#222027",
        fontFamily: "Bebas Neue Pro",
        ...style,
      }}
    >
      {children}
    </span>
  );
};

BoldText.defaultProps = defaultPlainTextProps;

export const NeonText: React.FC<IText> = ({
  className,
  size,
  children,
  style,
}) => {
  return (
    <span
      className={className}
      style={{
        fontSize: size,
        fontFamily: "Neon Light",
        ...style,
        paddingBottom: "15px",
      }}
    >
      {children}
    </span>
  );
};

NeonText.defaultProps = defaultPlainTextProps;

export const HarlowText: React.FC<IText> = ({
  className,
  size,
  children,
  style,
}) => {
  return (
    <span
      className={className}
      style={{
        fontSize: size,
        fontFamily: "Harlow Regular",
        ...style,
        paddingBottom: "15px",
      }}
    >
      {children}
    </span>
  );
};

HarlowText.defaultProps = defaultPlainTextProps;
