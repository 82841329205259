import React, { Fragment, useContext, useState, useEffect } from "react";
import { withRouter, RouteComponentProps, useHistory } from "react-router";
import "./navbar.scss";
import { PopupContext } from "../../context/Popup/PopupContext";
import Logo from "../../assets/Logo/Trivia_Logo.png";
import HQ_Logo from "../../assets/Logo/HQ_LOGO.png";
import { useAuthContext } from "../../context/Auth/AuthContainer";
import { DARK_GREEN, FLUORESCENT_BLUE, YELLOW } from "../../color";
import MessageSlot from "../Trivia/MessageSlot/MessageSlot";
import {
  LOGIN_ROUTE,
  MAIN_MENU_ROUTE,
  RESUME_GAME_RULES,
  GAME_LOBBY,
  ROUND_SCREEN_ROUTE,
  GAME_SCREEN,
  ROUND_END_ROUTE,
  GAME_END_ROUTE,
  GAME_INTRO,
  SELECT_COLLECTION,
  TRIVIA_GAMES,
  TRIVIA_RESULTS,
  MANAGE_PLAYER_COLLECTION,
  JOINING,
  POWER_UPS,
} from "../../containers/routes/RoutesConstants";
import {
  Clamp,
  getPlayer,
  isAdminRefreshed,
  removeAdminRefreshStatus,
} from "../../utilities/util";
import { useGameStateContext } from "../../context/GameState/GameStateContainer";
import NetworkManager from "../../services/NetworkManager";
import { useGameAdminContext } from "../../context/GameAdmin/GameAdminContainer";
import PusherSubscriptions, { isGamePages } from "../pusher/bindChannel";
import MessageBox from "../messagebox/messagebox";
import { EGameCompany, EMessageType } from "../../containers/Enums/Enums";
import SessionStorage from "../../services/SessionStorage";
import {
  GAME_COMPANY,
  PLAYER_ID,
  RELOAD_STATUS,
  WINDOW_RELOAD,
} from "../../constants";
import SettingsPopUp from "../../containers/SettingsPopUp/SettingsPopUp";
import { useMusicContext } from "../../context/Music/MusicContainer";
import { CircularSpinner } from "../Spinner/Spinner";
import Button from "../Trivia/Button/Button";
import LocalStorage from "../../services/LocalStorage";
import ReloadAlert from "../alerts/reloadAlert";
import BackAlert from "../alerts/backAlert";
import PauseButton from "./navbarComponents/pauseButton";

const Navbar: React.FC<RouteComponentProps> = ({ history }) => {
  const { showPopup, popup } = useContext(PopupContext);
  const { logoutUser, currentOnlineUser } = useAuthContext();
  const { setGameState } = useGameStateContext();
  const { resetAdmin } = useGameAdminContext();
  const pageHistory = useHistory();
  const [controlButtons, setControlButtons] = useState({
    hideButton: true,
    hideLogo: false,
    connection: false,
    showConnection: true,
  });
  const { resetAudio, resetSFXAudio } = useMusicContext();
  const [isLoading, setIsLoading] = React.useState<boolean>(false);

  const [logoutText, setLogoutText] = useState<string>("LOG OUT");
  let currentPage = pageHistory?.location?.pathname;

  useEffect(() => {
    if (isAdminRefreshed()) {
      showPopup(<AdminLeavePopup />);
      removeAdminRefreshStatus();
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    switch (currentPage) {
      case LOGIN_ROUTE:
        resetAudio();
        resetSFXAudio();
        setControlButtons({
          ...controlButtons,
          hideLogo: true,
          hideButton: true,
          showConnection: false,
        });
        break;
      case MAIN_MENU_ROUTE:
        setControlButtons({
          ...controlButtons,
          hideLogo: false,
          hideButton: false,
          showConnection: true,
        });
        setLogoutText("LOG OUT");
        break;
      case GAME_INTRO:
      case POWER_UPS:
      case RESUME_GAME_RULES:
      case GAME_LOBBY:
      case ROUND_SCREEN_ROUTE:
      case GAME_SCREEN:
      case ROUND_END_ROUTE:
      case GAME_END_ROUTE:
      case SELECT_COLLECTION:
      case JOINING:
        setControlButtons({
          ...controlButtons,
          hideButton: false,
          showConnection: true,
        });
        setLogoutText("EXIT GAME");
        break;
      case TRIVIA_GAMES:
      case TRIVIA_RESULTS:
      case MANAGE_PLAYER_COLLECTION:
        setControlButtons({
          ...controlButtons,
          hideLogo: false,
          hideButton: false,
          showConnection: true,
        });
        setLogoutText("LOG OUT");
        break;

      default:
        setControlButtons({
          ...controlButtons,
          hideLogo: false,
          hideButton: true,
          showConnection: false,
        });
        setLogoutText("LOG OUT");
        break;
    }
    return () => {
      setControlButtons({
        ...controlButtons,
        hideButton: true,
        hideLogo: false,
        showConnection: false,
      });
    };
  }, [currentPage]); // eslint-disable-line react-hooks/exhaustive-deps

  const onLogoutButtonClicked = () => {
    console.log("logoutText", logoutText);
    switch (logoutText) {
      case "LOG OUT":
        doLogout();
        break;
      default:
        onExitButtonClicked();
        break;
    }
  };

  const doLogout = () => {
    logoutUser();
    if (setGameState) {
      setGameState({
        dbPlayers: [],
        gameCode: "",
      });
    }
    // history.replace(LOGIN_ROUTE);
  };
  const onExitButtonClicked = async () => {
    setIsLoading(true);
    try {
      const playerId = getPlayer() || "";
      if (playerId && setGameState) {
        resetAdminState();
        await NetworkManager.UpdatePlayer(playerId, {
          id: playerId,
          isReady: false,
        });
        SessionStorage.RemoveItem(PLAYER_ID);
      }
      moveToMainMenu();
    } catch (err) {
      moveToMainMenu();
      console.log("Error during exiting game");
    }
    setIsLoading(false);
  };
  const moveToMainMenu = () => {
    if (isGamePages()) {
      LocalStorage.SetItem(RELOAD_STATUS, "true");
      SessionStorage.SetItem(WINDOW_RELOAD, "true");
      history.replace(MAIN_MENU_ROUTE);
    } else {
      history.replace(MAIN_MENU_ROUTE);
    }
  };

  const AdminLeavePopup = () => {
    let box = getMessageBox(
      EMessageType.warning,
      "OOPS, SOMETHING WENT WRONG",
      <div
        style={{
          fontSize: "1.75em",
          fontFamily: "SegoeUI",
          marginBottom: "20px",
        }}
      >
        The connection with the Quizmaster was lost.
        <br />
        Your trivia game has ended.
      </div>,
      <Button
        leftArrow={false}
        color={YELLOW}
        style={{
          fontSize: "2.25em",
          paddingLeft: "5px",
          justifyContent: "center",
        }}
        width={200}
        height={50}
        onClick={() => {
          showPopup(null);
        }}
      >
        {"CLOSE"}
      </Button>,
      null,
      "",
      { marginTop: "-5px" }
    );
    return box;
  };

  const resetAdminState = () => {
    if (resetAdmin) {
      resetAdmin();
    }
  };
  const onSettingButtonClicked = () => {
    showPopup(<SettingsPopUp />);
  };

  const getCompanyLogo = (): any => {
    const company = LocalStorage.GetItem(GAME_COMPANY) || "";
    console.log(company);
    switch (company) {
      case EGameCompany.Experios:
        return <img src={Logo} height="28" alt="Logo Experios" />;
      case EGameCompany.EscapeHQ:
        return <img src={HQ_Logo} height="56" alt="Escape HQ" />;
      default:
        return <img src={Logo} height="28" alt="Logo Experios" />;
    }
  };

  const navBarLogo = () => {
    const companyLogo = getCompanyLogo();
    return (
      <span className="custom-margin">
        <div className="navbar-brand "></div>
        {companyLogo === "" ? null : (
          <div className="navbar-brand">{companyLogo}</div>
        )}
      </span>
    );
  };
  const navBarButton = () => {
    return (
      <Fragment>
        {popup ? popup : null}
        <PusherSubscriptions
          onExitButtonClicked={onExitButtonClicked}
        ></PusherSubscriptions>
        <button
          type="button"
          className="navbar-toggler"
          data-toggle="collapse"
          data-target="#navbarCollapse"
          aria-expanded="false"
          aria-controls="navbarCollapse"
        >
          <span className="navbar-toggler-icon"></span>
        </button>

        <div className="collapse navbar-collapse" id="navbarCollapse">
          <div className="navbar-nav ml-auto">
            {/*  add audio */}
            {/* {controlButtons.showAudioControls ? <MusicController /> : null} */}
            {controlButtons?.showConnection ? (
              <div className="nav-item connection-button">
                <MessageSlot
                  style={{
                    color: controlButtons?.connection ? "#fff" : "#222027",
                  }}
                  color={
                    controlButtons?.connection ? "transparent" : DARK_GREEN
                  }
                  className={"connected-users"}
                >
                  {controlButtons?.connection ? (
                    <span></span>
                  ) : (
                    <i
                      className="fal fa-users-class mr-2"
                      style={{
                        fontSize: "1em",
                      }}
                    ></i>
                  )}
                  {Clamp(currentOnlineUser, 0, 100)} CONNECTED
                </MessageSlot>
                {/* <Button width={200} leftArrow={false} color={DARK_GREEN}>
                <i className="fal fa-users-class"></i> &nbsp; CONNECTED
              </Button> */}
              </div>
            ) : null}
          </div>
          <PauseButton controlButtons={controlButtons} />
          {/*setting button  */}

          <div className="navbar-nav ml-2 ">
            {controlButtons?.showConnection ? (
              <div className="nav-item setting-button">
                <MessageSlot
                  style={{
                    color: controlButtons?.connection ? "#fff" : "#222027",
                    cursor: "pointer",
                    width: "100%",
                  }}
                  color={
                    controlButtons?.connection
                      ? "transparent"
                      : FLUORESCENT_BLUE
                  }
                  className={"connected-users"}
                  onClick={() => {
                    onSettingButtonClicked();
                  }}
                >
                  {controlButtons?.connection ? (
                    <span></span>
                  ) : (
                    <i
                      className="fal fa-cog mr-2"
                      style={{
                        fontSize: "1em",
                      }}
                    ></i>
                  )}
                  SETTINGS
                </MessageSlot>
              </div>
            ) : null}
          </div>
          {/* setting */}
          <div className="navbar-nav ml-2 ">
            <div
              className="nav-item logout-button"
              style={{ width: logoutText === "LOG OUT" ? "105px" : "120px" }}
            >
              <MessageSlot
                color={controlButtons?.connection ? "transparent" : YELLOW}
                style={{
                  color: controlButtons?.connection ? "#fff" : "#222027",
                  cursor: "pointer",
                }}
                className={"connected-users"}
                onClick={() => {
                  onLogoutButtonClicked();
                }}
              >
                <i
                  className="far fa-sign-out"
                  style={{
                    fontSize: "1em",
                    paddingRight: "4px",
                  }}
                >
                  {" "}
                </i>
                &nbsp;
                {logoutText || "LOG"}
              </MessageSlot>
            </div>
          </div>
        </div>
      </Fragment>
    );
  };

  return (
    <nav className="navbar navbar-expand-md navbar-dark bg-dark custom mt-2">
      {isLoading ? <CircularSpinner></CircularSpinner> : null}
      <ReloadAlert />
      <BackAlert />
      {controlButtons?.hideLogo ? null : navBarLogo()}
      {controlButtons?.hideButton ? null : navBarButton()}
    </nav>
  );
};

export default withRouter(Navbar);

export const getMessageBox = (
  type: EMessageType,
  title: string,
  message: React.ReactNode,
  button: React.ReactNode,
  button2?: React.ReactNode,
  titleIcon?: string,
  iconStyles?: React.CSSProperties
) => {
  return (
    <MessageBox
      title={title}
      type={type}
      button1={button}
      button2={button2}
      titleIcon={titleIcon}
      iconStyles={iconStyles}
    >
      {message}
    </MessageBox>
  );
};
