import React from "react";
import { BLACK, YELLOW } from "../../../color";

interface IProgressBar extends React.HTMLAttributes<HTMLElement> {
  percentage: number;
  startFrom?: "left" | "right";
}
const ProgressBar: React.FC<IProgressBar> = ({
  className,
  style,
  percentage,
  startFrom = "left",
}) => {
  return (
    <div
      className={className}
      style={{
        width: "100%",
        height: "20px",
        borderRadius: "40px",
        backgroundColor: BLACK,
        position: "relative",
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-start",
        // backgroundImage: `linear-gradient(to right, ${YELLOW} 0%, ${YELLOW} ${20}%, ${BLACK} ${percentage}%, ${BLACK} 100%)`,
        ...style,
      }}
    >
      <div
        style={{
          backgroundColor: YELLOW,
          borderRadius: "40px",
          borderWidth: "2px",
          borderStyle: "solid",
          position: "absolute",
          width: `${percentage}%`,
          opacity: `${percentage < 1 ? 0 : 1}`,
          height: `${"20px"}`,
          ...(startFrom === "left" ? { left: 0 } : { right: 0 }),
        }}
      ></div>
    </div>
  );
};

ProgressBar.defaultProps = {
  percentage: 44,
};
export default ProgressBar;
