import React, { useContext, useState, useEffect } from "react";
import { GAME_CODE } from "../../constants";
import LocalStorage from "../../services/LocalStorage";
import * as Subscriptions from "../../graphql/subscriptions";
import { GameStateContext, GameStateProvider } from "./GameStateContext";
import { defaultGameState, IGameState } from "./GameStateInterface";
import { Subscription } from "../Common/Subscriptions.service";
import { TriviaPlayer } from "../../API";
import { isTriviaMakerRoute, removeItem, updateItem } from "../Common/helper";
import { TTriviaPlayer } from "../../types/ApiTypes";
import NetworkManager from "../../services/NetworkManager";
import { useAuthContext } from "../Auth/AuthContainer";
import { getPlayer, getPusherPlayer } from "../../utilities/util";
import { useGameScreenContext } from "../GameScreen/GameScreenContainer";

const GameStateContainer: React.FC = ({ children }) => {
  const { isAuthenticated } = useAuthContext();
  const [gameState, setGameState] = useState<IGameState>(defaultGameState);
  const { changeGameScreenState } = useGameScreenContext();

  useEffect(() => {
    if (!isAuthenticated) {
      setGameState(defaultGameState);
    }
  }, [isAuthenticated]);
  useEffect(() => {
    if (gameState.gameCode && !isTriviaMakerRoute()) {
      fetchPlayers();
    }
  }, [gameState.gameCode]); // eslint-disable-line react-hooks/exhaustive-deps
  useEffect(() => {
    const code = gameState.gameCode;
    console.log("Mounted Gamestate:", code);
    let createTriviaPlayer: any = null;
    let updateTriviaPlayer: any = null;
    let deleteTriviaPlayer: any = null;
    if (code) {
      createTriviaPlayer = Subscription(
        Subscriptions.onCreatedTriviaPlayer,
        { GameCode: code },
        "onCreatedTriviaPlayer",
        (data: TriviaPlayer) => {
          console.log("Created");
          setGameState((prev) => {
            return {
              ...prev,
              dbPlayers: [...(prev.dbPlayers || []), data],
            };
          });
        }
      );
      updateTriviaPlayer = Subscription(
        Subscriptions.onUpdatedTriviaPlayer,
        { GameCode: code },
        "onUpdatedTriviaPlayer",
        (data: TriviaPlayer) => {
          // if (!data.isAdmin) {
          //   removeAdminId(data);
          // }
          saveAdminId(data);
          setGameState((prev) => {
            return {
              ...prev,
              dbPlayers: [...updateItem(data, prev.dbPlayers || [])],
            };
          });
        }
      );

      deleteTriviaPlayer = Subscription(
        Subscriptions.onDeletedTriviaPlayer,
        { GameCode: code },
        "onDeletedTriviaPlayer",
        (data: TriviaPlayer) => {
          setGameState((prev) => {
            return {
              ...prev,
              dbPlayers: [...removeItem(data.id || "", prev.dbPlayers || [])],
            };
          });
        }
      );
    }
    return () => {
      console.log("Unmounted GameState");
      updateTriviaPlayer?.unsubscribe();
      createTriviaPlayer?.unsubscribe();
      deleteTriviaPlayer?.unsubscribe();
    };
  }, [gameState.gameCode]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      const code = LocalStorage.GetItem(GAME_CODE) || "";
      const playerId = getPlayer() || "";
      const playerPusherId = getPusherPlayer() || "";
      triggerSetGameState({
        gameCode: code,
        playerId: playerId,
        playerPusherId: playerPusherId,
      });
    }
    return () => {
      isMounted = false;
    };
  }, []);

  const triggerSetGameState = (data: IGameState) => {
    setGameState((prev) => {
      return { ...prev, ...data };
    });
  };

  const fetchPlayers = async (): Promise<void> => {
    try {
      const { body } = await NetworkManager.GetAllPlayer();
      const players: TTriviaPlayer[] = body || [];
      searchAndSaveAdmin(players);
      triggerSetGameState({ dbPlayers: [...players] });
    } catch (err) {
      console.log("Error while fetching players");
      NetworkManager.PushError(500, "Fetch Player Error:" + err);
    }
  };

  const setPlayers = async (players: TTriviaPlayer[]): Promise<void> => {
    setGameState((prev) => {
      const dbPlayerId = prev.dbPlayers?.map((player) => player.id) || [];
      const filterPlayers = players.filter(
        (player) => !dbPlayerId.includes(player.id)
      );
      const allPlayers = [...(prev.dbPlayers || []), ...filterPlayers];
      searchAndSaveAdmin(allPlayers);
      return {
        ...prev,
        dbPlayers: [...allPlayers],
      };
    });
  };

  const searchAndSaveAdmin = (players: TTriviaPlayer[]) => {
    players.forEach((player) => {
      saveAdminId(player);
    });
  };
  const saveAdminId = (player: TTriviaPlayer) => {
    if (player.isSuperAdmin) {
      setGameState((prev) => {
        const adminIdsList = [...(prev.adminId || []), player.PusherId || ""];
        let uniqueAdminIds = Array.from(new Set(adminIdsList));
        return {
          ...prev,
          adminId: [...uniqueAdminIds],
        };
      });
    }
  };

  const reset = () => {
    if (changeGameScreenState) {
      changeGameScreenState({ isPause: false });
    }
    triggerSetGameState({ dbPlayers: [], adminId: [] });
  };

  return (
    <GameStateProvider
      value={{
        gameCode: gameState.gameCode,
        playerPusherId: gameState.playerPusherId,
        playerId: gameState.playerId,
        adminId: gameState.adminId,
        dbPlayers: gameState.dbPlayers,
        connectedPlayer: gameState.connectedPlayer,
        isAlreadyLoaded: gameState.isAlreadyLoaded,
        setGameState: triggerSetGameState,
        refetchPlayers: fetchPlayers,
        setPlayers: setPlayers,
        reset,
      }}
    >
      {children}
    </GameStateProvider>
  );
};

export default GameStateContainer;

export const useGameStateContext = () => {
  return useContext(GameStateContext);
};
