import API from "@aws-amplify/api";
const API_NAME = "quizapi";
const HEADERS = {
  headers: {
    "content-type": "application/json",
  },
};

const path = "/v1/reseller";
export default class TriviaMakerManager {
  static GetTriviaDetails() {
    return API.get(API_NAME, `${path}/triviaStats`, {
      ...HEADERS,
    });
  }
  //Category Section
  static CreateCategory(payload: Object) {
    return API.post(API_NAME, `${path}/createCategory`, {
      ...HEADERS,
      body: payload,
    });
  }

  static UpdateCategory(payload: Object) {
    return API.post(API_NAME, `${path}/updateCategory`, {
      ...HEADERS,
      body: payload,
    });
  }

  static DeleteCategory(payload: Object) {
    return API.del(API_NAME, `${path}/deleteCategory`, {
      ...HEADERS,
      body: payload,
    });
  }
  static GetCategoryList() {
    return API.get(API_NAME, `${path}/listCategory`, {
      ...HEADERS,
    });
  }

  //Collection Section
  static CreateCollection(payload: Object) {
    return API.post(API_NAME, `${path}/createCollection`, {
      ...HEADERS,
      body: payload,
    });
  }

  static UpdateCollection(payload: Object) {
    console.log(JSON.stringify(payload));
    return API.post(API_NAME, `${path}/updateCollection`, {
      ...HEADERS,
      body: payload,
    });
  }

  static DeleteCollection(payload: Object) {
    return API.del(API_NAME, `${path}/deleteCollection`, {
      ...HEADERS,
      body: payload,
    });
  }
  static GetCollections(code?: string) {
    const endpoint = `${path}/listCollection/${code}`;
    return API.get(API_NAME, endpoint, {
      ...HEADERS,
    });
  }

  //Question Answer Section
  static CreateQuestion(payload: Object) {
    return API.post(API_NAME, `${path}/createQuestion`, {
      ...HEADERS,
      body: payload,
    });
  }

  static UpdateQuestion(payload: Object) {
    return API.post(API_NAME, `${path}/updateQuestion`, {
      ...HEADERS,
      body: payload,
    });
  }

  static DeleteQuestion(payload: Object) {
    return API.del(API_NAME, `${path}/deleteQuestion`, {
      ...HEADERS,
      body: payload,
    });
  }
  static GetQuestionList(id?: string) {
    const endpoint = id ? `${path}/listQuestion/${id}` : `${path}/listQuestion`;
    return API.get(API_NAME, endpoint, {
      ...HEADERS,
    });
  }
}
