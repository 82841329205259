import { TTriviaPlayer } from "../../types/ApiTypes";

export interface IGameState {
  gameCode?: string;
  playerId?: string;
  playerPusherId?: string;
  adminId?: string[];
  dbPlayers?: TTriviaPlayer[];
  connectedPlayer?: string[];
  isAlreadyLoaded?: boolean;
  setGameState?: (data: IGameState) => void;
  refetchPlayers?: () => Promise<void>;
  setPlayers?: (players: TTriviaPlayer[]) => Promise<void>;
  reset?: () => void;
}

export const defaultGameState: IGameState = {
  gameCode: "",
  playerId: "",
  playerPusherId: "",
  adminId: [],
  dbPlayers: [],
  connectedPlayer: [],
  isAlreadyLoaded: false,
  setGameState: (data: IGameState) => {},
  refetchPlayers: () => Promise.resolve(),
  setPlayers: (players: TTriviaPlayer[]) => Promise.resolve(),
  reset: () => {},
};
