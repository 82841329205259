import React from "react";
import classes from "./HeaderSlot.module.scss";
import { BoldText } from "../../Text/Text";

interface IHeaderSlot extends React.HTMLAttributes<HTMLElement> {
  imgStyle?: any;
  leftIcon?: string;
  textClass?: string;
}
const HeaderSlot: React.FC<IHeaderSlot> = ({
  className,
  style,
  children,
  imgStyle,
  leftIcon,
  textClass,
}) => {
  return (
    <div
      className={`${classes["container"]} ${className}`}
      style={{ ...style }}
    >
      {leftIcon ? (
        <img
          className={classes["leftIcon"]}
          style={{ ...imgStyle }}
          src={leftIcon}
          alt={"icon"}
        />
      ) : (
        ""
      )}

      <BoldText size="2.75em" className={textClass}>
        {children}
      </BoldText>
    </div>
  );
};

export default HeaderSlot;
