import React from "react";
import {
  TriviaMakerInterface,
  defaultTriviaMakerContext,
} from "./TriviaMakerInterface";

export const TriviaMakerContext = React.createContext<TriviaMakerInterface>(
  defaultTriviaMakerContext
);
export const TriviaMakerProvider = TriviaMakerContext.Provider;
export const TriviaMakerConsumer = TriviaMakerContext.Consumer;
