import React, { useEffect } from "react";
import classes from "./Checkbox.module.scss";

export interface ICheckbox extends React.HTMLAttributes<HTMLElement> {
  color?: string;
  isDisable?: boolean;
  onValueChange?: (value: boolean) => void;
  //left
  left?: number;
  tickWidth?: string;
  tickHeight?: string;
  name?: string;
  top?: number;
  tickStyles?: string;
}
const Checkbox: React.FC<ICheckbox> = ({
  color,
  isDisable,
  style,
  onValueChange,
  left = 5,
  tickHeight = "19",
  tickWidth = "23",
  top = 0,
  defaultChecked,
  ...rest
}) => {
  const [isChecked, setCheckedStatus] = React.useState<boolean | undefined>(
    defaultChecked || false
  );

  useEffect(() => {
    setCheckedStatus(defaultChecked);
  }, [defaultChecked]);

  return (
    <div
      className={`${classes["container-circle"]} ${rest.className}`}
      style={{
        ...style,
        pointerEvents: isDisable ? "none" : "all",
      }}
      onClick={(e) => {
        // setCheckedStatus(!isChecked);
        if (onValueChange) onValueChange(!isChecked);
      }}
    >
      {defaultChecked ? (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width={tickWidth}
          height={tickHeight}
          viewBox="0 0 24 19"
          style={{
            position: "absolute",
            left: left,
            top: top,
          }}
          className={rest.tickStyles}
        >
          <path
            id="iconmonstr-check-mark-1"
            d="M20.285,2,9,13.567,3.714,8.556,0,12.272,9,21,24,5.715Z"
            transform="translate(0 -2)"
            fill={color}
          />
        </svg>
      ) : null}
    </div>
  );
};

Checkbox.defaultProps = {
  color: "#88d128",
  isDisable: false,
};
export default Checkbox;
