import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { WINDOW_RELOAD } from "../../constants";
import {
  ADMIN_LOGIN,
  LOGIN_ROUTE,
} from "../../containers/routes/RoutesConstants";
import SessionStorage from "../../services/SessionStorage";

const ReloadAlert = () => {
  const pageHistory = useHistory();
  let currentPage = pageHistory?.location?.pathname;

  useEffect(() => {
    if (checkPageURL()) {
      window.addEventListener("beforeunload", unloadCallback);
    }
    return () => {
      if (checkPageURL()) {
        window.removeEventListener("beforeunload", unloadCallback);
      }
    };
  }, [currentPage]); // eslint-disable-line react-hooks/exhaustive-deps

  const checkPageURL = () => {
    switch (currentPage) {
      case LOGIN_ROUTE:
      case ADMIN_LOGIN:
        return false;

      default:
        return true;
    }
  };
  const unloadCallback = (event: BeforeUnloadEvent) => {
    const refreshed = SessionStorage.GetItem(WINDOW_RELOAD);
    if (refreshed) return;
    event.preventDefault();
    event.returnValue = "";
    return "";
  };

  return null;
};
export default ReloadAlert;
