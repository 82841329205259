export interface IMusicInterface {
  isAudioPlaying: boolean;
  isSFXPlaying: boolean;
  audioVolume: number | undefined;
  sfxVolume: number | undefined;
  toggleAudioState: () => void;
  toggleSFXState: () => void;
  changeAudioVolume: (value: number) => void;
  changeSFXVolume: (value: number) => void;
  changeAudioClip: (path: string, loop: boolean) => void;
  playSFXMusic: () => void;
  playMusic: () => void;
  pauseMusic: () => void;
  pauseSFXMusic: () => void;
  audio: HTMLAudioElement | undefined;
  sfxAudio: HTMLAudioElement | undefined;
  createNewAudioInstance: (path: string) => void;
  createNewSFXInstance: (path: string) => void;
  resetAudio: () => void;
  resetSFXAudio: () => void;
}

export const defaultMusicContext: IMusicInterface = {
  isAudioPlaying: false,
  isSFXPlaying: false,
  audioVolume: 0 || undefined,
  sfxVolume: 0 || undefined,
  toggleAudioState: () => {},
  toggleSFXState: () => {},
  changeAudioVolume: (value: number) => {},
  changeSFXVolume: (value: number) => {},
  changeAudioClip: (path: string, loop: boolean) => {},
  playMusic: () => {},
  playSFXMusic: () => {},
  pauseMusic: () => {},
  pauseSFXMusic: () => {},
  audio: undefined,
  sfxAudio: undefined,
  createNewAudioInstance: (path: string) => {},
  createNewSFXInstance: (path: string) => {},
  resetAudio: () => {},
  resetSFXAudio: () => {},
};
