import React, { useEffect, useState } from "react";
import { BALTIC_SEA } from "../../../../color";
import { PlainText } from "../../../Text/Text";
import Checkbox, { ICheckbox } from "../Checkbox";
import classes from "./TextCheckbox.module.scss";

interface TriviaTextCheckbox extends ICheckbox {
  containerClass?: string;
}

const TriviaCheckbox: React.FC<TriviaTextCheckbox> = ({
  color,
  isDisable,
  style,
  containerClass,
  onValueChange,
  defaultChecked,
  ...rest
}) => {
  const [status, checkStatus] = useState<boolean>(defaultChecked || false);

  useEffect(() => {
    checkStatus(defaultChecked || false);
  }, [defaultChecked]);
  return (
    <div
      className={`${classes["container"]} ${containerClass}`}
      onClick={() => {
        if (onValueChange) onValueChange(!status);
        checkStatus(!status);
      }}
    >
      <Checkbox
        color={color}
        isDisable={isDisable}
        // onValueChange={onValueChange}
        defaultChecked={status}
        {...rest}
        style={{
          borderRadius: "0px",
          borderColor: BALTIC_SEA,
          borderWidth: "2px",
          width: "27px",
          ...style,
        }}
      />

      <PlainText
        className={rest.className}
        size={"1.5em"}
        style={{
          color: BALTIC_SEA,
          width: "90%",
          marginLeft: "10px",
        }}
      >
        {rest.title}
      </PlainText>
    </div>
  );
};
export default TriviaCheckbox;
