import IMG1 from "./assets/icons/Divide-Icon.svg";
import IMG2 from "./assets/icons/Points-Icon.svg";
import IMG3 from "./assets/icons/Eye-Theif.svg";
import IMG4 from "./assets/icons/Graph-Icon.svg";
import IMG5 from "./assets/icons/Choice-Icon.svg";
import IMG6 from "./assets/icons/Time-Speed.svg";

//summary
import SummaryIcon1 from "./assets/icons/summary-icon-1.svg";
import SummaryIcon2 from "./assets/icons/summary-icon-2.svg";
import SummaryIcon3 from "./assets/icons/summary-icon-3.svg";
import SummaryIcon4 from "./assets/icons/summary-icon-4.svg";
import { DARK_GREEN, GREY, YELLOW } from "./color";

//
export const TRIVIA_ADMIN_GAMECODE = "B18OCSVT99AUS";
export const TOKEN = "AUTH_TOKEN";
export const ADMIN_REFRESH_STATUS = "ADMIN_REFRESH_STATUS";
export const USER_NAME = "USER_NAME";
export const ADMIN_TOKEN = "ADMIN_AUTH_TOKEN";
export const AUTH_TOKEN_HEADER = "Authorization";
export const GAME_CODE = "GAME_CODE";
export const GAME_CODE_DATA = "GAME_CODE_DATA";
export const PLAYER_PUSHER_ID = "PLAYER_PUSHER_ID";
export const PLAYER_ID = "PLAYER_ID";
export const ADMIN_GAME_CODE = "ADMIN_GAME_CODE";
export const SUPER_ADMIN = "SUPER_ADMIN";
export const GAME_TYPE = "GAME_TYPE";
export const GAME_COMPANY = "COMPANY";
export const GAME_LANGUAGE = "GAME_LANGUAGE";
export const TRIVIA_MAKER_ADMIN_TOKEN = "TRIVIA_MAKER_ADMIN_TOKEN";
export const TRIVIA_MAKER_ADMIN_GAME_CODE = "TRIVIA_MAKER_ADMIN_GAME_CODE;";
export const GAME_TIME = 3600; // Game Time in secs
export const MAX_USERS = 7; // Max user Join on same Game Code
export const HINT_AVAILABILITY = 60; // hint will be available after 2 minutes- 2 minutes = 120s
export const MAX_REQUESTED_HINT = 4; // Max requested hint count = 4 for 1 step
export const LAST_GAME_STEP = "13";
export const RELOAD_STATUS = "RELOAD_STATUS";
export const WINDOW_RELOAD = "WINDOW_RELOAD";
//website Link
export const WEBSITE_LINK = "http://www.experios.com.au/";
export const WEBSITE_LINK_HQ = "https://www.escapehq.co.nz/";

//PusherConstants
export const CHANNEL_JOIN_SUCCESS = "pusher:subscription_succeeded";
export const CHANNEL_JOIN_FAILURE = "pusher:subscription_error";
export const MEMBER_JOIN = "pusher:member_added";
export const MEMBER_REMOVE = "pusher:member_removed";
export const START_ROUND = "round-started";
export const START_NEXT_ROUND = "next-round-started";
export const SUBMIT_ANSWER = "client-answer-submitted";
export const PAUSE_GAME = "client-pause-game";
export const POWER_UP_REQUEST = "client-power-up-request";
export const ANSWER_RESPONSE = "answer-response";
export const REQUEST_HINT = "hint-requested";
export const ROUND_ENDED = "game-ended";
export const GAME_ERROR = "game-error";
export const JUMP_END = "jump-to-end";
export const IS_READY = "is-ready";
export const MOVE_TO_QUESTION = "move-to-question";
export const LIST_ITEM_TYPE = {
  QUIZ: "quiz",
  CATEGORY: "category",
  QUESTIONS: "question",
};

export const PUBLIC = "public";

export const SERVER_STATUS = {
  GAME_CODE_NOT_FOUND: {
    value: "Game CODE DOESN'T EXIST",
    color: "#FC5C65",
  },
  INVALID_ADMIN_GAME_CODE: {
    value: "INVALID QUIZMASTER CODE",
    color: "#FC5C65",
  },
};

export const GRAPHQL_OPERATIONS = Object.freeze({
  CREATE_QUIZ: "createQuiz",
  UPDATE_QUIZ: "updateQuiz",
  DELETE_QUIZ: "deleteQuiz",
  LIST_QUIZZES: "listQuizs",
  CREATE_CATEGORY: "createCategory",
  UPDATE_CATEGORY: "updateCategory",
  DELETE_CATEGORY: "deleteCategory",
  CREATE_QUESTION: "createQuestion",
  UPDATE_QUESTION: "updateQuestion",
  DELETE_QUESTION: "deleteQuestion",
});

export const API_NAME = "quizapi";
export const API_ENDPOINT = "/quizFlow";

export const DEFAULT_AVATAR = "#BE7BFF";

export const SELECT_PLAYER_AVATAR = [
  "#BE7BFF",
  "#F9CE29",
  "#29C0B0",
  "#FFAB2D",
  "#FF9497",
  "#ADD53B",
];
export const GAME_CATEGORIES_DATA = [
  {
    title: "ANIMALS",
    color: "#2BCBBA",
    tests: [{ title: "1" }, { title: "1" }, { title: "1" }, { title: "1" }],
  },
  {
    title: "GEOGRAPHY",
    color: "#BE7BFF",
    tests: [{ title: "1" }, { title: "1" }, { title: "1" }, { title: "1" }],
  },
  {
    title: "technology",
    color: "#FFAB2D",
    tests: [{ title: "1" }, { title: "1" }, { title: "1" }, { title: "1" }],
  },
];

export const GAME_POWER_ICONS = [
  {
    img: IMG1,
    title: "50/50",
  },
  {
    img: IMG2,
    title: "X2 POINTS",
  },
  {
    img: IMG3,
    title: "THIEF",
  },
  {
    img: IMG4,
    title: "MAJORITY",
  },
  {
    img: IMG5,
    title: "X2 CHOICE",
  },
  {
    title: "X2 TIME",
    img: IMG6,
  },
];
export const CURRENT_STANDINGS_TABLE_COLUMNS = [
  { title: "RANK", id: "1", key: "rank" },
  { title: "PLAYER", id: "2", key: "player" },
  { title: "CORRECT", id: "3", key: "correct" },
  { title: "INCORRECT", id: "4", key: "incorrect" },
  { title: "POINTS", id: "5", key: "points" },
  // { title: "", id: "6", key: "pointStatus" },
];
export const MANAGE_CATEGORIES_TABLE_COLUMNS = [
  { title: "ICON", id: "1", key: "icon" },
  { title: "NAME", id: "2", key: "name" },
  { title: "COLOUR", id: "3", key: "color" },
  { title: "EDIT", id: "4", key: "edit" },
  { title: "DELETE", id: "5", key: "delete" },
];
export const CREATE_QUESTION_SET_TABLE_COLUMNS = [
  { title: "", id: "1", key: "icon" },
  { title: "", id: "2", key: "name" },
  { title: "", id: "3", key: "edit" },
  { title: "", id: "4", key: "delete" },
];

export const GAME_FINAL_RESULT = [
  {
    title: "RANK",
    id: "1",
    key: "rank",
  },
  {
    title: "PLAYER",
    id: "2",
    key: "player",
  },
  {
    title: "TOTAL CORRECT",
    id: "3",
    key: "correct",
  },
  {
    title: "TOTAL INCORRECT",
    id: "4",
    key: "incorrect",
  },
  {
    title: "OVERALL ACCURACY",
    id: "5",
    key: "accuracy",
  },
  {
    title: "FINAL SCORE",
    id: "6",
    key: "points",
  },
  {
    title: "",
    id: "7",
    key: "status",
  },
];
export const GAME_LEADER_BOARD = [
  {
    title: "RANK",
    id: "1",
    key: "CurrentRank",
  },
  {
    title: "PLAYER",
    id: "2",
    key: "playerName",
  },
  {
    title: "TOTAL CORRECT",
    id: "3",
    key: "CorrectAnswersCount",
  },
  {
    title: "TOTAL INCORRECT",
    id: "4",
    key: "IncorrectAnswersCount",
  },
  {
    title: "OVERALL ACCURACY",
    id: "5",
    key: "accuracy",
  },
  {
    title: "FINAL SCORE",
    id: "6",
    key: "Points",
  },
  // {
  //   title: "",
  //   id: "7",
  //   key: "PrevRank",
  // },
];
export interface ISummarySlot {
  img: string;
  title: string;
}
export const SUMMARY_SLOT_DATA: ISummarySlot[] = [
  {
    img: SummaryIcon1,
    title: "3 QUESTION SETS SELECTED FOR 3 ROUNDS",
  },
  { img: SummaryIcon2, title: "32 QUESTIONS TO ANSWER" },
  { img: SummaryIcon3, title: "45-60 MINUTES TOTAL PLAYING TIME" },
  { img: SummaryIcon4, title: "3/5 DIFFICULTY LEVEL" },
];

export const CREATE_CATEGORY_SLOT_ICONS_DATA = [
  { img: "music" },
  { img: "travel" },
  { img: "film" },
  { img: "flag" },
  { img: "literature" },
  { img: "sports" },
  { img: "science" },
  { img: "game" },
  { img: "plane" },
  { img: "food" },
  { img: "animal" },
  { img: "pop" },
];


export const PLAYER_PANEL = "PlayerPanel";
export const ADMIN_PANEL = "AdminPanel";
export const ADMIN_LABEL = "admin";


export const ADMIN_SUBMISSION_DELAY = 1000 * 2;
export const GAME_CODE_EXPIRED_MSG = "GAME CODE EXPIRED";
export const TOKEN_EXPIRED = "Token Expired";

///audio constants
export const AUDIO_PLAYING_STATUS = "audio_playing_status";
export const SFX_PLAYING_STATUS = "sfx_playing_status";
export const AUDIO_VOLUME = "audio_volume";
export const SFX_VOLUME = "sfx_volume";

export const PowerUpsUsageData = [
  {
    color: YELLOW,
    icon: "/assests/Icons/PowerUps/arrow.svg",
    iconTitle: "X2 POINTS",
    cross: "",
    title: "Power-up ready to be used",
  },
  {
    color: GREY,
    icon: "/assests/Icons/PowerUps/face.svg",
    iconTitle: "SABOTAGE",
    cross: "/assests/Icons/PowerUps/close.svg",
    title: "Power-up has already been used",
  },
  {
    color: DARK_GREEN,
    icon: "/assests/Icons/PowerUps/divide.svg",
    iconTitle: "50/50",
    cross: "",
    title: "Power-up activated",
  },
  {
    color: GREY,
    icon: "/assests/Icons/PowerUps/timer.svg",
    iconTitle: "X2 TIME",
    cross: "",
    title: "Power-up unavailable for this question",
  },
];
export const PowerUpsListData = [
  {
    color: YELLOW,
    icon: "/assests/Icons/PowerUps/divide.svg",
    iconTitle: "50/50",
    cross: "",
    title: "Removes half of the incorrect answers",
  },
  {
    color: YELLOW,
    icon: "/assests/Icons/PowerUps/timer.svg",
    iconTitle: "X2 TIME",
    cross: "",
    title: "Doubles the amount of time to answer",
  },
  {
    color: YELLOW,
    icon: "/assests/Icons/PowerUps/arrow.svg",
    iconTitle: "X2 POINTS",
    cross: "",
    title: "Doubles the amount of points for a correct answer",
  },
  {
    color: YELLOW,
    icon: "/assests/Icons/PowerUps/face.svg",
    iconTitle: "SABOTAGE",
    cross: "",
    title: "Question & answer text is upside down for one random player",
  },
];
