import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { PlayerPowerUps, PowerUp } from "../../../../API";
import { WHITE } from "../../../../color";
import { POWER_UP_REQUEST } from "../../../../constants";
import { IGameLocationState } from "../../../../containers/GameScreen/GameScreen";
import { useGameStateContext } from "../../../../context/GameState/GameStateContainer";
import NetworkManager from "../../../../services/NetworkManager";
import { triggerClientEvent } from "../../../../services/pusher";
import {
  getChannel,
  getPlayer,
  isSuperAdmin,
} from "../../../../utilities/util";
import Button from "../../../Trivia/Button/Button";
import {
  EPowerUp,
  IPowerUpSection,
  POWER_UPS_BUTTONS_DATA,
} from "../../gameScreenInterfaces/gameScreenInterfaces";
import { getPlayerPowerUps } from "../../gameScreenUtils/gameScreenUtils";
import PowerUpsButton from "../powerUpsButton/powerUpsButton";
import classes from "./powerUpsSection.module.scss";

const PowerUpsSection: React.FC<IPowerUpSection> = ({
  isDisable,
  handleSabotagePowerUpResponse,
  handleDoublePointsPowerUpResponse,
  handleFiftyFiftyPowerUpResponse,
  handleDoubleTimePowerUpResponse,
}) => {
  const history = useHistory();
  const routeState: IGameLocationState = history.location
    .state as IGameLocationState;
  const questionNo = routeState?.questionNo;
  const { dbPlayers, gameCode, connectedPlayer } = useGameStateContext();

  const [powerUps, setPowerUps] = useState<
    (PlayerPowerUps | null)[] | null | undefined
  >([]);
  const [isClicked, setIsClicked] = useState(EPowerUp.None);

  useEffect(() => {
    const powerUpsList = getPlayerPowerUps(dbPlayers, getPlayer());
    setPowerUps(powerUpsList);
  }, [questionNo, dbPlayers, connectedPlayer]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setIsClicked(EPowerUp.None);
  }, [questionNo]); // eslint-disable-line react-hooks/exhaustive-deps

  const handlePowerUpClick = async (powerUp: string) => {
    try {
      const playerId = getPlayer() || "";
      await NetworkManager.UsePowerUp(playerId, powerUp);
      const channel: string = getChannel(gameCode);
      if (isSuperAdmin()) {
        triggeredPowerUp(powerUp);
      } else {
        triggerClientEvent(channel, POWER_UP_REQUEST, {
          power: powerUp,
          playerId: playerId,
        });
      }
    } catch (err) {}
  };
  const triggeredPowerUp = (powerUp: string) => {
    const playerId = getPlayer() || "";
    const powerUpResponse = { power: powerUp, playerId: playerId };
    switch (powerUp) {
      case PowerUp.Sabotage:
        handleSabotagePowerUpResponse(powerUpResponse);
        return;
      case PowerUp.DoublePoint:
        handleDoublePointsPowerUpResponse(powerUpResponse);
        return;
      case PowerUp.FiftyFifty:
        handleFiftyFiftyPowerUpResponse(powerUpResponse);
        return;
      case PowerUp.DoubleTime:
        handleDoubleTimePowerUpResponse(powerUpResponse);
        return;
    }
  };
  const isUsed = (powerUp: string) => {
    if (powerUps) {
      const isPowerUp = powerUps?.filter((power) => power?.PowerUp === powerUp);
      return isPowerUp[0]?.isUsed;
    }
    return false;
  };

  return (
    <div className={classes["powerUpsContainer"]}>
      <div className={classes["powerUpsButtonWrapper"]}>
        <Button
          leftArrow={true}
          color={"#222027"}
          style={{ fontSize: "2.8125em", justifyContent: "center" }}
          width={363}
          height={91}
          type="button"
          textstyles={{ color: WHITE }}
        >
          POWER-UPS
        </Button>
      </div>
      <div className={classes["powerUpsButtonContainer"]}>
        {POWER_UPS_BUTTONS_DATA.map(({ key, icon, name }, index) => {
          return (
            <PowerUpsButton
              key={index}
              name={name}
              icon={icon}
              isClicked={isClicked}
              isDisable={isDisable}
              isUsed={isUsed(key)}
              onClick={() => {
                setIsClicked(name);
                handlePowerUpClick(key);
              }}
            />
          );
        })}
      </div>
    </div>
  );
};
export default PowerUpsSection;
