import React from "react";
import {
  GameAdminInterface,
  defaultGameAdminContext,
} from "./GameAdminInterface";

export const GameAdminContext = React.createContext<GameAdminInterface>(
  defaultGameAdminContext
);
export const GameAdminProvider = GameAdminContext.Provider;
export const GameAdminConsumer = GameAdminContext.Consumer;
