import { Members } from "pusher-js";
import React from "react";
import { useEffect } from "react";
import { useHistory } from "react-router";
import { RED } from "../../color";
import {
  ADMIN_REFRESH_STATUS,
  CHANNEL_JOIN_FAILURE,
  CHANNEL_JOIN_SUCCESS,
  MEMBER_JOIN,
  MEMBER_REMOVE,
  PLAYER_PUSHER_ID,
  WINDOW_RELOAD,
} from "../../constants";
import {
  GAME_SCREEN,
  LOGIN_ROUTE,
  ROUND_END_ROUTE,
  ROUND_SCREEN_ROUTE,
} from "../../containers/routes/RoutesConstants";
import { useAuthContext } from "../../context/Auth/AuthContainer";
import { useGameStateContext } from "../../context/GameState/GameStateContainer";
import LocalStorage from "../../services/LocalStorage";
import { pusher } from "../../services/pusher";
import SessionStorage from "../../services/SessionStorage";
import { MemberType } from "../../types/types";
import { isUniqueUserName } from "../../utilities/util";

interface IPusherSubscriptions {
  onExitButtonClicked: () => Promise<void>;
}

const PusherSubscriptions: React.FC<IPusherSubscriptions> = ({
  onExitButtonClicked,
}) => {
  const history = useHistory();
  const {
    UPDATE_ONLINE_USER,
    checkAuthentication,
    isAuthenticated,
    setErrorMessage,
    logoutUser,
  } = useAuthContext();
  const { gameCode, setGameState, adminId } = useGameStateContext();

  const adminIds = React.useRef<string[]>(adminId || []);

  useEffect(() => {
    adminIds.current = [...(adminId || [])] || [];
  }, [adminId]);

  useEffect(() => {
    let memberChannel: any = null;
    if (checkAuthentication() && gameCode !== "") {
      console.log("Connecting To Game Channel", gameCode);
      SessionStorage.RemoveItem(WINDOW_RELOAD);
      memberChannel = pusher().subscribe(`presence-${gameCode}`);
      memberChannel?.bind(CHANNEL_JOIN_SUCCESS, () => {
        let members = memberChannel;
        let memberInfo: any = {};
        Object.assign(memberInfo, members);
        console.log("memberInfo", memberInfo);
        if (!isUniqueUserName(memberInfo.members, memberInfo.members.me)) {
          setErrorMessage({
            value: "NAME ALREADY IN USE",
            color: RED,
          });
          doLogout();
        } else {
          let totalMember = memberInfo.members.count;
          saveMemberId(memberInfo.members);
          UPDATE_ONLINE_USER(totalMember);
          saveAllConnectedMembers(memberInfo.members);
          setErrorMessage({
            value: "",
            color: "",
          });
        }
      });

      memberChannel?.bind(CHANNEL_JOIN_FAILURE, (err: any) => {
        console.log(CHANNEL_JOIN_FAILURE, err);
        let { status } = err;
        if (status === 408 || status === 503) {
          // retry?
          memberChannel = pusher().subscribe(`presence-${gameCode}`);
        }
      });
      memberChannel?.bind(MEMBER_JOIN, (member: MemberType) => {
        console.log(MEMBER_JOIN);
        let members = memberChannel;
        let memberInfo: any = {};
        Object.assign(memberInfo, members);
        if (isUniqueUserName(memberInfo.members, member)) {
          let totalMember = memberInfo.members.count;
          UPDATE_ONLINE_USER(totalMember);
          saveAllConnectedMembers(memberInfo.members);
        }
      });

      memberChannel?.bind(MEMBER_REMOVE, (member: any) => {
        let members = memberChannel;
        let memberInfo: any = {};
        Object.assign(memberInfo, members);
        let totalMember = memberInfo.members.count;
        UPDATE_ONLINE_USER(totalMember);
        saveAllConnectedMembers(memberInfo.members);
        //processPusherMemberRemoved(member);
        onAdminRefresh(member);
      });
    }
    return () => {
      memberChannel?.unbind_all();
      console.log("Unmounted Navbar");
    };
  }, [isAuthenticated, gameCode]); // eslint-disable-line react-hooks/exhaustive-deps

  const onAdminRefresh = async (member: any) => {
    const isAdminExist = adminIds.current.includes(member.info.userId);
    if (isAdminExist && isGamePages()) {
      LocalStorage.SetItem(ADMIN_REFRESH_STATUS, "true");
      SessionStorage.SetItem(WINDOW_RELOAD, "true");
      await onExitButtonClicked();
    }
  };

  const saveMemberId = (members: Members) => {
    if (setGameState) {
      const userId = members.me.info.userId;
      setGameState({ playerPusherId: userId });
      SessionStorage.SetItem(PLAYER_PUSHER_ID, userId);
    }
  };

  const saveAllConnectedMembers = (members: Members) => {
    if (setGameState) {
      const playerId: string[] = [];
      members.each((member: MemberType) => {
        playerId.push(member.info.userId);
      });
      setGameState({ connectedPlayer: [...playerId] });
    }
  };

  const doLogout = () => {
    logoutUser();
    if (setGameState) {
      setGameState({ dbPlayers: [] });
    }
    history.replace(LOGIN_ROUTE);
  };

  return null;
};
export default PusherSubscriptions;
export const isGamePages = () => {
  let currentPage = window?.location?.pathname;
  switch (currentPage) {
    case GAME_SCREEN:
    case ROUND_SCREEN_ROUTE:
    case ROUND_END_ROUTE:
      return true;

    default:
      return false;
  }
};
