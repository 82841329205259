import React from "react";
import InputField from "../../../../../components/inputfield/Inputfield";
import { BoldText } from "../../../../../components/Text/Text";
import TextCheckbox from "../../../../../components/Trivia/Checkbox/TextCheckbox/TextCheckbox";
import { ErrorComponent } from "../../../CreateCollection/CollectionStats/FileInput/FileInputComponent";
import classes from "../../CreateQuestion.module.scss";
import { ICreateQuestionSlot } from "../AddQuestionDetails/AddQuestionDetails";

interface IAddAnswerSlot extends ICreateQuestionSlot {
  required?: boolean;
  id: string;
  index: number;
  onValueChanged?: (value: boolean) => void;
  className?: string;
}

const AddAnswerSlot: React.FC<IAddAnswerSlot> = ({
  required,
  handleChange,
  id,
  index,
  onValueChanged,
  actions,
  className,
}) => {
  const isCheckboxDisable = () => {
    if (actions.values.answerType === "Single Select") {
      if (
        actions.values["answer1"].isCorrect === false &&
        actions.values["answer2"].isCorrect === false &&
        actions.values["answer3"].isCorrect === false &&
        actions.values["answer4"].isCorrect === false
      ) {
        return false;
      } else {
        return !actions.values[id].isCorrect;
      }
    }
    return actions.values[id].answer ? false : true;
  };

  return (
    <div className={`${classes["addAnswerSlotContainer"]} ${className}`}>
      <div className={classes["addAnswerSlotContainerTop"]}>
        <BoldText
          size={"2.1875em"}
          style={{ fontFamily: "Bebas Neue", letterSpacing: "2px" }}
        >
          ANSWER {QUESTION_OPTIONS[index - 1]}
        </BoldText>
        {required ? (
          <div className={classes["requiredContainer"]}>
            <div className={classes["requiredTitle"]}>required field</div>
            <img
              className={classes["requiredIcon"]}
              src={"/assests/Icons/required.svg"}
              alt="icon"
            />
          </div>
        ) : null}
      </div>
      <div className={classes["addAnswerSlotContainerMiddle"]}>
        <InputField
          style={{
            fontSize: "1.875em",
            color: "#222027",
            width: "100%",
          }}
          type="text"
          name={id + ".answer"}
          maxLength={100}
          value={actions.values[id].answer}
          onValueChanged={handleChange}
        />
      </div>
      <div className={classes["errorCheckboxContainer"]}>
        {required ? ErrorComponent(id + ".answer") : <div></div>}
        <div className={classes["addAnswerSlotContainerBottom"]}>
          <TextCheckbox
            title={"CORRECT ANSWER"}
            style={{ borderRadius: "6px" }}
            className={classes["checkboxTitle"]}
            defaultChecked={actions.values[id].isCorrect}
            name={id + ".isCorrect"}
            onValueChange={onValueChanged}
            isDisable={isCheckboxDisable()}
            left={1}
            top={1}
          />
        </div>
      </div>
    </div>
  );
};
export default AddAnswerSlot;

export const QUESTION_OPTIONS = ["A", "B", "C", "D"];
