import { TCollection, TQuestion } from "../../types/ApiTypes";

export interface ISubmitAnswer {
  playerId: string;
  playerName: string;
  answers: string[];
  isCorrect: boolean;
  points?: number;
  receivedTime?: string;
  reactionTime?: string;
}
export interface ISubmitAnswers {
  questionNo: number;
  answers: ISubmitAnswer[];
}
export interface GameAdminInterface {
  collections?: TCollection[];
  selectedCollections?: TCollection[];
  fetchCollections?: () => Promise<TCollection[]>;
  currentRound?: number;
  currentRoundId?: string;
  currentQuestionNo?: number;
  currentRoundQuestion?: TQuestion[];
  fetchRoundQuestion?: (id: string) => Promise<TQuestion[]>;
  setAdminState?: (data: GameAdminInterface) => void;
  roundResult?: ISubmitAnswers[];
  resetAdmin?: () => void;
  adminStatus?: boolean;
  collectionPlayed?: string[];
  getPlayedCollectionsIds?: () => string[];
}

export const defaultGameAdminContext: GameAdminInterface = {
  collections: [],
  selectedCollections: [],
  fetchCollections: () => Promise.resolve([]),
  currentRound: 0,
  currentRoundId: "",
  currentQuestionNo: 0,
  currentRoundQuestion: [],
  fetchRoundQuestion: (id: string) => Promise.resolve([]),
  setAdminState: (data: GameAdminInterface) => {},
  roundResult: [],
  resetAdmin: () => {},
  adminStatus: true,
  collectionPlayed: [],
  getPlayedCollectionsIds: () => [],
};

// export interface ICategory {
//   id: string;
//   Image: string;
//   Type: string;
//   Name: string;
//   Color: string;
//   TotalCollection: number;
// }

// export interface ICollection {
//   id: string;
//   Category_Id: string;
//   Image: string;
//   Type: string;
//   Name: string;
//   Description: string;
//   GameRules: string;
//   CollectionType: string;
//   Time: number;
//   TotalQuestion: number;
//   TotalTime: number;
//   Difficulty: number;
// }

// export interface IQuestion {
//   id: string;
//   Collection_Id: string;
//   Description: string;
//   Media: string;
//   Hint: string[];
//   Answers: IAnswer[];
// }

// export interface IAnswer {
//   id: string;
//   Answer: string[];
//   Correct: boolean;
//   CaseSensitive: boolean;
// }
