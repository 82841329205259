import React from "react";
import { Redirect, Route } from "react-router-dom";
import {
  ADMIN_LOGIN,
  MANAGE_PLAYER_COLLECTION,
  TRIVIA_MAKER,
  CREATE_QUESTION,
  CREATE_COLLECTION,
  MAIN_MENU_ROUTE,
} from "../../containers/routes/RoutesConstants";
import { IRoutes } from "../../containers/routes/RoutesMetaData";
import { useAdminAuthContext } from "../../context/AdminAuth/AdminAuthContainer";
import { useAuthContext } from "../../context/Auth/AuthContainer";

const TriviaPrivateRoute: React.FC<IRoutes> = ({ component, exact, path }) => {
  const { checkAdminAuthentication } = useAdminAuthContext();
  const {
    checkAdminAuthentication: quizMasterAuthentication,
  } = useAuthContext();

  const renderPlayerCollection = () => {
    return (
      <React.Fragment>
        {quizMasterAuthentication() ? (
          <Route exact={exact} path={path} component={component} />
        ) : (
          redirect(path)
        )}
      </React.Fragment>
    );
  };
  const renderAdminPages = () => {
    return (
      <React.Fragment>
        {checkAdminAuthentication() ? (
          <Route exact={exact} path={path} component={component} />
        ) : (
          redirect(path)
        )}
      </React.Fragment>
    );
  };
  const renderCollectionCreationPages = () => {
    return (
      <React.Fragment>
        {checkAdminAuthentication() || quizMasterAuthentication() ? (
          <Route exact={exact} path={path} component={component} />
        ) : (
          redirect(path)
        )}
      </React.Fragment>
    );
  };

  const routesType = () => {
    if (path === MANAGE_PLAYER_COLLECTION) {
      return renderPlayerCollection();
    } else if (path === CREATE_COLLECTION || path === CREATE_QUESTION) {
      return renderCollectionCreationPages();
    } else {
      return renderAdminPages();
    }
  };

  return routesType();
};

export default TriviaPrivateRoute;
const redirect = (path: string) => {
  if (path === MANAGE_PLAYER_COLLECTION) {
    return <Redirect to={MAIN_MENU_ROUTE} />;
  } else {
    return <Redirect to={ADMIN_LOGIN} />;
  }
};

export const TriviaNotFound = () => {
  const { checkAdminAuthentication } = useAdminAuthContext();
  return (
    <React.Fragment>
      {checkAdminAuthentication() ? (
        <Redirect to={TRIVIA_MAKER} />
      ) : (
        <Redirect to={ADMIN_LOGIN} />
      )}
    </React.Fragment>
  );
};
