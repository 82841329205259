import React from "react";
import {
  AdminAuthInterface,
  defaultAdminAuthContext,
} from "./AdminAuthInterface";

export const AdminAuthContext = React.createContext<AdminAuthInterface>(
  defaultAdminAuthContext
);
export const AdminAuthProvider = AdminAuthContext.Provider;
export const AdminAuthConsumer = AdminAuthContext.Consumer;
