import React, { Suspense } from "react";
import { Route } from "react-router";
import { Switch } from "react-router-dom";

import { CircularSpinner } from "../../components/Spinner/Spinner";
import TriviaMakerContextContainer from "../../context/TriviaMaker/TriviaMakerContainer";
import {
  CREATE_CATEGORY,
  CREATE_COLLECTION,
  CREATE_QUESTION,
  MANAGE_CATEGORIES,
  MANAGE_COLLECTION,
  MANAGE_PLAYER_COLLECTION,
  TRIVIA_MAKER,
  ADMIN_LOGIN,
  TRIVIA_NOT_FOUND,
} from "../routes/RoutesConstants";
import { IRoutes } from "../routes/RoutesMetaData";
import TriviaPrivateRoute, {
  TriviaNotFound,
} from "../../components/TriviamakerPrivateRoute/TriviaMakerPrivateRoute";
import TriviaNavbar from "../../components/triviaNavbar/triviaNavbar";
import AdminAuthContextContainer from "../../context/AdminAuth/AdminAuthContainer";

const AdminMainMenu = React.lazy(() => import("./MainMenu/MainMenu"));

const ManageCategories = React.lazy(() =>
  import("./ManageCategories/ManageCategories")
);
const CreateCollection = React.lazy(() =>
  import("./CreateCollection/CreateCollection")
);
const CreateQuestion = React.lazy(() =>
  import("./CreateQuestion/CreateQuestion")
);
const CreateCategory = React.lazy(() =>
  import("./CreateCategory/CreateCategory")
);
const ManageCollection = React.lazy(() =>
  import("./ManageCollection/ManageCollection")
);
const ManagePlayerCollection = React.lazy(() =>
  import("./ManagePlayerCollections/ManagePlayerCollections")
);
const AdminLogin = React.lazy(() => import("./AdminSignIn/AdminSignIn"));

const Trivia = () => {
  return (
    <AdminAuthContextContainer>
      <TriviaMakerContextContainer>
        <TriviaNavbar />
        <Suspense
          fallback={<CircularSpinner backdropAlpha={false}></CircularSpinner>}
        >
          <Switch>
            {allRoutes.map((currentRoute, index) => {
              return currentRoute.isPrivate ? (
                <TriviaPrivateRoute
                  key={index}
                  component={currentRoute.component}
                  exact={currentRoute.exact}
                  path={currentRoute.path}
                />
              ) : (
                <Route
                  key={index}
                  path={currentRoute.path}
                  component={currentRoute.component}
                  exact={currentRoute.exact || false}
                />
              );
            })}
          </Switch>
        </Suspense>
      </TriviaMakerContextContainer>
    </AdminAuthContextContainer>
  );
};

export default Trivia;

const allRoutes: IRoutes[] = [
  {
    path: ADMIN_LOGIN,
    component: AdminLogin,
    exact: true,
    isPrivate: false,
  },
  {
    path: TRIVIA_MAKER,
    component: AdminMainMenu,
    exact: true,
    isPrivate: true,
  },
  {
    path: MANAGE_CATEGORIES,
    component: ManageCategories,
    exact: true,
    isPrivate: true,
  },
  {
    path: CREATE_CATEGORY,
    component: CreateCategory,
    exact: true,
    isPrivate: true,
  },
  {
    path: MANAGE_COLLECTION,
    component: ManageCollection,
    exact: true,
    isPrivate: true,
  },
  {
    path: CREATE_COLLECTION,
    component: CreateCollection,
    exact: true,
    isPrivate: true,
  },
  {
    path: CREATE_QUESTION,
    component: CreateQuestion,
    exact: true,
    isPrivate: true,
  },
  {
    path: MANAGE_PLAYER_COLLECTION,
    component: ManagePlayerCollection,
    exact: true,
    isPrivate: true,
  },
  {
    path: TRIVIA_NOT_FOUND,
    component: TriviaNotFound,
    exact: false,
    isPrivate: false,
  },
];
