/** @format */

export const LOGIN_ROUTE = "/login";
export const MAIN_MENU_ROUTE = "/";
export const RESUME_GAME_RULES = "/resume-trivia";
export const QUIZ_LIST = "/quiz-list"; //1
export const CATEGORY_LIST = "/category-list"; //2
export const QUESTION_LIST = "/question-list"; //3
export const QUESTION = "/question"; //4
export const GAME_LOBBY = "/game-lobby";
export const SELECT_COLLECTION = `/select-collection`;
export const ROUND_SCREEN_ROUTE = "/round";
export const GAME_SCREEN = "/game-screen";
export const ROUND_END_ROUTE = "/round-end";
export const GAME_END_ROUTE = "/game-end";
export const GAME_INTRO = "/game-intro";
export const TRIVIA_MAKER = "/triviamaker";
export const MANAGE_CATEGORIES = "/triviamaker/admin-manage-categories";
export const CREATE_CATEGORY = "/triviamaker/admin-create-category";
export const MANAGE_COLLECTION = "/triviamaker/admin-manage-collection";
export const CREATE_COLLECTION = "/triviamaker/admin-create-collection";
export const CREATE_QUESTION = "/triviamaker/admin-create-question";
export const NOT_FOUND = "/*";
export const TRIVIA_GAMES = "/trivia-games";
export const TRIVIA_RESULTS = "/trivia-results";
export const MANAGE_PLAYER_COLLECTION = "/triviamaker/manage-player-collection";
export const ADMIN_LOGIN = "/triviamaker/login";
export const TRIVIA_NOT_FOUND = "/triviamaker/*";
export const JOINING = "/joining";
export const POWER_UPS = "/power-ups";
