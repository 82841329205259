import Amplify from "aws-amplify";
import { nanoid } from "nanoid";
import {
  ADMIN_GAME_CODE,
  ADMIN_REFRESH_STATUS,
  ADMIN_TOKEN,
  AUDIO_PLAYING_STATUS,
  AUDIO_VOLUME,
  GAME_CODE,
  GAME_CODE_DATA,
  PLAYER_ID,
  PLAYER_PUSHER_ID,
  RELOAD_STATUS,
  SFX_PLAYING_STATUS,
  SFX_VOLUME,
  SUPER_ADMIN,
  TRIVIA_MAKER_ADMIN_GAME_CODE,
} from "../constants";
import LocalStorage from "../services/LocalStorage";
import SessionStorage from "../services/SessionStorage";
import { TTriviaPlayer } from "../types/ApiTypes";
import { MemberList, MemberType } from "../types/types";

export const styleTime = (secs: number) => {
  if (secs <= 0) {
    return "00:00";
  }
  let minutes = Math.floor(secs / 60);
  let seconds = Math.floor(secs - minutes * 60);
  let timeFormatting = "";
  if (minutes < 10) {
    timeFormatting = "0" + minutes + ":";
  } else {
    timeFormatting = minutes + ":";
  }
  if (seconds < 10) {
    timeFormatting = timeFormatting + "0" + seconds;
  } else {
    timeFormatting = timeFormatting + "" + seconds;
  }
  return timeFormatting;
};

export const getTotalTime = (totalTime: number) => {
  const floorValue = Math.floor(totalTime / 60);
  const ceilValue = Math.ceil(totalTime / 60);
  if (floorValue === ceilValue) {
    return ceilValue.toString();
  }
  return `${floorValue} - ${ceilValue}`;
};

export const SecToMin = (secs: number): number => {
  return Math.trunc(secs / 60);
};
export const Clamp = (value: number, min: number, max: number): number => {
  if (value >= min && value <= max) return value;
  if (value < min) return min;
  return max;
};

export const GetFileSize = (file: File) => {
  if (file) {
    return file.size / 1024 / 1024; // Return size in MB
  }
  throw new Error("No File Found");
};
export const StringToNumber = (value: string): number => {
  let filterElements = value.match(/\d+/);
  filterElements =
    filterElements === undefined || filterElements === null
      ? []
      : filterElements;
  return filterElements.length > 0 ? parseInt(filterElements[0]) : 0;
};

export const CacheImages = async (images: string[]) => {
  const promises = await images.map((src) => {
    return new Promise((resolve, reject) => {
      const img = new Image();

      img.src = src;
      img.onload = resolve;
      img.onerror = reject;
    });
  });
  await Promise.all(promises);
};
export const IsMobile = () => {
  return /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
  // const mobiles = {
  //   Android: function() {
  //     return navigator.userAgent.match(/Android/i);
  //   },
  //   BlackBerry: function() {
  //     return navigator.userAgent.match(/BlackBerry/i);
  //   },
  //   iOS: function() {
  //     return navigator.userAgent.match(/iPhone|iPad|iPod/i);
  //   },
  //   Opera: function() {
  //     return navigator.userAgent.match(/Opera Mini/i);
  //   },
  //   Windows: function() {
  //     return (
  //       navigator.userAgent.match(/IEMobile/i) ||
  //       navigator.userAgent.match(/WPDesktop/i)
  //     );
  //   },
  // };
  // return (
  //   mobiles.Android() ||
  //   mobiles.BlackBerry() ||
  //   mobiles.iOS() ||
  //   mobiles.Opera() ||
  //   mobiles.Windows()
  // );
};

export const IsSafari = () => {
  return (
    navigator.userAgent.includes("Safari") &&
    !navigator.userAgent.includes("Chrome")
  );
};
export const IsImage = (path: string) => {
  let extension = path.split(".").pop() || "";
  const extensions = ["jpg", "jpeg", "png", "gif", "tiff", "svg"];
  return extensions.includes(extension);
};
export const IsAudio = (path: string) => {
  let extension = path.split(".").pop() || "";
  const extensions = ["mp3", "wav", "aac"];
  return extensions.includes(extension);
};
export const SliceString = (value: string, length: number = 15) => {
  if (value.length < length) {
    return value;
  }
  return value.slice(0, length) + "...";
};

export const SliceStringWithoutDots = (value: string, length: number = 15) => {
  if (value.length < length) {
    return value;
  }
  return value.slice(0, length);
};

export const UploadDocument = async (file: File) => {
  try {
    const result: any = await Amplify.Storage.put(
      `${"QuizMedia"}/${nanoid(16)}?${file.name.split("?").join("")}`,
      file,
      {
        contentType: file.type,
        level: "public",
      }
    );
    return result.key;
  } catch (err) {}
};

export const RemoveDocument = async (filename: string) => {
  try {
    if (filename) {
      await Amplify.Storage.remove(filename, {
        level: "public",
      });
    }
  } catch (err) {}
};

export const ShuffleArray = (array: any[]) => {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }
};

export const isUniqueUserName = (members: MemberList, me: MemberType) => {
  let count = 0;
  members.each(function(member: MemberType) {
    if (member.info.name === me.info.name) {
      count++;
    }
  });
  return !(count > 1);
};
export const SliceImageName = (imageName: string) => {
  const startIndex = imageName.indexOf("?");
  const fileName = imageName.slice(startIndex + 1, imageName.length);
  return fileName;
};

export const getTimeRange = (seconds: number, separator: string) => {
  const floorValue = Math.floor(seconds / 60);
  const ceilValue = Math.ceil(seconds / 60);
  if (floorValue === ceilValue) {
    return ceilValue.toString();
  }
  return `${floorValue}${separator}${ceilValue}`;
};

export const groupBy = (property: string, objectArray: any[]) => {
  return objectArray.reduce((acc, obj) => {
    const key = obj[property];
    if (!acc[key]) {
      acc[key] = [];
    }
    acc[key].push(obj);
    return acc;
  }, {});
};

export const sortedBy = (property: string, objectArray: any[]) => {
  return objectArray.sort((a: any, b: any) => {
    return a[property].toUpperCase().localeCompare(b[property].toUpperCase());
  });
};

export const sortedByNumber = (property: string, objectArray: any[]) => {
  return objectArray.sort((a: any, b: any) => {
    return a[property] - b[property];
  });
};

export const sortedGameResults = (
  objectArray: any[],
  rankKey: string = "rank"
) => {
  return objectArray.sort((a: any, b: any) => {
    return a[rankKey] - b[rankKey];
  });
};

export const getChannel = (gameCode: string | undefined) => {
  return `private-${gameCode}-gameplay`;
};

export const isAdmin = () => {
  return LocalStorage.GetItem(ADMIN_TOKEN) ? true : false;
};

export const getPlayer = () => {
  return SessionStorage.GetItem(PLAYER_ID);
};
export const getPusherPlayer = () => {
  return SessionStorage.GetItem(PLAYER_PUSHER_ID);
};
export const getOnlinePlayers = (
  players: TTriviaPlayer[],
  connectedPlayers: string[]
) => {
  return players.filter((player) => {
    return connectedPlayers.includes(player.PusherId || "");
  });
};

export const delay = (time: number) => {
  return new Promise((resolve, rej) => {
    setTimeout(() => {
      resolve(0);
    }, time);
  });
};
export const getAdminCode = () => {
  return LocalStorage.GetItem(ADMIN_GAME_CODE);
};
export const getPlayerCode = () => {
  return LocalStorage.GetItem(GAME_CODE);
};
export const getTriviaMakerAdmin = () => {
  return LocalStorage.GetItem(TRIVIA_MAKER_ADMIN_GAME_CODE);
};
export const isSuperAdmin = () => {
  return LocalStorage.GetItem(SUPER_ADMIN) ? true : false;
};

export const getGameData = (key: string) => {
  let data: any = LocalStorage.GetItem(GAME_CODE_DATA) || "{}";
  data = JSON.parse(data);
  return data[key];
};

export const removeSuperAdmin = () => {
  LocalStorage.RemoveItem(SUPER_ADMIN);
};

export const isAdminRefreshed = () => {
  return LocalStorage.GetItem(ADMIN_REFRESH_STATUS) ? true : false;
};
export const removeAdminRefreshStatus = () => {
  LocalStorage.RemoveItem(ADMIN_REFRESH_STATUS);
};

export const isMusicPlaying = () => {
  return JSON.parse(LocalStorage.GetItem(AUDIO_PLAYING_STATUS) || "true");
};

export const SFXPlayingStatus = () => {
  return JSON.parse(LocalStorage.GetItem(SFX_PLAYING_STATUS) || "true");
};
export const getMusicVolume = () => {
  return JSON.parse(LocalStorage.GetItem(AUDIO_VOLUME) || "0.1");
};
export const getSFXVolume = () => {
  return JSON.parse(LocalStorage.GetItem(SFX_VOLUME) || "0.1");
};
export const isUserRefreshed = () => {
  return LocalStorage.GetItem(RELOAD_STATUS) ? true : false;
};
export const removeUserRefreshStatus = () => {
  LocalStorage.RemoveItem(RELOAD_STATUS);
};
