import { CircularProgress } from "@material-ui/core";
import { Storage } from "aws-amplify";
import { nanoid } from "nanoid";
import React, { Fragment } from "react";
import { useEffect } from "react";
import { IsImage } from "../../utilities/util";
import classes from "./S3Image.module.scss";

interface IS3Image {
  s3Key: string | undefined | null;
  src?: string;
  className?: string;
  loader?: boolean;
  isAudioPlaying?: boolean;
}

const CustomS3Image: React.FC<IS3Image> = ({
  s3Key,
  src,
  className,
  loader = true,
  isAudioPlaying = true,
}) => {
  const [s3Image, setS3Image] = React.useState<any>(src);
  const ImageId = nanoid();

  useEffect(() => {
    const getS3Image = async () => {
      if (s3Key) {
        try {
          if (IsImage(s3Key)) {
            const url = await Storage.get(s3Key);
            setS3Image(url);
          } else {
            setS3Image(`/assests/Icons/CategoryIcons/${s3Key || "film"}.svg`);
          }
        } catch (err) {}
      }
    };
    getS3Image();
    return () => {
      setS3Image(`/assests/Icons/CategoryIcons/${s3Key || "film"}.svg`);
    };
  }, [s3Key]);
  return (
    <Fragment>
      {s3Image ? (
        <img
          id={ImageId}
          src={s3Image}
          alt="icon"
          className={`${classes["image"]} ${className}`}
        />
      ) : loader ? (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "210px",
          }}
        >
          <CircularProgress></CircularProgress>
        </div>
      ) : null}
    </Fragment>
  );
};
export default CustomS3Image;
