import { BrowserRouter } from "react-router-dom";
import AuthContextContainer from "./context/Auth/AuthContainer";
import "./App.css";
import Routes from "./containers/routes/Routes";
import PopupContainer from "./context/Popup/PopupContainer";
import Navbar from "./components/navbar/navbar";
import Amplify from "aws-amplify";
import awsConfig from "./aws-exports";
import GameAdminContextContainer from "./context/GameAdmin/GameAdminContainer";
import GameStateContainer from "./context/GameState/GameStateContainer";
import MusicContextContainer from "./context/Music/MusicContainer";
import ErrorBoundary from "./components/errorBoundary/errorBoundary";
import GameScreenContextContainer from "./context/GameScreen/GameScreenContainer";

Amplify.configure(awsConfig);

const App = () => {
  return (
    <ErrorBoundary>
      <BrowserRouter>
        <GameScreenContextContainer>
          <MusicContextContainer>
            <AuthContextContainer>
              <PopupContainer>
                <GameAdminContextContainer>
                  <GameStateContainer>
                    <Navbar />
                    <Routes />
                  </GameStateContainer>
                </GameAdminContextContainer>
              </PopupContainer>
            </AuthContextContainer>
          </MusicContextContainer>
        </GameScreenContextContainer>
      </BrowserRouter>
    </ErrorBoundary>
  );
};

export default App;
