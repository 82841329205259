import React, { useEffect } from "react";
import classes from "../../CreateCollection.module.scss";
import {
  GetFileSize,
  SliceImageName,
  SliceString,
} from "../../../../../utilities/util";
import { ErrorMessage } from "formik";
import CustomS3Image from "../../../../../components/s3Image/S3Image";

interface IFileInputComponent {
  value?: any;
  name: string;
  actions: any;
  fileSettings?: any;
  isQuestion?: boolean;
}

const FileInputComponent: React.FC<IFileInputComponent> = ({
  value,
  name,
  actions,
  fileSettings = {
    filter: ".png,.jpg,.jpeg",
    isImage: false,
  },
  isQuestion,
}) => {
  const [imageName, setImageName] = React.useState(value);
  const [mediaError, setMediaError] = React.useState("");
  const inputRef = React.useRef<HTMLInputElement>(null);

  useEffect(() => {
    setImageName(value);
  }, [value]);
  //file input
  const uploadMediaFile = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    try {
      if (event.target.files && event.target.files.length > 0) {
        const file = event.target.files[0];
        const size = GetFileSize(file);
        if (size <= 10) {
          //const previousFile = value;
          //await RemoveDocument(previousFile);
          // const key = await UploadDocument(file);
          setImageName(file.name);
          actions.setFieldValue(name, file.name);
          actions.setFieldValue("imageFile", file);
        } else {
          setMediaError("File size exceeds to 10MB");
        }
      }
    } catch (err) {
      setMediaError("Unable to upload file! Try again.");
      console.log("Error while uploading media file!");
    }
  };

  return (
    <>
      <div
        className={`${classes["fullWidth"]}`}
        style={{ cursor: "pointer" }}
        onClick={(e) => {
          inputRef.current?.click();
          setMediaError("");
        }}
      >
        <label className={classes["inputLabel"]}>CLICK TO ADD MEDIA</label>
        {isQuestion ? (
          <label className={classes["inputLabel"]}>
            {" "}
            (PNG, JPG, JPEG, MP3)
          </label>
        ) : null}
        <FileInput />
        <input
          style={{ display: "none" }}
          ref={inputRef}
          accept={fileSettings.filter}
          type="file"
          onChange={(event) => {
            uploadMediaFile(event);
          }}
        />
      </div>
      {mediaError && <p style={{ width: "50%", color: "red" }}>{mediaError}</p>}

      {imageName.length > 0 ? (
        <>
          {/* <label className={classes["inputLabel"]}>SELECTED MEDIA</label> */}
          <div
            style={{
              color: "white",
              display: "flex",
              paddingTop: "10px",
              width: "98%",
              justifyContent: "space-between",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {renderFileIcon(false, imageName)}
              <div
                style={{
                  color: "#222027",
                  fontSize: "1.875em",
                }}
              >
                {SliceString(SliceImageName(imageName), 44)}
              </div>
            </div>
            <img
              style={{
                color: "red",
                paddingTop: "10px",
                width: "30px",
                height: "30px",
                cursor: "pointer",
                marginRight: "40px",
              }}
              alt="icon"
              src={"/assests/Icons/table-close.svg"}
              onClick={() => {
                setImageName("");
                actions.setFieldValue("image", "");
                actions.setFieldValue("imageFile", null);
                // RemoveDocument(
                //   fileSettings.filter === ".svg"
                //     ? actions.values.icon
                //     : actions.values.image
                // );
              }}
            />
          </div>
        </>
      ) : null}
    </>
  );
};
export default FileInputComponent;

const FileInput: React.FC<{}> = () => {
  return (
    <div className={classes["fileInput"]}>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <img
          src={"/assests/Icons/plus-icon.svg"}
          style={{ width: "30px", margin: 0 }}
          alt="icon"
        />
      </div>
    </div>
  );
};

export const ErrorComponent = (
  name: string | undefined,
  className: string = ""
) => {
  return (
    <span className={`${classes["errorMessage"]} ${className}`}>
      <ErrorMessage name={name || ""} />
    </span>
  );
};
const renderFileIcon = (isImage: boolean, image: string) => {
  if (isImage) {
    return (
      <CustomS3Image
        s3Key={image}
        className={classes["categoryIcon"]}
        loader={false}
      />
    );
  } else {
    return (
      <img
        style={{
          width: "40px",
          height: "40px",
          objectFit: "contain",
          marginRight: "20px",
        }}
        src={"/assests/Icons/img-icon.svg"}
        alt="icon"
      />
    );
  }
};
