import React from "react";
import classes from "./messagebox.module.scss";
import CFontAwesomeIcon from "../customFontIcon/fontAwesomeIcon";
import Backdrop from "../backdrop/Backdrop";
import {
  INFO_MARK_ICON,
  TICK_MARK_ICON,
  TRIANGLE_WARNING_ICON,
} from "../../icons";
import { EMessageType } from "../../containers/Enums/Enums";

interface IMessageBox {
  type: EMessageType;
  title: string;
  button1?: React.ReactNode;
  button2?: React.ReactNode;
  titleIcon?: string;
  iconStyles?: React.CSSProperties;
}

const getIcon = (type: EMessageType): string => {
  switch (type) {
    case EMessageType.Success:
      return TICK_MARK_ICON;
    case EMessageType.Error:
      return "fas fa-times";
    case EMessageType.Info:
      return INFO_MARK_ICON;
    case EMessageType.warning:
      return TRIANGLE_WARNING_ICON;
    default:
      return "fas fa-times";
  }
};
const getIconColor = (type: EMessageType): string => {
  switch (type) {
    case EMessageType.Success:
      return "#50A220";
    case EMessageType.Error:
      return "#F41D25";
    case EMessageType.Info:
    case EMessageType.warning:
      return "#F9CE29";
    default:
      return "#F41D25";
  }
};
const MessageBox: React.FC<IMessageBox> = ({
  type,
  title,
  button1,
  button2,
  titleIcon,
  children,
  iconStyles,
}) => {
  const icon = titleIcon ? titleIcon : getIcon(type);
  const iconColor = getIconColor(type);

  return (
    <Backdrop>
      <div className={classes["container"]}>
        <span
          style={{
            fontSize: "40px",
            color: `${iconColor}`,
            display: "flex",
            alignItems: "center",
          }}
        >
          <CFontAwesomeIcon
            parentIcon="fas fa-circle"
            childIcon={icon}
            style={{ fontSize: "1.2em", marginTop: "5px" }}
            childStyle={iconStyles}
          ></CFontAwesomeIcon>
          &nbsp;&nbsp;{title}
        </span>
        <div
          style={{
            height: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              flex: 1,
              marginTop: "10px",
              width: "90%",
              marginLeft: "20px",
            }}
          >
            {/* {message.map((x, index) => {
              return x;
            })} */}
            {children}
          </div>
          <div className={classes["button-container"]}>
            {button2}
            {button1}
          </div>
        </div>
      </div>
    </Backdrop>
  );
};

export default MessageBox;
