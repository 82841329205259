import { useContext, useEffect, useRef, useState } from "react";
import classes from "./SettingsPopUp.module.scss";
import { format } from "date-fns";
import { useGameStateContext } from "../../context/GameState/GameStateContainer";
import Button from "../../components/Trivia/Button/Button";
import { useMusicContext } from "../../context/Music/MusicContainer";
import { getMessageBox } from "../../components/navbar/navbar";
import { EAudioSounds, EMessageType } from "../Enums/Enums";
import { YELLOW } from "../../color";
import { PopupContext } from "../../context/Popup/PopupContext";
import { getGameData } from "../../utilities/util";
import SmallToggleSwitch from "../../components/Toggle/SmallToggle/SmallToggle";

const SettingsPopUp = () => {
  const gameCodeRef = useRef<HTMLSpanElement>(null);
  const { gameCode } = useGameStateContext();
  const { showPopup } = useContext(PopupContext);
  const [gameDetails, setGameDetails] = useState({
    daysValid: "",
    maxPlayers: 0,
  });
  const {
    isAudioPlaying,
    isSFXPlaying,
    audioVolume,
    sfxVolume,
    changeAudioVolume,
    changeSFXVolume,
    toggleAudioState,
    toggleSFXState,
    createNewSFXInstance,
  } = useMusicContext();

  useEffect(() => {
    if (gameCode) {
      const validDays = getGameData("daysValid") || "";
      const maximumPlayers = getGameData("maxPlayers");

      setGameDetails((prev) => ({
        ...prev,
        daysValid: validDays,
        maxPlayers: maximumPlayers,
      }));
    }
  }, [gameCode]); // eslint-disable-line react-hooks/exhaustive-deps

  const onCopyButtonClick = () => {
    navigator?.clipboard?.writeText(gameCode?.toUpperCase() || "");
    gameCodeRef?.current?.classList?.add("toggleIn");
    setTimeout(() => {
      gameCodeRef?.current?.classList?.remove("toggleIn");
    }, 1000);
  };

  const renderSettingBoxContent = () => {
    return (
      <div
        className={classes["settingBoxContent"]}
        style={{
          fontFamily: "SegoeUI",
          fontSize: "28px",
        }}
      >
        <div className={classes["settingBoxContentBox"]}>
          <span className={classes["settingBoxContentText"]}>Game code</span>
          <span
            ref={gameCodeRef}
            style={{
              cursor: "pointer",
            }}
            onClick={onCopyButtonClick}
          >
            {" "}
            {gameCode?.toUpperCase()}
            <i
              className="far fa-clone"
              style={{
                marginLeft: "15px",
                transform: "rotate(90deg)",
              }}
            />
            <span> Copy</span>
          </span>
        </div>
        <div className={classes["settingBoxContentBox"]}>
          <span className={classes["settingBoxContentText"]}>Expiry date</span>
          <span>
            {gameDetails.daysValid &&
              format(
                new Date(gameDetails?.daysValid?.toString() || ""),
                "dd MMM yyyy - hh:mma"
              )}
          </span>
        </div>
        <div className={classes["settingBoxContentBox"]}>
          <span className={classes["settingBoxContentText"]}>Max players</span>
          <span>{gameDetails?.maxPlayers}</span>
        </div>
        <div className={classes["settingBoxContentBox"]}>
          <span className={classes["settingBoxContentText"]}>Music</span>
          <span>
            <SmallToggleSwitch
              checked={isAudioPlaying}
              onChange={(e) => {
                toggleAudioState();
              }}
              optionLabels={["ON", "OFF"]}
              toggleType={"tertiary"}
              id="989"
            />
          </span>
        </div>
        <div className={classes["settingBoxContentBox"]}>
          <span className={classes["settingBoxContentText"]}>Music volume</span>
          <MusicSlider
            volume={audioVolume || 0}
            changeVolume={(e) => changeAudioVolume(e)}
            // onMouseUp={() => {
            //   createNewAudioInstance(EAudioSounds.Correct);
            // }}
          />
        </div>
        <div className={classes["settingBoxContentBox"]}>
          <span className={classes["settingBoxContentText"]}>SFX</span>
          <span>
            <SmallToggleSwitch
              checked={isSFXPlaying}
              onChange={(e) => {
                toggleSFXState();
              }}
              optionLabels={["ON", "OFF"]}
              toggleType={"tertiary"}
              id={"SFX"}
            />
          </span>
        </div>
        <div className={classes["settingBoxContentBox"]}>
          <span className={classes["settingBoxContentText"]}>SFX volume</span>
          <MusicSlider
            volume={sfxVolume || 0}
            changeVolume={(e) => changeSFXVolume(e)}
            onMouseUp={() => {
              createNewSFXInstance(EAudioSounds.Correct);
            }}
          />
        </div>
      </div>
    );
  };

  const Box = () => {
    let box = getMessageBox(
      EMessageType.Info,
      "SETTINGS",
      renderSettingBoxContent(),
      <Button
        leftArrow={false}
        color={YELLOW}
        style={{
          fontSize: "2.25em",
          paddingLeft: "5px",
          justifyContent: "center",
        }}
        width={200}
        height={50}
        onClick={() => {
          showPopup(null);
        }}
      >
        {"CLOSE"}
      </Button>
    );
    return box;
  };

  return Box();
};
export default SettingsPopUp;

export interface IMusicSlider {
  volume: number;
  changeVolume: (e: number) => void;
  onMouseUp?: () => void;
}

export const MusicSlider: React.FC<IMusicSlider> = ({
  volume,
  changeVolume,
  onMouseUp,
}) => {
  return (
    <div className={classes["sliderContainer"]}>
      <input
        style={{
          background: `linear-gradient(to right, #f9ce29 0%, #f9ce29 ${volume &&
            volume * 100}%, #FFFFFF ${volume && volume * 100}%, #FFFFFF 100%)`,
          width: "100%",
        }}
        type="range"
        min={0}
        max={100}
        value={volume && volume * 100}
        onChange={(e) => {
          changeVolume(parseInt(e.target.value));
        }}
        onMouseUp={onMouseUp}
      />
    </div>
  );
};
