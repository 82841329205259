export enum ContentType {
  json = "application/json",
  multipart = "multipart/form-data",
}

export const EGameCompany = Object.freeze({
  Experios: "experios",
  Enigma: "ENIGMA",
  FunEmpire: "FUN EMPIRE",
  EscapeRoom: "ESCAPE ROOMS FOR YOU",
  EscapeHQ: "escapehq",
  GetMeOut: "GMO",
  GrandEscapeGame: "FUNTANIA",
  None: "",
});

export const GameLanguages = Object.freeze({
  ENUK: "enuk",
  DUTCH: "nl",
  PK: "pk",
  DEFAULT: "enuk",
});

export const EQuestionType = Object.freeze({
  "Multi-Select": "Multi Select",
  "Single-Select": "Single Select",
  Input: "Text Type",
});

export const ECollectionType = Object.freeze({
  "": "",
  "Guess the...": "Guess the...",
  Guessing: "Guessing",
  Knowledge: "Knowledge",
  Riddles: "Riddles",
  Wiki: "Wiki",
});
export const ESecToAnswer = Object.freeze({
  "0": "",
  "5": "5",
  "10": "10",
  "30": "30",
  "45": "45",
  "60": "60",
  "90": "90",
  "120": "120",

  // "": "",
  // sec5: "5",
  // Sec10: "10",
  // sec30: "30",
  // Sec45: "45",
  // sec60: "60",
  // sec90: "90",
  // sec120: "120",
});
export const EDifficulty = Object.freeze({
  "": "",
  "1/5": "1",
  "2/5": "2",
  "3/5": "3",
  "4/5": "4",
  "5/5": "5",
});
export const ECategory = Object.freeze({
  "": "",
  AnimalKingdom: "ANIMAL KINGDOM",
  ComputerGames: "COMPUTER GAMES",
  FilmAndTelevision: "FILM & TELEVISION",
  FoodAndDrinks: "FOOD & DRINKS",
  GeneralKnowledge: "GENERAL KNOWLEDGE",
  Geography: "GEOGRAPHY",
  Literature: "LITERATURE",
  Music: "MUSIC",
  PopCulture: "POP CULTURE",
  Science: "SCIENCE",
  Sports: "SPORTS",
  Travel: "TRAVEL",
});

export const ECaseSensitive = Object.freeze({
  No: "No",
  Yes: "Yes",
});

export const EQuestionTime = Object.freeze({
  Sec10: "10 sec",
  Sec20: "20 sec",
  Sec30: "30 sec",
});
export const EHeaderType = Object.freeze({
  Text: "text",
  Image: "image",
  MultiText: "multiText",
  MultiNeonText: "multiNeonText",
});

export const EPowerUps = Object.freeze({
  FiftyFifty: "FiftyFifty",
  DoubleTime: "DoubleTime",
  DoublePoint: "DoublePoint",
  Sabotage: "Sabotage",
  None: "None",
});

export enum EMessageType {
  None,
  Success,
  Error,
  Info,
  warning,
}
export const EAudioSounds = Object.freeze({
  BGMusic: "assests/GameFiles/backgroundMusics/bgmusic.mp3",
  Correct: "assests/GameFiles/backgroundMusics/correct.mp3",
  FinalResults: "assests/GameFiles/backgroundMusics/finalresult.mp3",
  InCorrect: "assests/GameFiles/backgroundMusics/incorrect.mp3",
  QuestionCountdown:
    "assests/GameFiles/backgroundMusics/questioncountdownv2.mp3",
  RoundCountdown: "assests/GameFiles/backgroundMusics/roundcountdownv3.mp3",
  RoundResults: "assests/GameFiles/backgroundMusics/roundresult.mp3",
  DrumRoll: "assests/GameFiles/backgroundMusics/drumroll.mp3",
});
