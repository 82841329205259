import { Fragment, useEffect, useState } from "react";
import { RouteComponentProps, useHistory, withRouter } from "react-router-dom";

import "../navbar/navbar.scss";
import Logo from "../../assets/Logo/Trivia_Logo.png";
import { YELLOW } from "../../color";
import {
  ADMIN_LOGIN,
  CREATE_CATEGORY,
  CREATE_COLLECTION,
  CREATE_QUESTION,
  MANAGE_CATEGORIES,
  MANAGE_COLLECTION,
  MANAGE_PLAYER_COLLECTION,
  TRIVIA_MAKER,
} from "../../containers/routes/RoutesConstants";
import { useAdminAuthContext } from "../../context/AdminAuth/AdminAuthContainer";
import Button from "../Trivia/Button/Button";
///add audio
import { useMusicContext } from "../../context/Music/MusicContainer";

const TriviaNavbar: React.FC<RouteComponentProps> = ({ history }) => {
  const [controlButtons, setControlButtons] = useState({
    hideButton: true,
    hideLogo: false,
  });
  const { logoutTriviaAdmin } = useAdminAuthContext();
  const pageHistory = useHistory();
  let currentPage = pageHistory?.location?.pathname;
  ///add audio
  const { resetAudio, resetSFXAudio } = useMusicContext();

  useEffect(() => {
    switch (currentPage) {
      case ADMIN_LOGIN:
        setControlButtons({
          ...controlButtons,
          hideButton: true,
        });
        resetAudio();
        resetSFXAudio();
        break;

      case TRIVIA_MAKER:
        setControlButtons({
          ...controlButtons,
          hideButton: false,
        });
        break;

      case MANAGE_CATEGORIES:
      case CREATE_CATEGORY:
      case MANAGE_COLLECTION:
      case CREATE_COLLECTION:
      case CREATE_QUESTION:
        setControlButtons({
          ...controlButtons,
          hideButton: true,
        });
        break;
      case MANAGE_PLAYER_COLLECTION:
        setControlButtons({
          ...controlButtons,
          hideButton: true,
          hideLogo: true,
        });
        break;

      default:
        setControlButtons({
          ...controlButtons,
          hideLogo: false,
          hideButton: true,
        });
        break;
    }
    return () => {
      setControlButtons({
        ...controlButtons,
        hideButton: true,
        hideLogo: false,
      });
    };
  }, [currentPage]); // eslint-disable-line react-hooks/exhaustive-deps

  const navBarLogo = () => {
    return (
      <span className="custom-margin">
        <div className="navbar-brand "></div>
        {Logo === "" ? null : (
          <div className="navbar-brand">
            <img src={Logo} height="28" alt="Logo Virtual Escape" />
          </div>
        )}
      </span>
    );
  };

  const navBarButton = () => {
    return (
      <Fragment>
        <div className="collapse navbar-collapse" id="navbarCollapse">
          <div className="navbar-nav ml-auto">
            <Button
              leftArrow={false}
              color={YELLOW}
              style={{
                fontSize: "1.4375em",
                paddingLeft: "5px",
                paddingRight: "10px",
                justifyContent: "center",
                cursor: "pointer",
              }}
              onClick={() => {
                logoutTriviaAdmin();
              }}
            >
              <i
                className="far fa-sign-out"
                style={{
                  fontSize: "1em",
                }}
              >
                {" "}
              </i>
              &nbsp;
              {"LOG OUT"}
            </Button>
          </div>
        </div>
      </Fragment>
    );
  };

  return (
    <nav className="navbar navbar-expand-md navbar-dark bg-dark custom mt-2">
      {controlButtons?.hideLogo ? null : navBarLogo()}
      {controlButtons?.hideButton ? null : navBarButton()}
    </nav>
  );
};

export default withRouter(TriviaNavbar);
