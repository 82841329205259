export const HEALTH_CHECK = "/v1/healthcheck";
export const LOGIN = "/v1/enuk/login";
export const ADMIN_LOGIN = "/v1/enuk/admin/login";
export const TRIVIA_MAKER_LOGIN = "/v1/enuk/admin/trivialogin";
export const START_GAME = "/v1/enuk/admin/startgame";
export const NEXT_ROUND = "/v1/enuk/admin/nextround";
export const CHANNEL_MEMBER = "/v1/pusher/playercount";
export const SUBMIT_ANSWER = "/v1/enuk/admin/submitanswer";
export const SUBMIT_ROUND = "/v1/enuk/admin/submitround";
export const GET_GAME_STATUS = "/v1/enuk/getgamestatus";
export const LOG_ERROR = "/v1/enuk/logerror";
