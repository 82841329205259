/** @format */

import React from "react";

import {
  MAIN_MENU_ROUTE,
  LOGIN_ROUTE,
  RESUME_GAME_RULES,
  GAME_LOBBY,
  ROUND_SCREEN_ROUTE,
  GAME_SCREEN,
  ROUND_END_ROUTE,
  GAME_END_ROUTE,
  GAME_INTRO,
  TRIVIA_MAKER,
  // MANAGE_CATEGORIES,
  // CREATE_CATEGORY,
  // MANAGE_COLLECTION,
  // CREATE_COLLECTION,
  // CREATE_QUESTION,
  SELECT_COLLECTION,
  NOT_FOUND,
  TRIVIA_GAMES,
  TRIVIA_RESULTS,
  JOINING,
  POWER_UPS,
} from "./RoutesConstants";
import TriviaCreator from "../TriviaCreator/Trivia";
const EndGame = React.lazy(() => import("../EndOfGame/EndOfGame"));
const NotFound = React.lazy(() => import("../NotFound/NotFound"));
// const AdminMainMenu = React.lazy(() => import("../TriviaCreator/Trivia"));
// const ManageCategories = React.lazy(() =>
//   import("../TriviaCreator/ManageCategories/ManageCategories")
// );
// const CreateCollection = React.lazy(() =>
//   import("../TriviaCreator/CreateCollection/CreateCollection")
// );
// const CreateQuestion = React.lazy(() =>
//   import("../TriviaCreator/CreateQuestion/CreateQuestion")
// );
// const CreateCategory = React.lazy(() =>
//   import("../TriviaCreator/CreateCategory/CreateCategory")
// );
// const ManageCollection = React.lazy(() =>
//   import("../TriviaCreator/ManageCollection/ManageCollection")
// );
const GameIntro = React.lazy(() => import("../Trivia/GameIntro/GameIntro"));
const Login = React.lazy(() => import("../Signin/Signin"));
const Menu = React.lazy(() => import("../GameMenu/Menu"));
const ResumeGamesRules = React.lazy(() =>
  import("../Trivia/GameRules/ResumeGameRules")
);
const GameLobby = React.lazy(() => import("../GameLobby/GameLobby"));
const SelectCollection = React.lazy(() =>
  import("../GameLobby/SelectCollection/SelectCollection")
);
const GameScreen = React.lazy(() => import("../GameScreen/GameScreen"));
const EndRound = React.lazy(() => import("../EndRound/EndRound"));
const RoundScreen = React.lazy(() => import("../Trivia/Round/RoundScreen"));
const TriviaGames = React.lazy(() => import("../TriviaGames/TriviaGames"));
const TriviaResults = React.lazy(() =>
  import("../TriviaResults/TriviaResults")
);
const Joining = React.lazy(() => import("../Joining/Joining"));
const PowerUps = React.lazy(() =>
  import("../Trivia/PowerUpsRules/PowerUpsRules")
);

export interface IRoutes {
  path: string;
  component: any; //this component should be from containers
  exact?: boolean;
  isPrivate?: boolean;
}
export const allRoutes: IRoutes[] = [
  {
    path: LOGIN_ROUTE,
    component: Login,
    exact: true,
    isPrivate: false,
  },
  { path: MAIN_MENU_ROUTE, component: Menu, exact: true, isPrivate: true },
  {
    path: RESUME_GAME_RULES,
    component: ResumeGamesRules,
    exact: true,
    isPrivate: true,
  },
  {
    path: GAME_LOBBY,
    component: GameLobby,
    exact: true,
    isPrivate: true,
  },
  {
    path: SELECT_COLLECTION,
    component: SelectCollection,
    exact: true,
    isPrivate: true,
  },
  {
    path: ROUND_SCREEN_ROUTE,
    component: RoundScreen,
    exact: true,
    isPrivate: true,
  },
  {
    path: GAME_SCREEN,
    component: GameScreen,
    exact: true,
    isPrivate: true,
  },
  {
    path: GAME_INTRO,
    component: GameIntro,
    exact: true,
    isPrivate: true,
  },
  {
    path: ROUND_END_ROUTE,
    component: EndRound,
    exact: true,
    isPrivate: true,
  },
  {
    path: GAME_END_ROUTE,
    component: EndGame,
    exact: true,
    isPrivate: true,
  },
  {
    path: TRIVIA_MAKER,
    component: TriviaCreator,
    exact: false,
    isPrivate: false,
  },
  {
    path: TRIVIA_GAMES,
    component: TriviaGames,
    exact: true,
    isPrivate: true,
  },

  {
    path: TRIVIA_RESULTS,
    component: TriviaResults,
    exact: true,
    isPrivate: true,
  },
  {
    path: JOINING,
    component: Joining,
    exact: true,
    isPrivate: true,
  },
  {
    path: POWER_UPS,
    component: PowerUps,
    exact: true,
    isPrivate: true,
  },
  // {
  //   path: MANAGE_PLAYER_COLLECTION,
  //   component: ManagePlayerCollections,
  //   exact: true,
  //   isPrivate: true,
  // },
  // {
  //   path: MANAGE_CATEGORIES,
  //   component: ManageCategories,
  //   exact: true,
  //   isPrivate: false,
  // },
  // {
  //   path: CREATE_CATEGORY,
  //   component: CreateCategory,
  //   exact: true,
  //   isPrivate: false,
  // },
  // {
  //   path: MANAGE_COLLECTION,
  //   component: ManageCollection,
  //   exact: true,
  //   isPrivate: false,
  // },
  // {
  //   path: CREATE_COLLECTION,
  //   component: CreateCollection,
  //   exact: true,
  //   isPrivate: false,
  // },
  // {
  //   path: CREATE_QUESTION,
  //   component: CreateQuestion,
  //   exact: true,
  //   isPrivate: false,
  // },
  {
    path: NOT_FOUND,
    component: NotFound,
    exact: false,
    isPrivate: false,
  },
];
