import React, { useCallback, useContext, useState, useEffect } from "react";
import NetworkManager from "../../services/NetworkManager";
import TriviaMakerManager from "../../services/TriviaMakerService";
import { TCollection, TGameCode, TQuestion } from "../../types/ApiTypes";
import {
  getGameData,
  getPlayerCode,
  removeSuperAdmin,
  sortedByNumber,
} from "../../utilities/util";
import { useAuthContext } from "../Auth/AuthContainer";
import { GameAdminContext, GameAdminProvider } from "./GameAdminContext";
import {
  defaultGameAdminContext,
  GameAdminInterface,
} from "./GameAdminInterface";
import * as Subscriptions from "../../graphql/subscriptions";
import { Subscription } from "../Common/Subscriptions.service";
import { Collection } from "../../API";
import { removeItem, updateItem } from "../Common/helper";
import { ADMIN_LABEL, GAME_CODE_DATA, PUBLIC } from "../../constants";
import LocalStorage from "../../services/LocalStorage";
import { useGameStateContext } from "../GameState/GameStateContainer";

const GameAdminContextContainer: React.FC<{}> = ({ children }) => {
  const { isAuthenticated } = useAuthContext();
  const [adminState, setAdminState] = useState<GameAdminInterface>(
    defaultGameAdminContext
  );
  const { gameCode } = useGameStateContext();

  useEffect(() => {
    if (!isAuthenticated) {
      setAdminState(defaultGameAdminContext);
    }
  }, [isAuthenticated]);
  // const [collections, setCollections] = useState<TCollection[]>(
  //   defaultGameAdminContext.collections || []
  // );
  // const [selectedCollections, setSelectedCollections] = useState<TCollection[]>(
  //   defaultGameAdminContext.selectedCollections || []
  // );

  useEffect(() => {
    const updateGameCode = Subscription(
      Subscriptions.onUpdateGameCode,
      {},
      "onUpdateGameCode",
      (data: TGameCode) => {
        updatePlayerDetails(data?.CollectionPlayed || []);
      }
    );

    return () => {
      updateGameCode?.unsubscribe();
    };
  }, [gameCode]); // eslint-disable-line react-hooks/exhaustive-deps

  const updatePlayerDetails = (collectionsIds: Array<string | null> | null) => {
    let playerData: any = LocalStorage.GetItem(GAME_CODE_DATA) || "{}";
    playerData = JSON.parse(playerData);
    playerData.collectionPlayed = [...(collectionsIds || [])];
    LocalStorage.SetItem(GAME_CODE_DATA, JSON.stringify(playerData));
  };
  //////// select collection subscriptions ////////////////

  const handleUpdateItem = (data: Collection, prev: GameAdminInterface) => {
    return {
      ...prev,
      collections: [...updateItem(data, prev.collections || [])],
    };
  };

  const updateCollectionData = (data: Collection) => {
    if (data.isActive) {
      if (data.GameCode === PUBLIC) {
        onPublicCollectionStatusChange(data);
      } else {
        setAdminState((prev) => {
          return handleUpdateItem(data, prev);
        });
      }
    } else {
      setAdminState((prev) => {
        const filtered = prev?.collections?.filter(
          (collection) => collection?.id !== data?.id
        );
        return {
          ...prev,
          collections: [...(filtered || [])],
        };
      });
    }
  };
  const onPublicCollectionStatusChange = (data: Collection) => {
    setAdminState((prev) => {
      const filtered = prev?.collections?.filter(
        (collection) => collection?.id === data?.id
      );
      if (filtered?.length === 0) {
        return {
          ...prev,
          collections: [...(prev.collections || []), data],
        };
      }
      return handleUpdateItem(data, prev);
    });
  };
  const createCollectionData = (data: Collection) => {
    setAdminState((prev) => {
      return {
        ...prev,
        collections: [...(prev.collections || []), data],
      };
    });
  };
  const removeCollectionData = (data: Collection) => {
    setAdminState((prev) => {
      return {
        ...prev,
        collections: [...removeItem(data.id || "", prev.collections || [])],
      };
    });
  };

  const removePublicStatusChangeCollection = (data: Collection) => {
    setAdminState((prev) => {
      const filtered =
        prev?.collections?.filter((collection) => collection.id !== data.id) ||
        [];
      return {
        ...prev,
        collections: [...(filtered || [])],
      };
    });
  };

  const checkUserType = (data: Collection) => {
    if (
      data.GameCode !== getPlayerCode() &&
      data.GameCode !== ADMIN_LABEL &&
      data.GameCode !== PUBLIC
    ) {
      removePublicStatusChangeCollection(data);
      return false;
    } else {
      return true;
    }
  };

  useEffect(() => {
    const addCollection = Subscription(
      Subscriptions.onCreateCollection,
      {},
      "onCreateCollection",
      (data: Collection) => {
        if (checkUserType(data)) {
          createCollectionData(data);
        }
      }
    );
    const updateCollection = Subscription(
      Subscriptions.onUpdateCollection,
      {},
      "onUpdateCollection",
      (data: Collection) => {
        if (checkUserType(data)) {
          updateCollectionData(data);
        }
      }
    );
    const removeCollection = Subscription(
      Subscriptions.onDeleteCollection,
      {},
      "onDeleteCollection",
      (data: Collection) => {
        if (checkUserType(data)) {
          removeCollectionData(data);
        }
      }
    );
    const addedQuestion = Subscription(
      Subscriptions.onIncrementCollectionQuestions,
      {},
      "onIncrementCollectionQuestions",
      (data: Collection) => {
        updateCollectionData(data);
      }
    );
    return () => {
      addCollection?.unsubscribe();
      updateCollection?.unsubscribe();
      removeCollection?.unsubscribe();
      addedQuestion?.unsubscribe();
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  //////////////////////////////////
  // useEffect(() => {
  //   const gameCode = getPlayerCode();
  //   const addCollection = Subscription(
  //     Subscriptions.onCreatedCollection,
  //     { GameCode: gameCode },
  //     "onCreatedCollection",
  //     (data: Collection) => {
  //       createCollectionData(data);
  //     }
  //   );
  //   const updateCollection = Subscription(
  //     Subscriptions.onUpdatedCollection,
  //     { GameCode: gameCode },
  //     "onUpdatedCollection",
  //     (data: Collection) => {
  //       updateCollectionData(data);
  //     }
  //   );
  //   const removeCollection = Subscription(
  //     Subscriptions.onDeletedCollection,
  //     { GameCode: gameCode },
  //     "onDeletedCollection",
  //     (data: Collection) => {
  //       removeCollectionData(data);
  //     }
  //   );
  //   const addedQuestion = Subscription(
  //     Subscriptions.onIncrementCollectionQuestions,
  //     {},
  //     "onIncrementCollectionQuestions",
  //     (data: Collection) => {
  //       updateCollectionData(data);
  //     }
  //   );
  //   return () => {
  //     addCollection?.unsubscribe();
  //     updateCollection?.unsubscribe();
  //     removeCollection?.unsubscribe();
  //     addedQuestion?.unsubscribe();
  //   };
  // }, [gameCode]); // eslint-disable-line react-hooks/exhaustive-deps

  // useEffect(() => {
  //   const gameCode = "admin";
  //   const addCollection = Subscription(
  //     Subscriptions.onCreatedCollection,
  //     { GameCode: gameCode },
  //     "onCreatedCollection",
  //     (data: Collection) => {
  //       createCollectionData(data);
  //     }
  //   );
  //   const updateCollection = Subscription(
  //     Subscriptions.onUpdatedCollection,
  //     { GameCode: gameCode },
  //     "onUpdatedCollection",
  //     (data: Collection) => {
  //       updateCollectionData(data);
  //     }
  //   );
  //   const removeCollection = Subscription(
  //     Subscriptions.onDeletedCollection,
  //     { GameCode: gameCode },
  //     "onDeletedCollection",
  //     (data: Collection) => {
  //       removeCollectionData(data);
  //     }
  //   );
  //   const addedQuestion = Subscription(
  //     Subscriptions.onIncrementCollectionQuestions,
  //     {},
  //     "onIncrementCollectionQuestions",
  //     (data: Collection) => {
  //       updateCollectionData(data);
  //     }
  //   );
  //   return () => {
  //     addCollection?.unsubscribe();
  //     updateCollection?.unsubscribe();
  //     removeCollection?.unsubscribe();
  //     addedQuestion?.unsubscribe();
  //   };
  // }, [gameCode]); // eslint-disable-line react-hooks/exhaustive-deps

  //////// select collection subscriptions ////////////////

  const getPlayedCollectionsIds = () => {
    const collectionPlayed = getGameData("collectionPlayed");
    return collectionPlayed;
  };

  const fetchCollections = useCallback(async (): Promise<TCollection[]> => {
    try {
      // const collections = adminState.collections || [];
      // if (collections && collections.length > 0) {
      //   return collections;
      // }

      const { body } = await NetworkManager.GetCollections("admin");
      const playerResponse = await NetworkManager.GetCollections(
        getPlayerCode() || ""
      );
      const publicResponse = await NetworkManager.GetCollections(PUBLIC);

      const publicCollection = publicResponse.body;
      const playerCollection = playerResponse.body;
      const allCollections = [
        ...body,
        ...playerCollection,
        ...publicCollection,
      ];
      triggerSetAdminState({
        collections: [...allCollections],
      });
      return [...allCollections];
    } catch (err) {
      console.log("Error while fetching Collections");
    }
    return [];
  }, []);

  const fetchRoundQuestion = async (id: string): Promise<TQuestion[]> => {
    try {
      if (id) {
        const { body } = await TriviaMakerManager.GetQuestionList(id);
        const sortedQuestion = sortedByNumber("Rank", body);

        setAdminState((prev) => {
          return { ...prev, currentRoundQuestion: [...sortedQuestion] };
        });
        return [...sortedQuestion];
      } else {
        return [];
      }
    } catch (err) {
      console.log("Error while fetching Questions");
    }
    return [];
  };

  const triggerSetAdminState = (data: GameAdminInterface) => {
    setAdminState((prev) => {
      return { ...prev, ...data };
    });
  };

  const resetAdmin = () => {
    removeSuperAdmin();
    triggerSetAdminState({
      currentRound: 0,
      selectedCollections: [],
      adminStatus: true,
    });
  };

  return (
    <GameAdminProvider
      value={{
        collections: adminState.collections,
        selectedCollections: adminState.selectedCollections,
        fetchCollections,
        currentRound: adminState.currentRound,
        currentRoundId: adminState.currentRoundId,
        currentQuestionNo: adminState.currentQuestionNo,
        currentRoundQuestion: adminState.currentRoundQuestion,
        fetchRoundQuestion,
        setAdminState: triggerSetAdminState,
        roundResult: adminState.roundResult,
        resetAdmin: resetAdmin,
        adminStatus: adminState.adminStatus,
        getPlayedCollectionsIds,
      }}
    >
      {children}
    </GameAdminProvider>
  );
};

export default GameAdminContextContainer;

export const useGameAdminContext = () => {
  return useContext(GameAdminContext);
};
