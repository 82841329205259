import { Message } from "../Auth/AuthInterface";

export interface AdminAuthStateInterface {
  isAuthenticated: boolean;
  token: string;
  errorMessage: Message;
}

export interface AdminAuthInterface {
  isAuthenticated: boolean;
  errorMessage: Message;

  authenticateUser: (token: string) => void;
  logoutTriviaAdmin: () => void;
  checkAdminAuthentication: () => boolean;
}

export const defaultAdminAuthContext: AdminAuthInterface = {
  isAuthenticated: false,
  errorMessage: {
    value: "",
    color: "",
  },
  authenticateUser: (token: string) => {},
  logoutTriviaAdmin: () => {},
  checkAdminAuthentication: () => false,
};
